import React, { useEffect, useState, memo, useLayoutEffect } from "react";
import CostumModal from "../helpers/CostumModal";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Card } from "react-bootstrap";
import largePizza from "../assets/images/large-pizza.svg";
import { getCustomizeItemVariationListById } from "../../../Action/manageProductCategoryAction";
import "./customCart.css";
import { createItemToCart, updateItemToCart } from "../../../Action/cartAction";
import modifierImg from "../assets/images/modifiers.png";
import { checkRestorentStatus } from "../../../Services/authenticationServices";
import { ComponentLoader } from "../../../helpers/PageLoader";
import NormalModal from "../../../helpers/NormalModal";
const CostumCart = (props) => {
  var already = [];
  var alreadyArr = [];
  const responData = useSelector((state) => state.productCat);
  const cartEdit = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [itemPrice, setItemPrice] = useState("0");
  const {
    show,
    customizeModal,
    itemIdOfcategory: itemId,
    uniqueId,
    itemName,
    isEdited,
    halfTopping,
    itemImage,
    handleRestorentClosedModel,
  } = props;
  const [modefiers, setModifiers] = useState([]);
  const [error, seterror] = useState([]);
  const [disablebutton, setdisablebutton] = useState(false);
  const [veriation, setVeriation] = useState(0);
  const { productCatItemModifiers } = responData;
  const [cart, setCart] = useState({
    uniqueId: Date.now(),
    itemId,
    itemName,
    itemImage,
    halfTopping,
    variationId: "",
    variationName: "",
    itemPrice,
    quantity: 1,
    modifier: [],
  });
  const [initCart, setInitCart] = useState(null);
  const [confirmSize, setConfirmSize] = useState(false);

  var userid = "0";
  var temp = "0";
  var defaultDouble = "regular";

  /* update the modifier state */
  useEffect(() => {
    if (productCatItemModifiers) {
      setModifiers(productCatItemModifiers);
      if (!isEdited) {
        let tmpCart = { ...cart };
        if (productCatItemModifiers[0]) {
          tmpCart.modifier = [];
          let tmp = [...selectedSide];
          productCatItemModifiers[0]?.ModifierDetails.map(
            (modifierm, index) => {
              if (modifierm?.isDefault) {
                // let isHalfTopping = modifierm?.modifierPriceData[0]?.halfToping
                //   ? true
                //   : false;

                tmpCart.modifier.push({
                  modifierId: modifierm?.modifiersId,
                  modifierName: modifierm?.ModifierName,
                  modifierPrice: handlePrice(modifierm?.modifierPrice),
                  topping: "f",
                  layer: defaultDouble,
                  isDefault: modifierm?.isDefault,
                  isDelete: false,
                  modifierCatgegoryId: modifierm?.modifierCatgegoryName[0]._id,
                });
                let index = tmp.findIndex(
                  (e) => e.modifierId === modifierm?.modifiersId
                );
                let el = tmp[index];
                if (typeof el == "undefined") {
                  el = {
                    l: false,
                    f: true,
                    r: false,
                    show: true,
                  };
                } else {
                  el = {
                    l: false,
                    f: true,
                    r: false,
                    show: true,
                  };
                }
                tmp[index] = el;
              }
            }
          );
          setVeriation(tmpCart.variationId);
          setCart(tmpCart);
          setSelection(tmp);
        }
      }
    }
  }, [productCatItemModifiers]);

  /*this effect will only when user edit the item*/
  useEffect(() => {
    if (isEdited) {
      let index = cartEdit.findIndex((arr) => arr.uniqueId === uniqueId);
      if (index !== -1) {
        let tmp = { ...cart };
        tmp.uniqueId = cartEdit[index].uniqueId;
        tmp.modifier = cartEdit[index].modifier;
        tmp.variationId = cartEdit[index].variationId;
        tmp.variationName = cartEdit[index].variationName;
        tmp.itemPrice = cartEdit[index].itemPrice;
        tmp.quantity = cartEdit[index].quantity;
        //update the topping intial array to show and others opertaion
        var toppingArr = [...selectedSide];

        let doubleTmp = [...doubleSelection];
        cartEdit[index].modifier.map((mModifier, index) => {
          if (mModifier.topping == "l") {
            toppingArr.push({
              modifierId: mModifier.modifierId,
              show: true,
              l: true,
              f: false,
              r: false,
            });
          } else if (mModifier.topping == "f") {
            toppingArr.push({
              modifierId: mModifier.modifierId,
              show: true,
              l: false,
              f: true,
              r: false,
            });
          } else {
            toppingArr.push({
              modifierId: mModifier.modifierId,
              show: true,
              l: false,
              f: false,
              r: true,
            });
          }

          /** setting double */
          let doubleIndex = doubleTmp.findIndex(
            (e) => e.modifierId === mModifier.modifierId
          );
          if (doubleIndex === -1) {
            doubleTmp.push({
              modifierId: mModifier.modifierId,
              layer: mModifier.layer,
              show: true,
            });
          } else {
            doubleTmp[doubleIndex] = {
              modifierId: mModifier.modifierId,
              layer: mModifier.layer,
              show: true,
            };
          }
        });
        setVeriation(tmp.variationId);
        setSelection(toppingArr);
        setdoubleSelection(doubleTmp);
        setCart(tmp);
        setItemPrice(cartEdit[index].itemPrice);
      }
    }
  }, []);

  /* this effect use to  get item variation by id */
  useEffect(() => {
    if (itemId !== "") {
      dispatch(getCustomizeItemVariationListById(itemId));
    }
  }, [dispatch]);

  /* this effect use to  set cart  by default variation and variation id  */
  useEffect(() => {
    if (!responData?.loading && responData.productCatItemVariations[0]) {
      const itempriceGet =
        responData.productCatItemVariations[0]?.VariationDetails[0]
          .variationPrice;
      if (!isEdited) {
        setItemPrice(itempriceGet);
        setCart((state) => ({ ...state, itemPrice: itempriceGet })); // for Jira ticket OP-43
        // setCart((state) => ({ ...state, itemPrice }));
        /* update the cart state to modify all  pre-defind values  */
        setCart((state) => ({
          ...state,
          variationId:
            responData.productCatItemVariations[0]?.VariationDetails[0]
              .variationId,
        }));
        setCart((state) => ({
          ...state,
          variationName:
            responData.productCatItemVariations[0]?.VariationDetails[0]
              .variationName,
        }));
        setVeriation(cart.variationId);
      }
    }
  }, [responData]);

  /** this effet will handle modifier list active class  */
  useEffect(() => {
    setTimeout(() => {
      let collapseClasses = document.getElementsByClassName("collapse");
      for (let i = 0; i <= collapseClasses.length - 1; i++) {
        collapseClasses[i].className = collapseClasses[i].className + " show";
      }
      setTimeout(() => {
        let collapseClasses = document.getElementsByClassName("collapse");
        for (let i = 0; i <= collapseClasses.length - 1; i++) {
          collapseClasses[i].className = collapseClasses[i].className + " show";
        }
      }, 1000);
    }, 1000);
  }, []);

  /** this effect will fetch user data if loggedin and pickup */
  useEffect(() => {
    //
  }, []);
  /* filteriazation of price */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };

  /** this will handle  the undefind and null value */
  const handlePrice = (p) => {
    if (typeof p == "undefined") {
      return 0;
    } else if (p == undefined) {
      return 0;
    } else if (p == "") {
      return 0;
    } else {
      return p;
    }
  };

  /** if cart has already  same item  */
  function compare(arr1, arr2) {
    return new Promise((resolve, reject) => {
      var isSame = true;
      Promise.all(
        Object.keys(arr1).map(async function (key, index) {
          if (key === "uniqueId") {
            //
          } else if (key === "modifier") {
            arr1[key].map(function (itemOne) {
              let item = arr2[key].find(
                (t) => t.modifierId === itemOne.modifierId
              );
              if (typeof item !== "undefined") {
                Object.keys(item).map(function (mKey, index) {
                  if (item[mKey] !== itemOne[mKey]) {
                    isSame = false;
                  }
                });
              } else {
                isSame = false;
              }
            });
          } else {
            if (arr1[key] !== arr2[key]) {
              isSame = false;
            }
          }
          return new Promise((resolve, reject) => {
            resolve();
          });
        })
      ).then(() => {
        if (isSame) {
          reject();
        } else {
          resolve();
        }
      });
    });
  }

  /* add or remove modifier from state */
  function addremove(
    modifierId,
    modifierName,
    modifierPrice,
    cid,
    isDefault,
    modifierCatgegoryId
  ) {
    var chkBox = document.getElementById(cid);
    var index = "";
    var doubleIndex = "";
    if (chkBox.checked) {
      /** set tooping and and double functioality  */
      //update the toppings to show hide and  pre add the state
      var tmpcart = [...selectedSide];
      var doubleList = [...doubleSelection];
      index = tmpcart.findIndex((e) => e.modifierId === modifierId);
      doubleIndex = doubleList.findIndex((e) => e.modifierId === modifierId);
      if (index === -1) {
        let topping = halfTopping
          ? modefiers[0]?.ModifierDetails.filter(
              (x) => x.modifiersId === modifierId
            )[0]?.modifierPriceData.filter(
              (x) => x.varationsId === veriation
            )[0]?.halfToping
            ? "f"
            : ""
          : "";
        tmpcart.push({
          modifierId,
          show: true,
          l: false,
          f: true,
          r: false,
        });
        index = tmpcart.findIndex((e) => e.modifierId === modifierId);
      }
      if (doubleIndex === -1) {
        doubleList.push({
          modifierId,
          show: true,
          layer: defaultDouble,
        });
        doubleIndex = doubleList.findIndex((e) => e.modifierId === modifierId);
      }
      tmpcart[index].show = true;
      doubleList[doubleIndex].show = true;
      /** --end set and double functionality */

      /** update the main cart */
      //let update the cart object
      let tmpCart1 = { ...cart };
      //isdefault item item is solftdeleted so we can`t push so we just set this isdeleted true
      if (isDefault) {
        let i = tmpCart1.modifier.findIndex((v) => v.modifierId === modifierId);
        if (i !== -1) {
          tmpCart1.modifier[i].isDeleted = !tmpCart1.modifier[i].isDeleted;
        }
      } else {
        let topping = halfTopping
          ? modefiers[0]?.ModifierDetails.filter(
              (x) => x.modifiersId === modifierId
            )[0]?.modifierPriceData.filter(
              (x) => x.varationsId === veriation
            )[0]?.halfToping
            ? "f"
            : ""
          : "";
        let layer =
          modefiers[0]?.ModifierDetails.filter(
            (x) => x.modifiersId === modifierId
          )[0]?.modifierPriceData.filter((x) => x.varationsId === veriation)[0]
            ?.isDouble || false;
        tmpCart1.modifier.push({
          modifierId,
          modifierName,
          modifierPrice: handlePrice(modifierPrice),
          topping,
          ...(layer && { layer: defaultDouble }),
          isDefault,
          ...(isDefault && { isDeleted: false }),
          modifierCatgegoryId,
        });
      }
      setCart(tmpCart1);
      if (!isDefault) {
        //don`t  modify the price if modifier has default value
        setItemPrice((current) => current + handlePrice(modifierPrice));
        setCart((state) => ({
          ...state,
          itemPrice: itemPrice + handlePrice(modifierPrice),
        }));
      }
      setSelection(tmpcart);
      setdoubleSelection(doubleList);
    } else {
      let tmpCart = { ...cart };
      let index = tmpCart.modifier.findIndex(
        (v) => v.modifierId === modifierId
      );
      if (index !== -1) {
        //can`t delete the default one so soft delete them to show footprint on cart
        if (tmpCart.modifier[index].isDefault) {
          let modifierChunk = tmpCart.modifier[index];
          modifierChunk.isDeleted = true;
          modifierChunk.modifierPrice = 0;
          tmpCart.modifier[index] = modifierChunk;
        } else {
          tmpCart.modifier.splice(index, 1);
        }
        /* recalculate  the price */
        var price = handlePrice(
          responData.productCatItemVariations[0]?.VariationDetails.filter(
            (x) => x.variationId === veriation
          )[0].variationPrice
        );
        tmpCart.modifier.map((item, index) => {
          if (!item.isDefault) {
            price += item.modifierPrice;
          } else if (item.isDefault && item.layer === "extra") {
            price += item.modifierPrice;
          }
        });
        tmpCart.itemPrice = price;
        setItemPrice(price);
        setCart(tmpCart);
      }
      //update the toppings to show hide and  preadd the state
      tmpcart = [...selectedSide];
      doubleList = [...doubleSelection];
      index = tmpcart.findIndex((e) => e.modifierId === modifierId);
      doubleIndex = doubleList.findIndex((e) => e.modifierId === modifierId);
      if (index === -1) {
        tmpcart.push({
          modifierId,
          show: true,
          l: false,
          f: true,
          r: false,
        });
        index = tmpcart.findIndex((e) => e.modifierId === modifierId);
      }
      if (doubleIndex === -1) {
        doubleList.push({
          modifierId,
          show: true,
          layer: defaultDouble,
        });
        doubleIndex = tmpcart.findIndex((e) => e.modifierId === modifierId);
      }
      tmpcart[index].show = false;
      doubleList[doubleIndex].show = false;
      setSelection(tmpcart);
      setdoubleSelection(doubleList);
    }
  }
  /** this function will work when  we chnage the variation  */
  const handleVariationChnage = (price, id, name, vIndex) => {
    let tmpCart = { ...cart };
    const arr = tmpCart?.modifier?.find((v) => v.layer === "extra");
    const updatedData = tmpCart?.modifier?.map((item) => {
      if (item.layer === "extra") {
        item.layer = "regular";
      }
      return item;
    });
    setVeriation(id);
    tmpCart.arr = updatedData;
    tmpCart.variationId = id;
    tmpCart.variationName = name;
    tmpCart.itemPrice = price;
    let totalPrice = price;
    let selectedSlidesFootPrint = [...selectedSide];
    let doubleFootPrint = [...doubleSelection];
    tmpCart.modifier.map((singleModifier, index3) => {
      var mainModifier = modefiers[0].ModifierDetails.find(
        (v) => v.modifiersId === singleModifier.modifierId
      );
      let isHalfTopping = mainModifier.modifierPriceData.filter(
        (x) => x.varationsId === id
      )[0]?.halfToping
        ? true
        : false;
      let isDouble = mainModifier.modifierPriceData.filter(
        (x) => x.varationsId === id
      )[0]?.isDouble;
      let previousHalfToppingFootPrint =
        selectedSlidesFootPrint.filter(
          (e) => e.modifierId === singleModifier.modifierId
        )[0] || [];
      let previousDoubleFootPrint =
        selectedSlidesFootPrint.filter(
          (e) => e.modifierId === singleModifier.modifierId
        )[0] || [];
      if (!mainModifier.isDefault) {
        /**
         * some variation  has half topping and some does not have
         *  **/
        if (isHalfTopping) {
          if (
            previousHalfToppingFootPrint.l === true ||
            previousHalfToppingFootPrint.r === true
          ) {
            if (isDouble && previousDoubleFootPrint[0] == "regular") {
              tmpCart.modifier[index3].modifierPrice = handlePrice(
                mainModifier.modifierPriceData.filter(
                  (x) => x.varationsId === id
                )[0]?.Price / 2
              );
            } else if (isDouble && previousDoubleFootPrint[0] == "extra") {
              tmpCart.modifier[index3].modifierPrice = handlePrice(
                (mainModifier.modifierPriceData.filter(
                  (x) => x.varationsId === id
                )[0]?.Price /
                  2) *
                  2
              );
            } else {
              tmpCart.modifier[index3].modifierPrice = handlePrice(
                mainModifier.modifierPriceData.filter(
                  (x) => x.varationsId === id
                )[0]?.Price / 2
              );
            }
            totalPrice =
              totalPrice + handlePrice(tmpCart.modifier[index3].modifierPrice);
          } else {
            if (isDouble && previousDoubleFootPrint[0] == "regular") {
              tmpCart.modifier[index3].modifierPrice = handlePrice(
                mainModifier.modifierPriceData.filter(
                  (x) => x.varationsId === id
                )[0]?.Price
              );
            } else if (isDouble && previousDoubleFootPrint[0] == "extra") {
              tmpCart.modifier[index3].modifierPrice = handlePrice(
                mainModifier.modifierPriceData.filter(
                  (x) => x.varationsId === id
                )[0]?.Price * 2
              );
            } else {
              tmpCart.modifier[index3].modifierPrice = handlePrice(
                mainModifier.modifierPriceData.filter(
                  (x) => x.varationsId === id
                )[0]?.Price
              );
            }
            totalPrice =
              totalPrice + handlePrice(tmpCart.modifier[index3].modifierPrice);
          }
        } else {
          tmpCart.modifier[index3].topping = "";
          if (isDouble && previousDoubleFootPrint[0] == "regular") {
            tmpCart.modifier[index3].modifierPrice = handlePrice(
              mainModifier.modifierPriceData.filter(
                (x) => x.varationsId === id
              )[0]?.Price
            );
          } else if (isDouble && previousDoubleFootPrint[0] == "extra") {
            tmpCart.modifier[index3].modifierPrice = handlePrice(
              mainModifier.modifierPriceData.filter(
                (x) => x.varationsId === id
              )[0]?.Price * 2
            );
          } else {
            tmpCart.modifier[index3].modifierPrice = handlePrice(
              mainModifier.modifierPriceData.filter(
                (x) => x.varationsId === id
              )[0]?.Price
            );
          }
          totalPrice =
            totalPrice + handlePrice(tmpCart.modifier[index3].modifierPrice);
        }
      } else {
        if (!isHalfTopping) {
          tmpCart.modifier[index3].topping = "";
        }
        /** default modifier price update */
        if (isHalfTopping) {
          if (
            previousHalfToppingFootPrint.l === true ||
            previousHalfToppingFootPrint.r === true
          ) {
            if (isDouble && previousDoubleFootPrint[0] == "extra") {
              tmpCart.modifier[index3].modifierPrice = handlePrice(
                mainModifier.modifierPriceData.filter(
                  (x) => x.varationsId === id
                )[0]?.Price
              );
            }
            totalPrice =
              totalPrice + handlePrice(tmpCart.modifier[index3].modifierPrice);
          } else {
            if (isDouble && previousDoubleFootPrint[0] == "extra") {
              tmpCart.modifier[index3].modifierPrice = handlePrice(
                mainModifier.modifierPriceData.filter(
                  (x) => x.varationsId === id
                )[0]?.Price
              );
            }
            totalPrice =
              totalPrice + handlePrice(tmpCart.modifier[index3].modifierPrice);
          }
        } else {
          if (isDouble && previousDoubleFootPrint[0] == "extra") {
            tmpCart.modifier[index3].modifierPrice = handlePrice(
              mainModifier.modifierPriceData.filter(
                (x) => x.varationsId === id
              )[0]?.Price
            );
          }
          totalPrice =
            totalPrice + handlePrice(tmpCart.modifier[index3].modifierPrice);
        }
      }
      tmpCart.itemPrice = totalPrice;
    });

    // Getting old cart data to compare new data
    const oldItem = JSON.parse(localStorage.getItem("oldItem"));
    if (tmpCart?.variationName === oldItem?.variationName) {
    } else {
      setCart(tmpCart);
    }

    setItemPrice(tmpCart.itemPrice);
  };

  /** this function will check variations and add item to cart */
  const addToCart = () => {
    if (responData?.productCatItemVariations[0]?.VariationDetails?.length > 1) {
      setConfirmSize(true);
    } else {
      onAddCart();
    }
  };

  /** this function will add item to cart  */
  const handleAddToCart = () => {
    setdisablebutton(true);
    checkIfAnyOutOfStock()
      .then(() => {
        checkForDefaultMinMax()
          .then(() => {
            checkRestorentStatus()
              .then((res) => {
                if (res.result.status) {
                  var index = cartEdit.findIndex(
                    (v) => v.itemId === cart.itemId
                  );
                  if (index !== -1) {
                    compare(cartEdit[index], cart)
                      .then((e) => {
                        setdisablebutton(false);
                        addToCart();
                      })
                      .catch((err) => {
                        setdisablebutton(false);
                      });
                  } else {
                    setdisablebutton(false);
                    addToCart();
                  }
                } else {
                  setdisablebutton(false);
                  customizeModal("", "");
                  handleRestorentClosedModel();
                }
              })
              .catch((error) => {
                setdisablebutton(false);
              });
          })
          .catch((error) => {
            setdisablebutton(false);
          });
      })
      .catch((err) => {
        setdisablebutton(false);
      });
  };

  /** this function will add the item to the cart */
  const onAddCart = () => {
    dispatch(createItemToCart(cart));
    customizeModal("", "");
  };

  /*** this function will update item to the cart */
  const handleUpdateCart = () => {
    setdisablebutton(true);
    checkForDefaultMinMax()
      .then(() => {
        setdisablebutton(false);
        dispatch(updateItemToCart(cart));
        customizeModal("", "");
      })
      .catch((error) => {
        setdisablebutton(false);
      });
  };
  /** this is reactive componant used for render half toppings  */
  const Sizes = ({ modifiersId, modifierPrice, isDefault }) => {
    let tmp = [...selectedSide];
    let index = tmp.findIndex((e) => e.modifierId === modifiersId);
    let el = tmp[index];
    if (typeof el == "undefined") {
      el = {
        l: false,
        f: true,
        r: false,
        show: isDefault,
      };
    }
    return (
      <>
        {el.show ? (
          <div className="sizeSelect">
            <div className="d-flex h-100">
              <span
                className={`l ${el.l ? "active" : ""}`}
                key={`l${modifiersId}`}
                id={`l${modifiersId}`}
                onClick={(e) => toggleActive("l", modifiersId, modifierPrice)}
              >
                {" "}
                <img src={modifierImg} alt="sizes" />
              </span>
              <span
                className={`f ${el.f ? "active" : ""}`}
                key={`f${modifiersId}`}
                id={`f${modifiersId}`}
                onClick={(e) => toggleActive("f", modifiersId, modifierPrice)}
              >
                {" "}
                <img src={modifierImg} alt="sizes" />
              </span>
              <span
                className={`r ${el.r ? "active" : ""}`}
                key={`r${modifiersId}`}
                id={`r${modifiersId}`}
                onClick={(e) => toggleActive("r", modifiersId, modifierPrice)}
              >
                {" "}
                <img src={modifierImg} alt="sizes" />
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  /** this is reactive component for the double and light modifier functionality  */
  const DoubleComponent = ({ modifiersId, modifierPrice, isDefault }) => {
    let tmp = [...doubleSelection];
    let index = tmp.findIndex((e) => e.modifierId === modifiersId);
    let el = tmp[index];

    // console.log(isEdited, "isEdited", el)
    // if(!isEdited && el !== undefined){
    //   el.layer = defaultDouble
    // }

    const oldItem = JSON.parse(localStorage.getItem("oldItem"));
    if (!!oldItem && oldItem.variationId !== veriation && el !== undefined) {
      el.layer = defaultDouble;
    }
    if (typeof el == "undefined") {
      el = {
        show: isDefault,
        modifierId: modifiersId,
        layer: defaultDouble,
      };
    }
    if (!el.show) {
      return <></>;
    }
    return (
      <div className="w-100">
        <div
          className="btn-group btn-group-sm  mt-2"
          role="group"
          aria-label="First group"
        >
          <button
            type="button"
            className={`btn btn-secondary ${
              el.layer == "regular" ? "active" : ""
            }`}
            onClick={(e) => toggleDouble("regular", modifiersId, modifierPrice)}
          >
            Regular
          </button>
          <button
            type="button"
            className={`btn btn-secondary ${
              el.layer == "extra" ? "active" : ""
            }`}
            onClick={(e) => toggleDouble("extra", modifiersId, modifierPrice)}
          >
            Extra
          </button>
        </div>
      </div>
    );
  };
  const [selectedSide, setSelection] = useState([]);
  const [doubleSelection, setdoubleSelection] = useState([]);
  /** this function handle half toppings click  */
  const toggleActive = (select, modifierId, modifierPrice) => {
    var tmpcart = [...selectedSide];
    var isDobuleList = [...doubleSelection];
    var tmpoCart = { ...cart };
    var tmpoIndex = tmpoCart.modifier.findIndex(
      (e) => e.modifierId === modifierId
    );
    var index = tmpcart.findIndex((e) => e.modifierId === modifierId);
    var isDoubleIndex = isDobuleList.findIndex(
      (e) => e.modifierId === modifierId
    );
    if (index === -1) {
      tmpcart.push({
        modifierId,
        show: true,
        l: false,
        f: false,
        r: false,
      });
      index = tmpcart.findIndex((e) => e.modifierId === modifierId);
    }
    if (index !== -1) {
      if (select == "l") {
        tmpcart[index] = {
          modifierId,
          show: true,
          l: true,
          f: false,
          r: false,
        };
      } else if (select == "f") {
        tmpcart[index] = {
          modifierId,
          show: true,
          l: false,
          f: true,
          r: false,
        };
      } else {
        tmpcart[index] = {
          modifierId,
          show: true,
          l: false,
          f: false,
          r: true,
        };
      }
    }
    if (tmpoIndex !== -1) {
      let chunk = tmpoCart.modifier[tmpoIndex];
      chunk.topping = select;
      if (!chunk.isDefault) {
        if (select === "l" || select === "r") {
          chunk.modifierPrice = handlePrice(modifierPrice / 2);
        } else {
          chunk.modifierPrice = handlePrice(modifierPrice);
        }
        if (isDoubleIndex !== -1) {
          if (isDobuleList[isDoubleIndex].layer == "regular") {
            //do nothing for now but i am leaving this block for future changes
          } else if (isDobuleList[isDoubleIndex].layer == "extra") {
            chunk.modifierPrice = handlePrice(chunk.modifierPrice * 2);
          }
        }
      } else {
        if (isDoubleIndex !== -1) {
          if (isDobuleList[isDoubleIndex].layer == "regular") {
            //do nothing for now but i am leaving this block for future changes
          } else if (isDobuleList[isDoubleIndex].layer == "extra") {
            if (select === "l" || select === "r") {
              chunk.modifierPrice = handlePrice(modifierPrice / 2);
            } else {
              chunk.modifierPrice = handlePrice(modifierPrice);
            }
          }
        }
      }
      tmpoCart.modifier[tmpoIndex] = chunk;
      /* recalculate  the price */
      var price = handlePrice(
        responData.productCatItemVariations[0]?.VariationDetails.filter(
          (x) => x.variationId === veriation
        )[0].variationPrice
      );
      tmpoCart.modifier.map((item, index) => {
        if (!item.isDefault) {
          price += item.modifierPrice;
        } else if (item.isDefault && item.layer === "extra") {
          price += item.modifierPrice;
        }
      });
    }
    tmpoCart.itemPrice = price;
    setItemPrice(price);
    setCart(tmpoCart);
    setSelection(tmpcart);
  };
  /** this function will use for active de-active  the double and light buttons  */
  const toggleDouble = (select, modifierId, modifierPrice) => {
    let tmpcart = [...doubleSelection];
    let tmpoCart = { ...cart };
    let tmpoIndex = tmpoCart.modifier.findIndex(
      (e) => e.modifierId === modifierId
    );
    let index = tmpcart.findIndex((e) => e.modifierId === modifierId);
    if (index === -1) {
      tmpcart.push({
        modifierId,
        layer: defaultDouble,
        show: true,
      });
      index = tmpcart.findIndex((e) => e.modifierId === modifierId);
    }
    if (index !== -1) {
      tmpcart[index] = {
        modifierId,
        layer: select,
        show: true,
      };
    }
    if (tmpoIndex !== -1) {
      let chunk = tmpoCart.modifier[tmpoIndex];
      chunk.layer = select;
      if (!chunk.isDefault) {
        if (chunk.topping === "l" || chunk.topping === "r") {
          chunk.modifierPrice = handlePrice(modifierPrice / 2);
        } else {
          chunk.modifierPrice = handlePrice(modifierPrice);
        }
        if (index !== -1) {
          if (tmpcart[index].layer === "regular") {
            //refering for future use
          } else if (tmpcart[index].layer === "extra") {
            chunk.modifierPrice = handlePrice(chunk.modifierPrice) * 2;
          }
        }
      } else {
        if (index !== -1) {
          if (tmpcart[index].layer === "regular") {
            //refering for future use
          } else if (tmpcart[index].layer === "extra") {
            if (chunk.topping === "l" || chunk.topping === "r") {
              chunk.modifierPrice = handlePrice(modifierPrice / 2);
            } else {
              chunk.modifierPrice = handlePrice(modifierPrice);
            }
          }
        }
      }
      tmpoCart.modifier[tmpoIndex] = chunk;
      /* recalculate  the price */
      var price = handlePrice(
        responData.productCatItemVariations[0]?.VariationDetails.filter(
          (x) => x.variationId === veriation
        )[0].variationPrice
      );
      tmpoCart.modifier.map((item, index) => {
        if (!item.isDefault) {
          price += item.modifierPrice;
        } else if (item.isDefault && item.layer === "extra") {
          price += item.modifierPrice;
        }
      });
    }

    tmpoCart.itemPrice = price;
    setItemPrice(price);
    setCart(tmpoCart);
    setdoubleSelection(tmpcart);
  };
  /*** this function will check if any item is out of stock  */
  const checkIfAnyOutOfStock = () => {
    return new Promise((resolve, reject) => {
      var tmpcart = { ...cart };
      responData.productCatItemVariations[0] &&
        responData.productCatItemVariations[0]?.VariationDetails.map(
          (item, index) => {
            if (item.outOfStock) {
              if (item.variationId === tmpcart.variationId) {
                alert("item is out of stock");
                reject();
              }
            }
          }
        );
      resolve();
    });
  };
  /*** this function will check if  the default minimum and maximum set by admin   */
  const checkForDefaultMinMax = () => {
    return new Promise((resolve, reject) => {
      let tmpCartForCondition = { ...cart };
      var isError = false;
      alreadyArr &&
        alreadyArr.map((singleItem) => {
          if (singleItem.isDefault) {
            let matchItems = tmpCartForCondition.modifier.filter(
              (x) => x.modifierCatgegoryId === singleItem._id
            ).length;
            let min = singleItem.min ?? 0;
            let max = singleItem.max ?? 0;
            if (min <= matchItems && matchItems <= max) {
              let tmpError = error;
              let preIndex = tmpError.findIndex(
                (x) => x.modifierCatgegoryId === singleItem._id
              );
              if (preIndex != -1) {
                tmpError.splice(preIndex, 1);
                seterror(tmpError);
              }
            } else {
              isError = true;
              // tmpError = error;
              // preIndex = tmpError.findIndex(x => x.modifierCatgegoryId === singleItem._id);
              // if (preIndex === -1) {
              seterror((pre) => [
                ...pre,
                {
                  modifierCatgegoryId: singleItem._id,
                  data: `${
                    singleItem.MadifierCategoryName
                  } is mandatory, Please select Minimum ${
                    singleItem.min ?? 0
                  } and Maximum ${singleItem.max ?? 0} items`,
                },
              ]);
              // }
            }
          }
        });
      if (isError) {
        reject();
      } else {
        resolve();
      }
    });
  };
  const handleValidationOfAddToCart = () => {
    return (
      !disablebutton &&
      modefiers.length > 0 &&
      responData.productCatItemVariations.length > 0
    );
  };
  /** this is reactive componant used to render footer  */
  const Footer = () => {
    return (
      <div className="customfooter" style={{ margin: "0rem !important" }}>
        <div className="cstfooter-inner">
          <div className="f-price">{`$${priceMature(itemPrice)}`}</div>
          <div
            className="addcardbt"
            onClick={() => {
              // Removing old cart data after clicking button
              localStorage.removeItem("oldItem");
              if (handleValidationOfAddToCart()) {
                isEdited ? handleUpdateCart() : handleAddToCart();
              }
            }}
          >
            {/* For Jira ticket OP-43 */}
            {Number(priceMature(itemPrice)) > 0 && (
              <button className="btn">Add To Cart</button>
            )}
          </div>
        </div>
      </div>
    );
  };
  /**this is reactive component used to render  the modifier category default minimum and maximum errors */
  const Error = ({ modifierCatgegoryId }) => {
    let fil = error.filter((x) => x.modifierCatgegoryId == modifierCatgegoryId);
    if (fil.length > 0) {
      return <span className="c-red">{fil[0].data}</span>;
    } else {
      return <></>;
    }
  };
  return (
    <>
      <CostumModal
        show={show}
        handleClose={customizeModal}
        title={itemName}
        footer={<Footer />}
      >
        {responData.productCatItemVariations && (
          <div className="pizza-size cust-title popupLabels">
            {isEdited ? (
              <>
                <h3>Select Size</h3>
                {responData.productCatItemVariations[0] &&
                  responData.productCatItemVariations[0]?.VariationDetails.map(
                    (item, index) => {
                      return (
                        <label className="radio-img" key={index}>
                          {item.outOfStock !== true ? (
                            <>
                              <input
                                type="radio"
                                value={index}
                                name="layout"
                                checked={
                                  cart.variationId === item.variationId
                                    ? true
                                    : false
                                }
                                onChange={() => {}}
                              />
                              <div
                                className="image"
                                style={{
                                  backgroundImage: `url(${largePizza})`,
                                }}
                                onClick={() =>
                                  handleVariationChnage(
                                    item.variationPrice,
                                    item.variationId,
                                    item.variationName,
                                    index
                                  )
                                }
                              >
                                <p>{item.variationName}</p>
                              </div>
                            </>
                          ) : (
                            <div
                              className="image"
                              style={{ backgroundImage: `url(${largePizza})` }}
                            >
                              <p>{item.variationName} (Out of stock)</p>
                            </div>
                          )}
                        </label>
                      );
                    }
                  )}
              </>
            ) : (
              <>
                <h3>Select Size</h3>

                {responData.productCatItemVariations < 1 ? (
                  <ComponentLoader />
                ) : (
                  ""
                )}
                {responData?.productCatItemVariations[0] &&
                  responData?.productCatItemVariations[0]?.VariationDetails.map(
                    (item, index) => {
                      return (
                        <label className="radio-img" key={index}>
                          {item.outOfStock !== true ? (
                            <>
                              <input
                                type="radio"
                                value={index}
                                name="layout"
                                defaultChecked={index === 0 ? true : false}
                              />
                              <div
                                className="image"
                                style={{
                                  backgroundImage: `url(${largePizza})`,
                                }}
                                onClick={() =>
                                  handleVariationChnage(
                                    item.variationPrice,
                                    item.variationId,
                                    item.variationName,
                                    index
                                  )
                                }
                              >
                                <p>{item.variationName}</p>
                              </div>
                            </>
                          ) : (
                            <div
                              className="image"
                              style={{ backgroundImage: `url(${largePizza})` }}
                            >
                              <p>{item.variationName} (Out of stock)</p>
                            </div>
                          )}
                        </label>
                      );
                    }
                  )}
              </>
            )}
          </div>
        )}
        {modefiers.length < 1 ? <ComponentLoader /> : ""}
        {isEdited ? (
          <Accordion defaultActiveKey="0">
            {modefiers[0]?.ModifierDetails.map((modefier, index) => {
              var text = modefier.modifierCatgegoryName[0].MadifierCategoryName;
              userid = modefier.modifierCatgegoryName[0].UniqueID;
              if (temp == userid) {
                temp = "0";
              } else {
                temp = userid;
              }
              if (already.includes(text)) {
                return;
              }
              already.push(text);
              alreadyArr.push(modefier.modifierCatgegoryName[0]);
              return (
                <Card key={index}>
                  <Accordion.Toggle
                    className="card-head"
                    variant="link"
                    as={Card.Header}
                    eventKey={index + 1}
                  >
                    {modefier.modifierCatgegoryName[0].MadifierCategoryName}
                    <Error
                      modifierCatgegoryId={
                        modefier.modifierCatgegoryName[0]._id
                      }
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse className="show" eventKey={index + 1}>
                    <Card.Body>
                      <div className="customizeoption cust-title">
                        <div className="customizlist">
                          {modefiers.length > 0 &&
                            modefiers[0].ModifierDetails.map(
                              (modefier2, index2) => {
                                if (
                                  modefier2.modifierCatgegoryName[0]
                                    .MadifierCategoryName === text
                                )
                                  return (
                                    <>
                                      <div className="customchk" key={index2}>
                                        <input
                                          type="checkbox"
                                          defaultChecked={
                                            cart?.modifier?.findIndex(
                                              (arr) =>
                                                arr.modifierId ===
                                                  modefier2.modifiersId &&
                                                arr.isDeleted !== true
                                            ) === -1
                                              ? false
                                              : true
                                          }
                                          id={index2}
                                          className="modifier-checkbox"
                                          onClick={() =>
                                            addremove(
                                              modefier2.modifiersId,
                                              modefier2.ModifierName,
                                              modefier2.modifierPriceData.filter(
                                                (x) =>
                                                  x.varationsId === veriation
                                              )[0]?.Price,
                                              index2,
                                              modefier2.isDefault,
                                              modefier2.modifierCatgegoryName[0]
                                                ._id
                                            )
                                          }
                                        />
                                        <label htmlFor={index2}>
                                          {" "}
                                          {modefier2.ModifierName}{" "}
                                        </label>

                                        {halfTopping ? (
                                          modefier2.modifierPriceData.filter(
                                            (x) => x.varationsId === veriation
                                          )[0]?.halfToping ? (
                                            <Sizes
                                              modifierPrice={
                                                modefier2.modifierPriceData.filter(
                                                  (x) =>
                                                    x.varationsId === veriation
                                                )[0]?.Price
                                              }
                                              modifiersId={
                                                modefier2.modifiersId
                                              }
                                              isDefault={modefier2.isDefault}
                                            />
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          ""
                                        )}
                                        {modefier2.modifierPriceData.filter(
                                          (x) => x.varationsId === veriation
                                        )[0]?.isDouble ? (
                                          <DoubleComponent
                                            modifierPrice={
                                              modefier2.modifierPriceData.filter(
                                                (x) =>
                                                  x.varationsId === veriation
                                              )[0]?.Price
                                            }
                                            modifiersId={modefier2.modifiersId}
                                            isDefault={modefier2.isDefault}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  );
                              }
                            )}
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        ) : (
          <Accordion defaultActiveKey="0">
            {modefiers[0]?.ModifierDetails.map((modefier, index) => {
              var text = modefier.modifierCatgegoryName[0].MadifierCategoryName;
              userid = modefier.modifierCatgegoryName[0].UniqueID;
              if (temp == userid) {
                temp = "0";
              } else {
                temp = userid;
              }
              if (already.includes(text)) {
                return;
              }
              already.push(text);
              alreadyArr.push(modefier.modifierCatgegoryName[0]);
              return (
                <Card key={index}>
                  <Accordion.Toggle
                    className="card-head"
                    as={Card.Header}
                    eventKey={index + 1}
                  >
                    {modefier.modifierCatgegoryName[0].MadifierCategoryName}
                    <Error
                      modifierCatgegoryId={
                        modefier.modifierCatgegoryName[0]._id
                      }
                    />
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body>
                      <div className="customizeoption cust-title">
                        <div className="customizlist">
                          {modefiers.length > 0 &&
                            modefiers[0].ModifierDetails.map(
                              (modefier2, index2) => {
                                if (
                                  modefier2.modifierCatgegoryName[0]
                                    .MadifierCategoryName === text
                                )
                                  return (
                                    <>
                                      <div className="customchk" key={index2}>
                                        <input
                                          type="checkbox"
                                          defaultChecked={modefier2.isDefault}
                                          id={index2}
                                          className="modifier-checkbox"
                                          onClick={() =>
                                            addremove(
                                              modefier2.modifiersId,
                                              modefier2.ModifierName,
                                              modefier2.modifierPriceData.filter(
                                                (x) =>
                                                  x.varationsId === veriation
                                              )[0]?.Price,
                                              index2,
                                              modefier2.isDefault,
                                              modefier2.modifierCatgegoryName[0]
                                                ._id
                                            )
                                          }
                                        />
                                        <label htmlFor={index2}>
                                          {" "}
                                          {modefier2.ModifierName}{" "}
                                        </label>
                                        {halfTopping ? (
                                          modefier2.modifierPriceData.filter(
                                            (x) => x.varationsId === veriation
                                          )[0]?.halfToping ? (
                                            <Sizes
                                              modifierPrice={
                                                modefier2.modifierPriceData.filter(
                                                  (x) =>
                                                    x.varationsId === veriation
                                                )[0]?.Price
                                              }
                                              modifiersId={
                                                modefier2.modifiersId
                                              }
                                              isDefault={modefier2.isDefault}
                                            />
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          ""
                                        )}
                                        {modefier2.modifierPriceData.filter(
                                          (x) => x.varationsId === veriation
                                        )[0]?.isDouble ? (
                                          <DoubleComponent
                                            modifierPrice={
                                              modefier2.modifierPriceData.filter(
                                                (x) =>
                                                  x.varationsId === veriation
                                              )[0]?.Price
                                            }
                                            modifiersId={modefier2.modifiersId}
                                            isDefault={modefier2.isDefault}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  );
                              }
                            )}
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        )}
      </CostumModal>
      <NormalModal
        mainClass="modal custommodal custommodel1"
        showModal={confirmSize ? "yes" : "no"}
        closeModal={() => setConfirmSize(false)}
        title="Confirm size"
        footer={
          <div className="modal-footer custom-model-footer">
            <div className="customfooter position-relative">
              <div className="cstfooter-inner justify-content-end">
                <div className="BtnWrapper">
                  <button
                    className="btn cancel mr-2"
                    data-dismiss="modal"
                    onClick={() => setConfirmSize(false)}
                  >
                    No
                  </button>
                  <button
                    className="btn ok"
                    onClick={onAddCart}
                    // onClick={() => {
                    //   // Removing old cart data after clicking button
                    //   localStorage.removeItem("oldItem");
                    //   if (handleValidationOfAddToCart()) {
                    //     isEdited ? handleUpdateCart() : handleAddToCart();
                    //   }
                    // }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <p>
          Are you sure you want to proceed with{" "}
          <strong>{cart?.variationName}</strong> size?
        </p>
        {/* <div className="pizza-size cust-title popupLabels">
          {responData.productCatItemVariations < 1 ? <ComponentLoader /> : ""}
          {responData?.productCatItemVariations[0] &&
            responData?.productCatItemVariations[0]?.VariationDetails.map(
              (item, index) => {
                return (
                  <label className="radio-img" key={index}>
                    {item.outOfStock !== true ? (
                      <>
                        <input
                          type="radio"
                          value={index}
                          name="layout"
                          defaultChecked={index === 0 ? true : false}
                        />
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${largePizza})`,
                          }}
                          onClick={() =>
                            handleVariationChnage(
                              item.variationPrice,
                              item.variationId,
                              item.variationName,
                              index
                            )
                          }
                        >
                          <p>{item.variationName}</p>
                        </div>
                      </>
                    ) : (
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${largePizza})` }}
                      >
                        <p>{item.variationName} (Out of stock)</p>
                      </div>
                    )}
                  </label>
                );
              }
            )}
        </div> */}
      </NormalModal>
    </>
  );
};
export default memo(CostumCart);
