import { combineReducers } from "redux";
import userLoginReducer from "./userLoginReducer";
import manageProfileReducer from "./manageProfileReducer";
import manageMenuReducer from "./manageMenuReducer";
import manageModifierReducer from "./manageModifierReducer";
import manageProductCategoryReducer from "./manageProductCategoryReducer";
import manageModifierCategoryReducer from "./manageModifierCategoryReducer";
import manageMasterModifierReducer from "./manageMasterModifierReducer";
import userLogoutReducer from "./userLogoutReducer";
import manageCartReducer from "./manageCartReducer";
import DelivaryOrPickup from "./delivaryOrPickupReducer";
import manageOrderReducer from "./manageOrdersReducer.com";
import manageCrewOrDriver from "./manageCrewOrDriverReducer";
import manageCrewOrDriverOrder from "./manageCrewOrDriverOrder";
import managePayment from "./managePaymentReducer";
import deliveryAndCharges from "./deliveryAndCharges";
import manageRestaurentReducer from "./manageRestaurentTiming";
import manageVariationReducer from "./manageVariationReducer";
import customerReducer from "./customerAuthanticationReducer";
import manageDiscountReducer from "./manageDiscountReducer";
import HomeBannerReducer from "./homeBannerReducer";
import manageRoleAndPermission from "./manageRoleAndPermission";
import manageUsers from "./manageUsersReducer";
import couponReducer from "./manageCoupon";
import manageCustomer from "./manageCustomerReducer";
import locationReducer from "./locationReducer";
import AddressAndPhone from "./addressAndPhone";
import deliveryReducer from "./deliveryReducer";
/***
 * This is root reduce
 * ** */
export default combineReducers({
  login: userLoginReducer,
  logOut: userLogoutReducer,
  getManageProfile: manageProfileReducer,
  manageMenu: manageMenuReducer,
  manageModifier: manageModifierReducer,
  productCat: manageProductCategoryReducer,
  modifierCat: manageModifierCategoryReducer,
  masterModi: manageMasterModifierReducer,
  cart: manageCartReducer,
  delivaryOrPickup: DelivaryOrPickup,
  order: manageOrderReducer,
  crewOrDriver: manageCrewOrDriver,
  crewOrDriverOrder: manageCrewOrDriverOrder,
  payment: managePayment,
  deliveryAndCharges: deliveryAndCharges,
  delivery: deliveryReducer,
  restaurentTiming: manageRestaurentReducer,
  variation: manageVariationReducer,
  customer: customerReducer,
  discount: manageDiscountReducer,
  homeBanner: HomeBannerReducer,
  roleAndPermission: manageRoleAndPermission,
  users: manageUsers,
  coupon: couponReducer,
  customers: manageCustomer,
  location: locationReducer,
  addressAndPhone: AddressAndPhone,
});
