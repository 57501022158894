import React, { useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/";
import { useLocation } from "react-router-dom";

const PublicContainer = ({ children }) => {
  const location = useLocation();
  return (
    <>
      {["/login", "/forgot-password"].includes(location.pathname) === false ? (
        <Header />
      ) : (
        ""
      )}
      <div className="public_wrapper">{children}</div>
      {(location.pathname === "/login") === false ? <Footer /> : ""}
    </>
  );
};

export default PublicContainer;
