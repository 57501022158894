import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo_img from "../../Assets/images/o_original_logo.jpg";
import dashboardIcon from "../../Assets/images/004-id-card.svg";
import manageProfile from "../../Assets/images/003-menu.svg";
import manageMenu from "../../Assets/images/food.png";
import shoppingCart from "../../Assets/images/order.png";
import manageitemCategory from "../../Assets/images/food-category.png";
import managePayment from "../../Assets/images/bill.png";
import manageDelivery from "../../Assets/images/payment.png";
import manageProfile2 from "../../Assets/images/user.png";
import managemodifier from "../../Assets/images/category.png";
import managemodifierPrice from "../../Assets/images/price.png";
import deliveryman from "../../Assets/images/delivery-man.png";
import archive1 from "../../Assets/images/archive1.png";
import { useSelector } from "react-redux";
import { getPermission } from "../../Services/manageRolesAndPermission";
import o_original_logo from "../../__Assets/images/o_original_logo.jpg";
function SidebarComponent() {
  const [collepsed, setcollepsed] = useState({
    generateRestaurantReports: false,
    archives: false,
  });
  const icons = {
    dashboardIcon,
    manageDelivery,
    manageProfile,
    manageMenu,
    shoppingCart,
    manageitemCategory,
    managePayment,
    manageProfile2,
    managemodifier,
    managemodifierPrice,
    deliveryman,
    archive1,
  };
  const navData = [
    {
      uri: "/dashboard",
      pageName: "Dashboard",
      pageAliasName: "Dashboard",
      icon: "dashboardIcon",
    },
    {
      uri: "/manage-roles",
      pageName: "Manage Roles",
      pageAliasName: "manageRoles",
      icon: "manageDelivery",
    },
    {
      uri: "/manage-users",
      pageName: "Manage Users",
      pageAliasName: "manageUsers",
      icon: "manageProfile2",
    },
    {
      uri: "/manage-customers",
      pageName: "Manage Customers",
      pageAliasName: "manageUsers",
      icon: "manageProfile2",
    },
    {
      uri: "/manage-profile",
      pageName: "Manage Profile",
      icon: "manageProfile2",
    },
    {
      uri: "/manage-modifier-category",
      pageName: "Manage Modifier Category",
      pageAliasName: "manageModifierCategory",
      icon: "managemodifier",
    },
    {
      uri: "/manage-master-modifier",
      pageName: "Manage Master Modifier",
      pageAliasName: "manageMasterModifier",
      icon: "manageProfile",
    },
    {
      uri: "/manage-variation",
      pageName: "Manage Variations",
      pageAliasName: "manageVariation",
      icon: "manageDelivery",
    },
    {
      uri: "/manage-modifier",
      pageName: "Manage Modifier Price",
      pageAliasName: "manageModifierPrice",
      icon: "managemodifierPrice",
    },

    {
      uri: "/manage-product-category",
      pageName: "Manage Item Category",
      pageAliasName: "manageItemCategory",
      icon: "manageitemCategory",
    },
    {
      uri: "/manage-menu-item",
      pageName: "Manage Menu Item",
      pageAliasName: "manageMenuItem",
      icon: "manageMenu",
    },
    {
      uri: "/manage-orders",
      pageName: "Manage Order",
      pageAliasName: "manageOrder",
      icon: "shoppingCart",
    },
    {
      uri: "/manage-crew-driver-user",
      pageName: "Manage Crew/Drivers",
      pageAliasName: "manageCrewAndDriver",
      icon: "deliveryman",
    },
    {
      uri: "/manage-payment",
      pageName: "Manage Payment",
      pageAliasName: "managePayment",
      icon: "managePayment",
    },
    {
      uri: "/manage-settings",
      pageName: "Manage Restaurant Settings",
      pageAliasName: "manageRestaurantSettings",
      icon: "manageDelivery",
    },
    {
      uri: "",
      pageName: "Generate Restaurant Reports",
      pageAliasName: "generateRestaurantReports",
      icon: "manageDelivery",
      child: [
        {
          uri: "/sales-reports",
          pageName: "Sales Report",
          pageAliasName: "salesReport",
          icon: "manageDelivery",
        },
        {
          uri: "/driver-reports",
          pageName: "Daily Driver Fee Report",
          pageAliasName: "dailydriverReport",
          icon: "manageDelivery",
        },
        {
          uri: "/variation-reports",
          pageName: "Variation Report",
          pageAliasName: "variationReport",
          icon: "manageDelivery",
        },
        {
          uri: "/doordash-reports",
          pageName: "Doordash Report",
          pageAliasName: "doordashReport",
          icon: "manageDelivery",
        },
      ],
    },
    {
      uri: "",
      pageName: "Archive",
      pageAliasName: "archives",
      icon: "archive1",
      child: [
        {
          uri: "/manage-orders-archive",
          pageName: "Archive Orders",
          pageAliasName: "orderArchive",
          icon: "manageDelivery",
        },
        {
          uri: "/sales-reports-archive",
          pageName: "Archive Sales Report",
          pageAliasName: "salesReportArchive",
          icon: "manageDelivery",
        },
        {
          uri: "/manage-payments-archive",
          pageName: "Archive Payments",
          pageAliasName: "managePaymentArchive",
          icon: "manageDelivery",
        },
      ],
    },
    {
      uri: "/manage-locations",
      pageName: "Manage Locations",
      pageAliasName: "manageLocation",
      icon: "dashboardIcon",
    },
    {
      uri: "/manage-food-price",
      pageName: "Manage Food Price",
      pageAliasName: "manageCalculator",
      icon: "dashboardIcon",
    },
    {
      uri: "/food-calculator",
      pageName: "Food Calculator",
      pageAliasName: "",
      icon: "dashboardIcon",
    },
  ];
  const [nav, setnav] = useState([]);
  const handleCollepsed = (type) => {
    setcollepsed({ ...collepsed, [type]: !collepsed[type] });
  };
  const login = useSelector((state) => state.login);
  const [userLogo, setUserLogo] = useState([]);
  const [userLogo2, setUserLogo2] = useState([]);
  useEffect(() => {
    setUserLogo([...userLogo, login.userData.icon]);
    if (userLogo.length > 2) setUserLogo([userLogo[0], userLogo[1]]);
  }, [login]);
  useEffect(() => {
    let logoFromLocalStorage = JSON.parse(localStorage.getItem("userData"));
    if (logoFromLocalStorage) {
      setUserLogo2([...userLogo2, logoFromLocalStorage.icon]);
    }
  }, []);
  let logoToShow =
    userLogo2.length > 0
      ? userLogo2[0]
      : userLogo[1]
      ? userLogo[1]
      : o_original_logo;
  useEffect(async () => {
    if (Object.keys(login?.userData).length > 0) {
      if (login?.userData?.role === "superAdmin") {
        setnav(navData);
      } else {
        let fetchAllPermission = await getPermission();
        fetchAllPermission = fetchAllPermission.result;
        let filteredNav = [
          {
            uri: "/manage-profile",
            pageName: "Manage Profile",
            icon: "manageProfile2",
          },
          {
            uri: "/dashboard",
            pageName: "Dashboard",
            pageAliasName: "Dashboard",
            icon: "dashboardIcon",
          },
        ];
        let pushableNode;
        login.permission.forEach((item, index, arr) => {
          if (item.parentId) {
            //find if filteredNav already has this parent id
            let parentIndex = filteredNav.findIndex(
              (e) => e._id == item.parentId
            );
            if (parentIndex != -1) {
              filteredNav[parentIndex]["child"].push({ ...item });
            } else {
              pushableNode = {
                ...fetchAllPermission.find((e) => e._id == item.parentId),
                foo: true,
                child: [{ ...item }],
              };
              filteredNav.push(pushableNode);
            }
          } else {
            pushableNode = { ...item };
            filteredNav.push(pushableNode);
          }
        });
        setnav(filteredNav);
      }
    }
  }, [login]);
  return (
    <>
      <div className="left-panel" id="leftmenusection">
        <div className="logo">
          <img src={logoToShow} className="img-fluid" alt="logo_img" />
        </div>
        <div
          className="manu-section"
          style={{
            height: "87vh",
            overflow: "auto",
          }}
        >
          <ul>
            {nav.map((item, i) => (
              <li key={i}>
                {item.uri ? (
                  <NavLink activeClassName="active" to={item.uri}>
                    <img src={icons[item.icon]} alt="" />{" "}
                    <span> {item.pageName}</span>
                  </NavLink>
                ) : (
                  <a
                    onClick={() => {
                      handleCollepsed(item.pageAliasName);
                    }}
                  >
                    <img src={icons[item.icon]} alt="" />{" "}
                    <span> {item.pageName}</span>
                  </a>
                )}
                {item.child ? (
                  <ul
                    style={{
                      marginLeft: "50px",
                      display: collepsed[item.pageAliasName] ? "block" : "none",
                    }}
                  >
                    {item.child?.map((cItem, CIndex) => {
                      return (
                        <li key={1 + CIndex}>
                          <NavLink activeClassName="active" to={cItem.uri}>
                            <img src={icons[cItem.icon]} alt="" />{" "}
                            <span> {cItem.pageName}</span>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default SidebarComponent;
