import React from 'react'
// import o_original_logo from "../overlay/o_original_logo.jpg";
// import { NavLink } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
  //   <div
  //   id="myNav"
  //   className="overlay"
  //   style={{ backgroundColor: "rgb(48 119 49)" }}
  // >
    <div className=" bg-white h-100 h-mobile-auto overlay-inner">
      <div className="p-5 h-100 custom-overflow-wrapper">
        {/* <NavLink className="logo" href="/">
          <div className="welcome-logo">
            <img className="img-fluid" src={o_original_logo} />
          </div>
        </NavLink> */}
        <div className="privacy-contain txt-contain">
          <h3 className="display-4">
            PRIVACY POLICY
          </h3>
           <div className="menu-item">
                <span className="menu-item-title">
                WHAT PERSONAL INFORMATION DO WE COLLECT FROM THE PEOPLE THAT VISIT OUR BLOG, WEBSITE OR APP?
                </span>
                <div className='menu-item-description'>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number or other details to help you with your experience.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WHEN DO WE COLLECT INFORMATION?
                </span>
                <div className='menu-item-description'>We collect information from you when you register on our site, subscribe to a newsletter or enter information on our site.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                NO MOBILE INFORMATION WILL BE SHARED WITH THIRD PARTIES/AFFILIATES FOR MARKETING/PROMOTIONAL PURPOSES. ALL THE ABOVE CATEGORIES EXCLUDE TEXT MESSAGING ORIGINATOR OPT-IN DATA AND CONSENT; THIS INFORMATION WILL NOT BE SHARED WITH ANY THIRD PARTIES.
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                HOW DO WE USE YOUR INFORMATION?
                </span>
                <div className='menu-item-description'>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                TO PERSONALIZE USER’S EXPERIENCE AND TO ALLOW US TO DELIVER THE TYPE OF CONTENT AND PRODUCT OFFERINGS IN WHICH YOU ARE MOST INTERESTED.
                </span>
                <div className='menu-item-description'>To improve our website in order to better serve you.</div>
                <div className='menu-item-price-bottom'>HOW DO WE PROTECT VISITOR INFORMATION?</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                OUR WEBSITE IS SCANNED ON A REGULAR BASIS FOR SECURITY HOLES AND KNOWN VULNERABILITIES IN ORDER TO MAKE YOUR VISIT TO OUR SITE AS SAFE AS POSSIBLE.
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WE USE REGULAR MALWARE SCANNING.
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WE DO NOT USE AN SSL CERTIFICATE. WE ONLY PROVIDE ARTICLES AND INFORMATION, WE NEVER ASK FOR PERSONAL OR PRIVATE INFORMATION LIKE CREDIT CARD NUMBERS.
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                DO WE USE ‘COOKIES’?
                </span>
                <div className='menu-item-description'>Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WE USE COOKIES TO:
                </span>
                <div className='menu-item-title title-2'>HELP REMEMBER AND PROCESS THE ITEMS IN THE SHOPPING CART.</div>
                <div className='menu-item-description'>Understand and save user’s preferences for future visits.</div>
                <div className='menu-item-price-bottom'>YOU CAN CHOOSE TO HAVE YOUR COMPUTER WARN YOU EACH TIME A COOKIE IS BEING SENT, OR YOU CAN CHOOSE TO TURN OFF ALL COOKIES. YOU DO THIS THROUGH YOUR BROWSER (LIKE INTERNET EXPLORER) SETTINGS. EACH BROWSER IS A LITTLE DIFFERENT, SO LOOK AT YOUR BROWSER’S HELP MENU TO LEARN THE CORRECT WAY TO MODIFY YOUR COOKIES.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                IF YOU DISABLE COOKIES OFF, SOME FEATURES WILL BE DISABLED, AFFECTING THE USER EXPERIENCE AND SOME OF OUR SERVICES WILL NOT FUNCTION PROPERLY.
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                THIRD PARTY DISCLOSURE
                </span>
                <div className='menu-item-description'>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                HOWEVER, NON-PERSONALLY IDENTIFIABLE VISITOR INFORMATION MAY BE PROVIDED TO OTHER PARTIES FOR MARKETING, ADVERTISING, OR OTHER USES.
                </span>
                <div className='menu-item-description'>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                THIRD PARTY LINKS
                </span>
                <div className='menu-item-description'>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                GOOGLE
                </span>
                <div className='menu-item-description'>Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users.</div>
                  <a href="https://support.google.com/adwordspolicy/answer/$1316548?HL=EN">https://support.google.com/adwordspolicy/answer/$1316548?HL=EN</a> 
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WE USE GOOGLE ADSENSE ADVERTISING ON OUR WEBSITE.
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                GOOGLE, AS A THIRD PARTY VENDOR, USES COOKIES TO SERVE ADS ON OUR SITE. GOOGLE’S USE OF THE DART COOKIE ENABLES IT TO SERVE ADS TO OUR USERS BASED ON THEIR VISIT TO OUR SITE AND OTHER SITES ON THE INTERNET. USERS MAY OPT OUT OF THE USE OF THE DART COOKIE BY VISITING THE GOOGLE AD AND CONTENT NETWORK PRIVACY POLICY.
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WE HAVE IMPLEMENTED THE FOLLOWING:
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                DEMOGRAPHICS AND INTERESTS REPORTING
                </span>
                <div className='menu-item-description'>We along with third-party vendors, such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions, and other ad service functions as they relate to our website.</div>

              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                OPTING OUT: USERS CAN SET PREFERENCES FOR HOW GOOGLE ADVERTISES TO YOU USING THE GOOGLE AD SETTINGS PAGE. ALTERNATIVELY, YOU CAN OPT OUT BY VISITING THE NETWORK ADVERTISING INITIATIVE OPT-OUT PAGE OR PERMANENTLY USING THE GOOGLE ANALYTICS OPT-OUT BROWSER ADD ON.
                </span>

              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                CALIFORNIA ONLINE PRIVACY PROTECTION ACT
                </span>
                <div className='menu-item-description'>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy.</div>

              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                SEE MORE AT: <a href="HTTP://CONSUMERCAL.ORG/CALIFORNIA-ONLINE-PRIVACY-PROTECTION-ACT-CALOPPA/#STHASH.0FDRBT51.DPUF">HTTP://CONSUMERCAL.ORG/CALIFORNIA-ONLINE-PRIVACY-PROTECTION-ACT-CALOPPA/#STHASH.0FDRBT51.DPUF</a> 
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                ACCORDING TO CALOPPA WE AGREE TO THE FOLLOWING:
                </span>
                <div className='menu-item-description'>Users can visit our site anonymously. Once this privacy policy is created, we will add a link to it on our home page, or as a minimum on the first significant page after entering our website. Our Privacy Policy link includes the word ‘Privacy’, and can be easily be found on the page specified above.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                USERS WILL BE NOTIFIED OF ANY PRIVACY POLICY CHANGES:
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                ON OUR PRIVACY POLICY PAGE
                </span>
                <div className='menu-item-description'>Users are able to change their personal information:</div>

              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                BY EMAILING US
                </span>
                <div className='menu-item-description'>How does our site handle do not track signals?</div>
                <div className='menu-item-price-bottom'>WE HONOR DO NOT TRACK SIGNALS AND DO NOT TRACK, PLANT COOKIES, OR USE ADVERTISING WHEN A DO NOT TRACK (DNT) BROWSER MECHANISM IS IN PLACE.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                DOES OUR SITE ALLOW THIRD PARTY BEHAVIORAL TRACKING?
                </span>
                <div className='menu-item-description'>When it comes to the collection of personal information from children under 13, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation’s consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children’s privacy and safety online.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WE DO NOT SPECIFICALLY MARKET TO CHILDREN UNDER 13.
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                FAIR INFORMATION PRACTICES
                </span>
                <div className='menu-item-description'>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                IN ORDER TO BE IN LINE WITH FAIR INFORMATION PRACTICES WE WILL TAKE THE FOLLOWING RESPONSIVE ACTION, SHOULD A DATA BREACH OCCUR:
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WE WILL NOTIFY THE USERS VIA EMAIL
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WITHIN 7 BUSINESS DAYS
                </span>
                <div className='menu-item-description'>We will notify the users via in site notification</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WITHIN 7 BUSINESS DAYS
                </span>
                <div className='menu-item-description'>We also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                CAN SPAM ACT
                </span>
                <div className='menu-item-description'>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</div>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                WE COLLECT YOUR EMAIL ADDRESS IN ORDER TO:
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                TO BE IN ACCORDANCE WITH CANSPAM WE AGREE TO THE FOLLOWING:
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                IF AT ANY TIME YOU WOULD LIKE TO UNSUBSCRIBE FROM RECEIVING FUTURE EMAILS,<br />
                YOU CAN EMAIL US AND WE WILL PROMPTLY REMOVE YOU FROM ALL CORRESPONDENCE.
                </span>
              </div>
              <div className="menu-item">
                <span className="menu-item-title">
                CONTACTING US
                </span>
                <div className='menu-item-description'>If there are any questions regarding this privacy policy you may contact us by emailing <a href="mailto:support@oliverspizza.com">support@oliverspizza.com</a>.</div>                
              </div>
        </div>
      </div>
    </div>
  // </div>
  )
}

export default PrivacyPolicy