import React, { useState, useEffect, useCallback } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaHandHoldingUsd, FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../helpers/ConfirmModal";
import BootstrapeTableData from "../../helpers/BootstrapeTableData";
import { PageLoader, ComponentLoader } from "../../helpers/PageLoader";
import {
  getOrdersList,
  readOrderStatus,
} from "../../Action/manageOrdersAction";
import ManageOrderPopup from "./MangeOrderPopup";
import SocketHandler from "../../helpers/socketHandler";
import formettedDate from "../../helpers/moomentDate";
import ManageOrderRefund from "./refundOrderPopup";
import { getAllOrderList } from "../../Services/manageOrderServices";
const ManageOrderComponent = ({ socket }) => {
  const responseData = useSelector((state) => state.order);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [litsData, setLitsData] = useState([]);
  const [showModal, setShowModal] = useState("no");
  const [showRefundModal, setRefundShowModal] = useState("no");
  const [isTodayCheckBox, setisTodayCheckBox] = useState(true);
  const [deletConfirmModal, setDeletConfirmModal] = useState({
    show: false,
    data: [],
  });
  const [isLoding, setIsLoading] = useState({
    pageLoading: false,
    tableLoading: false,
  });
  const isToday = (someDate) => {
    const today = new Date();
    someDate = new Date(someDate);
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  /* this function will return the correct payment type display */
  const displayPaymentType = (type) => {
    switch (type) {
      case "wallet":
        return "Pay By Wallet";
      case "card":
        return "Pay By Card";
      case "partial":
        return "Partially Paid";
    }
  };
  /** this function will add the mask for us number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    value = value.toString();
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
    userData: {},
    paymentData: {},
  });
  const [permission, setpermission] = useState({
    add: false,
    update: false,
    delete: false,
  });
  const columns = [
    {
      dataField: "randomOrderId",
      text: "Id",
      sort: true,
    },
    {
      dataField: "OrderServiceOption",
      text: "Service Option",
      sort: true,
    },
    {
      dataField: "OrderStatusCode",
      text: "Status",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date Of Orders",
      formatter: (cell, row) => {
        return formettedDate(cell);
      },
      filterValue: (cell, row) => {
        return formettedDate(cell);
      },
      sortValue: (cell, row) => {
        return formettedDate(cell);
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Method",
      formatter: (cell, row) => {
        return (
          <span key={Date.now()}>
            {row?.payments[0]?.paymentMethod === "cod"
              ? "Cash On Delivery"
              : displayPaymentType(row?.payments[0]?.paymentType)}
          </span>
        );
      },
      filterValue: (cell, row) => {
        return row?.payments[0]?.paymentMethod === "cod"
          ? "Cash On Delivery"
          : displayPaymentType(row?.payments[0]?.paymentType);
      },
      sortValue: (cell, row) => {
        return row?.payments[0]?.paymentMethod === "cod"
          ? "Cash On Delivery"
          : displayPaymentType(row?.payments[0]?.paymentType);
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Status",
      formatter: (cell, row) => {
        try {
          return row?.payments[0]?.paymentStatus;
        } catch {
          return null;
        }
      },
      filterValue: (cell, row) => {
        try {
          return row?.payments[0]?.paymentStatus;
        } catch {
          return null;
        }
      },
      sortValue: (cell, row) => {
        try {
          return row?.payments[0]?.paymentStatus;
        } catch {
          return null;
        }
      },
      sort: true,
    },
    {
      dataField: "assignTo",
      text: "Assign To",
      formatter: (cell, row) => {
        try {
          if (
            (row?.OrderStatusCode === "driver" ||
              row?.OrderStatusCode === "delivered" ||
              row?.OrderStatusCode === "rejected") &&
            row?.assignToDoordash?.at(0)
          ) {
            return `${row.assignToDoordash?.at(0)?.name} ${
              row?.rejectByDriver ? "(Rejected)" : ""
            }`;
          }

          return `${row?.assignTo?.at(0).fname}
              ${row?.assignTo?.at(0).lname} 
              ${(row.rejectByDriver ? "(Rejected)" : "").trim()}`;
        } catch (e) {
          console.log(e, "--------- Found error");
          return null;
        }
      },
      filterValue: (cell, row) => {
        try {
          return (
            row.assignTo[0].fname +
            " " +
            row.assignTo[0].lname +
            (row.rejectByDriver ? "(Rejected)" : "").trim()
          );
        } catch (e) {
          return null;
        }
      },
      sortValue: (cell, row) => {
        try {
          return (
            row.assignTo[0].fname +
            " " +
            row.assignTo[0].lname +
            (row.rejectByDriver ? "(Rejected)" : "").trim()
          );
        } catch (e) {
          return null;
        }
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Customer Name",
      formatter: (cell, row) => {
        try {
          return row.users[0].fname + " " + row.users[0].lname;
        } catch (e) {
          return null;
        }
      },
      filterValue: (cell, row) => {
        try {
          return row.users[0].fname + " " + row.users[0].lname;
        } catch (e) {
          return null;
        }
      },
      sortValue: (cell, row) => {
        try {
          return row.users[0].fname + " " + row.users[0].lname;
        } catch (e) {
          return null;
        }
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Customer Phone Number",
      formatter: (cell, row) => {
        try {
          return normalizeInput(row.contactNumber);
        } catch (e) {
          return null;
        }
      },
      filterValue: (cell, row) => {
        try {
          return normalizeInput(row.contactNumber);
        } catch (e) {
          return null;
        }
      },
      sortValue: (cell, row) => {
        try {
          return normalizeInput(row.contactNumber);
        } catch (e) {
          return null;
        }
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Action",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button onClick={() => actionData(row, "edit")}>
                <FaRegEdit />
              </Button>
            </li>
            <li>
              {/* <OverlayTrigger
                // placement="right"
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Refund value</Tooltip>}
              > */}
              <Button onClick={() => actionData(row, "refund")}>
                <FaHandHoldingUsd />
              </Button>
              {/* </OverlayTrigger> */}
            </li>
          </ul>
        );
      },
    },
  ];
  /** this function will handle the the add or update flag and open the popup */
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
        userData: {},
      });
    }
  };
  const handleToggleCheckBox = () => {
    const { orderList } = responseData;
    if (isTodayCheckBox) {
      setLitsData(orderList.reverse());
    } else {
      setLitsData(orderList.reverse().filter((e) => isToday(e.createdAt)));
    }
    setisTodayCheckBox(!isTodayCheckBox);
  };
  const addNewModalMemorized = useCallback(addNewModal, [showModal]);
  const showHideOrderRefundModel = useCallback(() => {
    setRefundShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
  });
  // mount
  useEffect(() => {
    if (isTodayCheckBox) {
      dispatch(getOrdersList(null, "getOrderItem"));
    } else {
      dispatch(getOrdersList(null, "getAllOrdersItem"));
    }
  }, [isTodayCheckBox]);
  useEffect(() => {
    if (socket) {
      socket.on("newOrder", () => {
        dispatch(getOrdersList());
      });
      socket.on("readyOrder", () => {
        dispatch(getOrdersList());
      });
    }
  }, [socket]);
  // update
  useEffect(() => {
    const { orderList, loading, tableLading } = responseData;
    if (orderList) {
      if (!isTodayCheckBox) {
        setLitsData(orderList.reverse());
      } else {
        setLitsData(orderList.reverse().filter((e) => isToday(e.createdAt)));
      }
      setIsLoading({
        pageLoading: loading,
        tableLoading: tableLading,
      });
    }
  }, [responseData]);
  useEffect(() => {
    if (login.userData?.role === "superAdmin") {
      setpermission({
        add: true,
        update: true,
        delete: true,
      });
    }
    login.permission.map((item) => {
      if (item.pageAliasName == "manageOrder") {
        setpermission({
          add: item.add,
          update: item.update,
          delete: item.delete,
        });
      }
    });
  }, [login]);
  //handle order read
  const orderRead = (type, orderId) => {
    let tmpListData = [...litsData];
    let tmpIndex = tmpListData.findIndex((e) => e._id === orderId);
    if (tmpIndex !== -1) {
      if (tmpListData[tmpIndex].isRead == 0) {
        dispatch(
          readOrderStatus({
            orderId,
            readOrderStatus: type,
          })
        );
      }
    }
  };
  //handle actions
  const actionData = (data, action) => {
    switch (action) {
      case "delete":
        setDeletConfirmModal({
          show: true,
          data: data,
        });
        break;
      case "edit":
        setEditData({
          edit: true,
          data: data,
          userData: data.users[0],
          paymentData: data.payments[0],
        });
        addNewModal();
        orderRead(1, data._id);
        break;
      case "refund":
        setEditData({
          edit: false,
          data: data,
          userData: {},
          paymentData: {},
        });
        showHideOrderRefundModel();
        break;
      default:
        break;
    }
  };
  /** this function handle delete close  alert  */
  const handleCloseAlert = () => {
    setDeletConfirmModal({
      show: false,
      data: [],
    });
  };
  /** this function handle success close  alert  */
  const handleSuccessAlert = () => {
    dispatch(
      deleteModifierCategoryAction({ itemId: deletConfirmModal.data._id })
    );
    setDeletConfirmModal({
      show: false,
      data: [],
    });
  };
  /*** show the loading if loading is true  */
  if (isLoding.pageLoading === true) {
    return <PageLoader size={50} />;
  }
  const rowClasses = (row) => {
    if (row.OrderStatusCode === "ready") {
      return "ready";
    } else if (row.isRead === 0) {
      return "unread";
    } else {
      return "";
    }
  };
  return (
    <>
      <div className="main-content">
        <div className="page-title">
          <h2>Manage Orders </h2>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="card customCard">
                <div className="card-title titlewithbtn pb-2">
                  <h2>Manage Orders</h2>
                  <span>
                    <input
                      type="checkbox"
                      defaultChecked={isTodayCheckBox}
                      onClick={handleToggleCheckBox}
                    />
                    <b className="ml-1">Today's Orders</b>{" "}
                  </span>
                </div>
                <div className="card-body">
                  {isLoding.pageLoading === false ? (
                    <BootstrapeTableData
                      data={litsData}
                      columns={columns}
                      keyfield={"_id"}
                      searchBar={true}
                      isLoading={isLoding.tableLoading}
                      rowClasses={rowClasses}
                    />
                  ) : (
                    <ComponentLoader size={40} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal === "yes" && (
        <ManageOrderPopup
          showModal={showModal}
          closeModal={addNewModalMemorized}
          editData={editData}
          socket={socket}
        />
      )}
      {deletConfirmModal && (
        <ConfirmModal
          show={deletConfirmModal.show}
          handleCloseAlert={handleCloseAlert}
          handleSuccessAlert={handleSuccessAlert}
        />
      )}
      {showRefundModal === "yes" && (
        <ManageOrderRefund
          showModal={showRefundModal}
          closeModal={showHideOrderRefundModel}
          permission={permission}
          data={editData.data}
        />
      )}
    </>
  );
};
const ManageOrder = () => {
  return (
    <SocketHandler>
      <ManageOrderComponent />
    </SocketHandler>
  );
};
export default ManageOrder;
