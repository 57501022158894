import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/deliveryServices";
import { Store } from "react-notifications-component";

// get
export function* createDelivery(params) {
  const { payload } = params;
  const response = yield call(auth.createDelivery, payload);
  try {
    yield put({
      type: types.GET_DELIVERY_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_DELIVERY_ERROR,
      msg: response.message,
    });
  }
}

// get
export function* createDeliveryQuote(params) {
  const { payload } = params;
  try {
    const response = yield call(auth.createDeliveryQuote, payload);
    yield put({
      type: types.GET_DELIVERY_QUOTE_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_DELIVERY_QUOTE_ERROR,
      msg: err?.response?.data?.err?.fieldErrors?.at(0)?.error,
    });
    Store.addNotification({
      message: err?.response?.data?.err?.fieldErrors?.at(0)?.error,
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}

// get
export function* acceptDeliveryQuote(params) {
  const { payload } = params;
  const response = yield call(auth.acceptDeliveryQuote, payload);
  try {
    yield put({
      type: types.ACCEPT_DELIVERY_QUOTE_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
    yield put({
      type: types.REMOVE_DELIVERY_QUOTE,
    });
  } catch (err) {
    yield put({
      type: types.ACCEPT_DELIVERY_QUOTE_ERROR,
      msg: response.message,
    });
  }
}
