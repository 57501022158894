/***
 * Here, We will add all action type.
 * ** */

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const SET_USER = "SET_USER";
export const SET_USER_SUCCESS = "SET_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";
export const SET_USER_INFORMATION = "SET_USER_INFORMATION";

// Mange Prifle Form,
export const MANAGE_PROFILE_FORM_GET = "MANAGE_PROFILE_FORM_POST";
export const MANAGE_PROFILE_FORM_GET_SUCCESS =
  "MANAGE_PROFILE_FORM_POST_SUCCESS";
export const MANAGE_PROFILE_FORM_GET_ERROR = "MANAGE_PROFILE_FORM_POST_ERROR";

export const MANAGE_PROFILE_FORM_UPDATE = "MANAGE_PROFILE_FORM_UPDATE";
export const MANAGE_PROFILE_FORM_UPDATE_TAX = "MANAGE_PROFILE_FORM_UPDATE_TAX";

//stripe key
export const MANAGE_PROFILE_FORM_UPDATE_STRIPEKEY =
  "MANAGE_PROFILE_FORM_UPDATE_STRIPEKEY";

export const MANAGE_PROFILE_FORM_UPDATE_MINIMUMPURCHASE =
  "MANAGE_PROFILE_FORM_UPDATE_MINIMUMPURCHASE";

export const MANAGE_PROFILE_FORM_UPDATE_SUCCESS =
  "MANAGE_PROFILE_FORM_UPDATE_SUCCESS";
export const MANAGE_PROFILE_FORM_UPDATE_ERROR =
  "MANAGE_PROFILE_FORM_UPDATE_ERROR";

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_ERROR = "GET_COUNTRY_ERROR";

export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_ERROR = "GET_STATE_ERROR";

export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";

// menu Items Data
export const GET_MENU_ITEMS = "GET_MENU_ITEMS";
export const MENU_ITEMS_SUCCESS = "MENU_ITEMS_SUCCESS";
export const MENU_ITEMS_ERROR = "MENU_ITEMS_ERROR";
export const POST_MENU_ITEMS = "POST_MENU_ITEMS";
export const DELETE_MENU_ITEMS = "DELETE_MENU_ITEMS";
export const UPDATE_MENU_ITEMS = "UPDATE_MENU_ITEMS";

export const POST_PRICE_VARIATIONS_ITEM = "POST_PRICE_VARIATIONS_ITEM";
export const UPDATE_PRICE_VARIATIONS_ITEM = "UPDATE_PRICE_VARIATIONS_ITEM";

// menu Items with variation
export const GET_MENU_ITEMS_VARIATIONS = "GET_MENU_ITEMS_VARIATIONS";
export const MENU_ITEMS_VARIATIONS_SUCCESS = "MENU_ITEMS_VARIATIONS_SUCCESS";
export const MENU_ITEMS_VARIATIONS_ERROR = "MENU_ITEMS_VARIATIONS_ERROR";

// Manage Modifier
export const GET_MANAGE_MODIFIER_LIST = "GET_MANAGE_MODIFIER_LIST";
export const GET_MANAGE_MODIFIER_LIST_SUCCESS =
  "GET_MANAGE_MODIFIER_LIST_SUCCESS";
export const GET_MANAGE_MODIFIER_LIST_ERROR = "GET_MANAGE_MODIFIER_LIST_ERROR";
export const DELETE_MANAGE_MODIFIER_ITEM = "DELETE_MANAGE_MODIFIER_ITEM";
export const UPDATE_MANAGE_MODIFIER_ITEM = "UPDATE_MANAGE_MODIFIER_ITEM";
export const CREATE_MANAGE_MODIFIER_ITEM = "CREATE_MANAGE_MODIFIER_ITEM";

export const MODIFIER_TYPE_MANAGE_MODIFIER_LIST =
  "MODIFIER_TYPE_MANAGE_MODIFIER_LIST";
export const MODIFIER_TYPE_MANAGE_MODIFIER_LIST_SUCCESS =
  "MODIFIER_TYPE_MANAGE_MODIFIER_LIST_SUCCESS";

export const VARIATIONS_TYPE_MANAGE_MODIFIER_LIST =
  "VARIATIONS_TYPE_MANAGE_MODIFIER_LIST";
export const VARIATIONS_TYPE_MANAGE_MODIFIER_LIST_SUCCESS =
  "VARIATIONS_TYPE_MANAGE_MODIFIER_LIST_SUCCESS";

// Manage Modifier Category
export const GET_MODIFIER_CATEGORY_LIST = "GET_MODIFIER_CATEGORY_LIST";
export const GET_MODIFIER_CATEGORY_LIST_SUCCESS =
  "GET_MODIFIER_CATEGORY_LIST_SUCCESS";
export const GET_MODIFIER_CATEGORY_LIST_ERROR =
  "GET_MODIFIER_CATEGORY_LIST_ERROR";
export const POST_MODIFIER_CATEGORY_LIST = "POST_MODIFIER_CATEGORY_LIST";
export const DELETE_MODIFIER_CATEGORY_LIST_ITEM =
  "DELETE_MODIFIER_CATEGORY_LIST_ITEM";
export const UPDATE_MODIFIER_CATEGORY_LIST_ITEM =
  "UPDATE_MODIFIER_CATEGORY_LIST_ITEM";

// Manage Product Category
export const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY";
export const GET_PRODUCT_CATEGORY_NO_TOKEN = "GET_PRODUCT_CATEGORY_NO_TOKEN";
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_ERROR = "GET_PRODUCT_CATEGORY_ERROR";
export const POST_PRODUCT_CATEGORY = "POST_PRODUCT_CATEGORY";
export const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";
export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";

export const GET_INDIVIDUAL_CATEGORY_LIST = "GET_INDIVIDUAL_CATEGORY_LIST";
export const GET_INDIVIDUAL_CATEGORY_LIST_SUCCESS =
  "GET_INDIVIDUAL_CATEGORY_LIST_SUCCESS";

export const GET_PRODUCT_ITEM_LIST_BYID = "GET_PRODUCT_ITEM_LIST_BYID";
export const GET_PRODUCT_ITEM_LIST_BYID_SUCCESS =
  "GET_PRODUCT_ITEM_LIST_BYID_SUCCESS";

export const GET_CUSTOMIZE_ITEMVARIATION_LIST_BYID =
  "GET_CUSTOMIZE_ITEMVARIATION_LIST_BYID";
export const GET_CUSTOMIZE_ITEMVARIATION_LIST_BYID_SUCCESS =
  "GET_CUSTOMIZE_ITEMVARIATION_LIST_BYID_SUCCESS";

export const GET_CUSTOMIZE_ITEMMODIFIER_LIST_BYID =
  "GET_CUSTOMIZE_ITEMMODIFIER_LIST_BYID";
export const GET_CUSTOMIZE_ITEMMODIFIER_LIST_BYID_SUCCESS =
  "GET_CUSTOMIZE_ITEMMODIFIER_LIST_BYID_SUCCESS";

// Manage Master Modifier
export const GET_MASTER_MODIFIER_LIST = "GET_MASTER_MODIFIER_LIST";
export const GET_MASTER_MODIFIER_LIST_SUCCESS =
  "GET_MASTER_MODIFIER_LIST_SUCCESS";
export const GET_MASTER_MODIFIER_LIST_ERROR = "GET_MASTER_MODIFIER_LIST_ERROR";
export const CREATE_MASTER_MODIFIER_LIST_ITEM =
  "CREATE_MASTER_MODIFIER_LIST_ITEM";
export const UPDATE_MASTER_MODIFIER_LIST_ITEM =
  "UPDATE_MASTER_MODIFIER_LIST_ITEM";
export const DELETE_MASTER_MODIFIER_LIST_ITEM =
  "DELETE_MASTER_MODIFIER_LIST_ITEM";

// manage cart
export const CREATE_ITEM_TO_CART = "CREATE_ITEM_TO_CART";
export const UPDATE_ITEM_TO_CART = "UPDATE_ITEM_TO_CART";
export const DELETE_ITEM_TO_CART = "DELETE_ITEM_TO_CART";
export const DELETE_MODIFIER_TO_CART = "DELETE_MODIFIER_TO_CART";
export const INCREMENT_ITEM_TO_CART = "INCREMENT_ITEM_TO_CART";
export const DECREMENT_ITEM_TO_CART = "DECREMENT_ITEM_TO_CART";
export const FLUSH_CART = "FLUSH_CART";
//manage delivery or pickup
export const UPDATE_DEL_OR_PICKUP = "UPDATE_DEL_OR_PICKUP";

//mange orders
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS";
export const GET_ORDER_LIST_ERROR = "GET_ORDER_LIST_ERROR";
export const GET_ORDER_LIST_ARCHIVE = "GET_ORDER_LIST_ARCHIVE";
export const GET_ORDER_LIST_ARCHIVE_SUCCESS = "GET_ORDER_LIST_ARCHIVE_SUCCESS";
export const GET_ORDER_LIST_ARCHIVE_ERROR = "GET_ORDER_LIST_ARCHIVE_ERROR";
export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_STATUS_ERROR = "CHANGE_ORDER_STATUS_ERROR";
export const READ_ORDER_STATUS = "READ_ORDER_STATUS";
export const READ_ORDER_STATUS_SUCCESS = "READ_ORDER_STATUS_SUCCESS";
export const READ_ORDER_STATUS_FAILED = "READ_ORDER_STATUS_FAILED";
export const PUSH_ORDER_TO_LIST = "PUSH_ORDER_TO_LIST";
export const UPDATE_ORDER_TO_LIST = "UPDATE_ORDER_TO_LIST";

// get all order list from order details table
export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";

//manage crew or driver users
export const GET_Crew_OR_DRIVER = "GET_Crew_OR_DRIVER";
export const GET_Crew_OR_DRIVER_SUCCESS = "GET_Crew_OR_DRIVER_SUCCESS";
export const GET_Crew_OR_DRIVER_ERROR = "GET_Crew_OR_DRIVER_ERROR";
export const ADD_Crew_OR_DRIVER = "ADD_Crew_OR_DRIVER";
export const ADD_Crew_OR_DRIVER_ERROR = "ADD_Crew_OR_DRIVER_ERROR";
export const UPDATE_Crew_OR_DRIVER = "UPDATE_Crew_OR_DRIVER";
export const UPDATE_Crew_OR_DRIVER_ERROR = "UPDATE_Crew_OR_DRIVER_ERROR";
//crew or driver action
export const GET_Crew_OR_DRIVER_ORDER = "GET_Crew_OR_DRIVER_ORDER";
export const GET_Crew_OR_DRIVER_ORDER_ERROR = "GET_Crew_OR_DRIVER_ORDER_ERROR";
export const GET_Crew_OR_DRIVER_ORDER_SUCCESS =
  "GET_Crew_OR_DRIVER_ORDER_SUCCESS";
export const READ_ORDER_STATUS_CREW_OR_DRIVER =
  "READ_ORDER_STATUS_CREW_OR_DRIVER";
export const READ_ORDER_STATUS_CREW_OR_DRIVER_SUCCESS =
  "READ_ORDER_STATUS_CREW_OR_DRIVER_SUCCESS";
export const READ_ORDER_STATUS_CREW_OR_DRIVER_FAILED =
  "READ_ORDER_STATUS_CREW_OR_DRIVER_FAILED";
export const DRIVER_CHECK_IN_OUT = "DRIVER_CHECK_IN_OUT";
export const UPDATE_CREW_OR_DRIVER_OTP = "UPDATE_CREW_OR_DRIVER_OTP";

//payment
export const GET_PAYMENT = "GET_PAYMENT";
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";
export const GET_PAYMENT_ERROR = "GET_PAYMENT_ERROR";
export const REFUND_PAYMENT = "REFUND_PAYMENT";
export const REFUND_PAYMENT_ERROR = "REFUND_PAYMENT_ERROR";

//delivery charges
export const GET_DELIVERY_CHARGES = "GET_DELIVERY_CHARGES";
export const GET_DELIVERY_CHARGES_SUCCESS = "GET_DELIVERY_CHARGES_SUCCESS";
export const UPDATE_DELIVERY_CHARGES = "UPDATE_DELIVERY_CHARGES";
export const ADD_DELIVERY_CHARGES = "ADD_DELIVERY_CHARGES";
export const DELETE_DELIVERY_CHARGES = "DELETE_DELIVERY_CHARGES";
export const GET_DELIVERY_CHARGES_ERROR = "GET_DELIVERY_CHARGES_ERROR";

// delivery (Doordash)
export const GET_DELIVERY = "GET_DELIVERY";
export const GET_DELIVERY_SUCCESS = "GET_DELIVERY_SUCCESS";
export const GET_DELIVERY_ERROR = "GET_DELIVERY_ERROR";
export const GET_DELIVERY_QUOTE = "GET_DELIVERY_QUOTE";
export const GET_DELIVERY_QUOTE_SUCCESS = "GET_DELIVERY_QUOTE_SUCCESS";
export const GET_DELIVERY_QUOTE_ERROR = "GET_DELIVERY_QUOTE_ERROR";
export const REMOVE_DELIVERY_QUOTE = "REMOVE_DELIVERY_QUOTE";
export const REMOVE_ACCCEPTED_QUOTE = "REMOVE_ACCCEPTED_QUOTE";
export const ACCEPT_DELIVERY_QUOTE = "ACCEPT_DELIVERY_QUOTE";
export const ACCEPT_DELIVERY_QUOTE_SUCCESS = "ACCEPT_DELIVERY_QUOTE_SUCCESS";
export const ACCEPT_DELIVERY_QUOTE_ERROR = "ACCEPT_DELIVERY_QUOTE_ERROR";

//restaurent charges
export const GET_RESTAURENT_TIMING = "GET_RESTAURENT_TIMING";
export const GET_RESTAURENT_TIMING_SUCCESS = "GET_RESTAURENT_TIMING_SUCCESS";
export const UPDATE_RESTAURENT_TIMING = "UPDATE_RESTAURENT_TIMING";
export const ADD_RESTAURENT_TIMING = "ADD_RESTAURENT_TIMING";
export const DELETE_RESTAURENT_TIMING = "DELETE_RESTAURENT_TIMING";
export const GET_RESTAURENT_TIMING_ERROR = "GET_RESTAURENT_TIMING_ERROR";

//manage variations

export const GET_VARIATION = "GET_VARIATION";
export const GET_VARIATION_SUCCESS = "GET_VARIATION_SUCCESS";
export const GET_VARIATION_ERROR = "GET_VARIATION_ERROR";
export const UPDATE_VARIATION = "UPDATE_VARIATION";
export const UPDATE_VARIATION_ERROR = "UPDATE_VARIATION_ERROR";
export const ADD_VARIATION = "ADD_VARIATION";
export const ADD_VARIATION_ERROR = "ADD_VARIATION_ERROR";
export const DELETE_VARIATION = "DELETE_VARIATION";
export const DELETE_VARIATION_ERROR = "DELETE_VARIATION_ERROR";

// authantication of customer
export const LOGIN_CUSTOMER = "LOGIN_CUSTOMER";
export const LOGIN_CUSTOMER_SUCCESS = "LOGIN_CUSTOMER_SUCCESS";
export const LOGIN_CUSTOMER_ERROR = "LOGIN_CUSTOMER_ERROR";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_CUSTOMER_SUCCESS = "SET_CUSTOMER_SUCCESS";
export const SET_CUSTOMER_ERROR = "SET_CUSTOMER_ERROR";

export const LOGOUT_CUSTOMER = "LOGOUT_CUSTOMER";
export const LOGOUT_CUSTOMER_SUCCESS = "LOGOUT_CUSTOMER_SUCCESS";
export const LOGOUT_CUSTOMER_ERROR = "LOGOUT_CUSTOMER_ERROR";

export const CHANGE_CUSTOMER_PASSWORD = "CHANGE_CUSTOMER_PASSWORD";
export const CHANGE_CUSTOMER_PASSWORD_SUCCESS =
  "CHANGE_CUSTOMER_PASSWORD_SUCCESS";
export const CHANGE_CUSTOMER_PASSWORD_ERROR = "CHANGE_CUSTOMER_PASSWORD_ERROR";

export const REGISTRATION_CUSTOMER = "REGISTRATION_CUSTOMER";
export const REGISTRATION_CUSTOMER_SUCCESS = "REGISTRATION_CUSTOMER_SUCCESS";
export const REGISTRATION_CUSTOMER_ERROR = "REGISTRATION_CUSTOMER_ERROR";

export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_ERROR = "GET_CUSTOMER_ERROR";

//manage discount

export const GET_DISCOUNT = "GET_DISCOUNT";
export const GET_DISCOUNT_SUCCESS = "GET_DISCOUNT_SUCCESS";
export const GET_DISCOUNT_ERROR = "GET_DISCOUNT_ERROR";

export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_ERROR = "UPDATE_DISCOUNT_ERROR";

export const ADD_DISCOUNT = "ADD_DISCOUNT";
export const ADD_DISCOUNT_ERROR = "ADD_DISCOUNT_ERROR";
export const ADD_DISCOUNT_SUCCESS = "ADD_DISCOUNT_SUCCESS";

export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const DELETE_DISCOUNT_ERROR = "DELETE_DISCOUNT_ERROR";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";

//manage home Banner
export const GET_HOME_BANNER = "GET_HOME_BANNER";
export const GET_HOME_BANNER_SUCCESS = "GET_HOME_BANNER_SUCCESS";
export const GET_HOME_BANNER_ERROR = "GET_HOME_BANNER_ERROR";

export const UPDATE_HOME_BANNER = "UPDATE_HOME_BANNER";
export const UPDATE_HOME_BANNER_SUCCESS = "UPDATE_HOME_BANNER_SUCCESS";
export const UPDATE_HOME_BANNER_ERROR = "UPDATE_HOME_BANNER_ERROR";

export const DELETE_HOME_BANNER = "DELETE_HOME_BANNER";
export const DELETE_HOME_BANNER_SUCCESS = "DELETE_HOME_BANNER_SUCCESS";
export const DELETE_HOME_BANNER_ERROR = "DELETE_HOME_BANNER_ERROR";

export const CHANGE_HOME_BANNER_INDEXING = "CHANGE_HOME_BANNER_INDEXING";
export const CHANGE_HOME_BANNER_FLAG = "CHANGE_HOME_BANNER_FLAG";

//manage role and permission
export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_ERROR = "GET_ROLE_ERROR";

export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_ERROR = "ADD_ROLE_ERROR";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

export const GET_PERMISSION = "GET_PERMISSION";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_ERROR = "GET_PERMISSION_ERROR";

export const GET_ALL_PERMISSION = "GET_ALL_PERMISSION";
export const GET_ALL_PERMISSION_SUCCESS = "GET_ALL_PERMISSION_SUCCESS";
export const GET_ALL_PERMISSION_ERROR = "GET_ALL_PERMISSION_ERROR";

//manange users
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

//manange coupon
export const GET_COUPON = "GET_COUPON";
export const GET_COUPON_SUCCESS = "GET_COUPON_SUCCESS";
export const GET_COUPON_ERROR = "GET_COUPON_ERROR";

export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_ERROR = "ADD_COUPON_ERROR";

export const UPDATE_COUPON = "UPDATE_COUPON";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_ERROR = "UPDATE_COUPON_ERROR";

export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_ERROR = "DELETE_COUPON_ERROR";

export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_ERROR = "GET_LOCATION_ERROR";
export const SELECT_LOCATION = "SELECT_LOCATION";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_ERROR = "CREATE_LOCATION_ERROR";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_ERROR = "UPDATE_LOCATION_ERROR";

//address and phone
export const GET_ADDRESS_AND_PHONE = "GET_ADDRESS_AND_PHONE";
export const GET_ADDRESS_AND_PHONE_SUCCESS = "GET_ADDRESS_AND_PHONE_SUCCESS";
export const GET_ADDRESS_AND_PHONE_ERROR = "GET_ADDRESS_AND_PHONE_ERROR";

export const GET_ADMIN_LOCATION = "GET_ADMIN_LOCATION";
export const GET_ADMIN_LOCATION_SUCCESS = "GET_ADMIN_LOCATION_SUCCESS";
export const GET_ADMIN_LOCATION_ERROR = "GET_ADMIN_LOCATION_ERROR";
