import { put, call } from "redux-saga/effects";
import * as auth from "../Services/authenticationServices";
import { setCookie, deleteAllCookies } from "../Utils/cookies";
import * as types from "../Action/types";
import jwt from "jsonwebtoken";
import { setAuthorization } from "../Services/api";
import { setCustomer, setUser } from "../Action/authAction";
import { Store  } from "react-notifications-component";

// Saga for User login
export function* loginSaga(payload) {
  try {
    const response = yield call(auth.loginService, payload.user);
    const tokenUserData = response.result.authToken;
    if (tokenUserData) {
      /**
       * clearing previous values
       */
      localStorage.clear();
      const decodedUserDetails = jwt.decode(tokenUserData);
      localStorage.setItem("userid", response.result.userId);
      response.result.locationId
        ? localStorage.setItem("locationId", response.result.locationId)
        : localStorage.setItem("locationId", response.result.userId);
      setCookie("token", tokenUserData);
      setAuthorization(tokenUserData);
      yield put(setUser(decodedUserDetails));
    }
    yield put({
      type: types.LOGIN_USER_SUCCESS,
      response: response.result,
    });
    yield put({
      type: types.GET_ALL_PERMISSION_SUCCESS,
      payload: response.result?.roleName?.permission || [],
    });
  } catch (error) {
    yield put({
      type: types.LOGIN_USER_ERROR,
      error,
      isLoggedIn: false,
    });
    Store.addNotification({
      message: "Wrong credential",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}
// saga for customer login
export function* customerLoginSaga({ payload }) {
  try {
    const response = yield call(auth.customerLoginService, payload);
    const tokenUserData = response.result.authToken;
    if (tokenUserData) {
      const decodedUserDetails = {
        fname: response.result.fname,
        lname: response.result.lname,
        email: response.result.email,
        contactNumber: response.result.contactNumber,
      };
      localStorage.setItem("customerId", response.result.userId);
      setCookie("customerToken", tokenUserData, 8760);
      setCookie("isCustomerLoggedIn", true, 8760);
      setCookie("customer", JSON.stringify(decodedUserDetails), 8760);
      setAuthorization(tokenUserData);
      yield put({
        type: types.LOGIN_CUSTOMER_SUCCESS,
        payload: {
          customerToken: tokenUserData,
          customer: decodedUserDetails,
        },
      });
      Store.addNotification({
        message: response.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put({
      type: types.LOGIN_CUSTOMER_ERROR,
      error,
      isLoggedIn: false,
    });
    Store.addNotification({
      message: "Username or password is incorrect",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}

// saga for customer login
export function* customerRegistrationSaga({ payload }) {
  try {
    const response = yield call(auth.customerRegistrationService, payload);
    if (response.status == 500) {
      if (response.data.err.code == 11000) {
        yield put({
          type: types.REGISTRATION_CUSTOMER_ERROR,
        });
        Store.addNotification({
          message: "Email already registered",
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      } else {
        yield put({
          type: types.REGISTRATION_CUSTOMER_ERROR,
        });
        Store.addNotification({
          message: "Somthing went wrong !!!",
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      }
    } else {
      const tokenUserData = response.result.authToken;
      if (tokenUserData) {
        const decodedUserDetails = {
          fname: response.result.fname,
          lname: response.result.lname,
          email: response.result.email,
          contactNumber: response.result.contactNumber,
        };
        //set customer id in local stoarage for future use
        localStorage.setItem("customerId", response.result.userId);
        //set customer token in coookies
        setCookie("customerToken", tokenUserData, 8760);
        //set customer login  in cookies
        setCookie("isCustomerLoggedIn", true, 8760);
        //set customer object in cookie
        setCookie("customer", JSON.stringify(decodedUserDetails), 8760);
        //set token in rest api client
        setAuthorization(tokenUserData);
        yield put({
          type: types.LOGIN_CUSTOMER_SUCCESS,
          payload: {
            customerToken: tokenUserData,
            customer: decodedUserDetails,
          },
        });
        yield put({
          type: types.REGISTRATION_CUSTOMER_SUCCESS,
        });
      }
    }
  } catch (error) {
    yield put({
      type: types.LOGIN_CUSTOMER_ERROR,
      error,
      isLoggedIn: false,
    });
    Store.addNotification({
      message: "Somthing went wrong !!!",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}

export function* logOutUser({ payload }) {
  deleteAllCookies();
  localStorage.clear();
  yield put({
    type: types.LOGOUT_USER_SUCCESS,
    msg: payload,
  });
}

//update the customer
// Saga for User login
export function* updateCustomer({ payload }) {
  try {
    const response = yield call(auth.updateCustomer, payload);
    const decodedUserDetails = {
      fname: response.result.fname,
      lname: response.result.lname,
      email: response.result.email,
      contactNumber: response.result.contactNumber,
    };
    setCookie("customer", JSON.stringify(decodedUserDetails), 8760);
    yield put({
      type: types.SET_CUSTOMER_SUCCESS,
      payload: response.result,
    });
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (error) {
    yield put({
      type: types.SET_CUSTOMER_ERROR,
      error,
      isLoggedIn: false,
    });
    Store.addNotification({
      message: "Somthing went wrong..",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }
}

//saga for the change password of the customer

export function* changePassword({ payload }) {
  try {
    const response = yield call(auth.changePassword, payload);
    yield put({
      type: types.CHANGE_CUSTOMER_PASSWORD_SUCCESS,
    });
    Store.addNotification({
      message: response.message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (err) {
    yield put({
      type: types.CHANGE_CUSTOMER_PASSWORD_ERROR,
    });
    try {
      Store.addNotification({
        message: err.data.err.message ?? "Somthing went wrong",
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } catch (e) {
      //
    }
  }
}

//saga for the change password of the customer

export function* getAllLocation() {
  try {
    const response = yield call(auth.getLocationService);
    yield put({
      type: types.GET_LOCATION_SUCCESS,
      payload: response.result || [],
    });
  } catch (err) {
    yield put({
      type: types.GET_LOCATION_ERROR,
    });
  }
}

export function* getAllLocationAdmin() {
  try {
    const response = yield call(auth.getLocationServiceAdmin);
    yield put({
      type: types.GET_LOCATION_SUCCESS,
      payload: response.result || [],
    });
  } catch (err) {
    yield put({
      type: types.GET_LOCATION_ERROR,
    });
  }
}

export function* createLocation({ payload }) {
  try {
    const response = yield call(auth.createLocationService, payload);
    yield call(getAllLocationAdmin);
  } catch (err) {
    yield put({
      type: types.GET_LOCATION_ERROR,
    });
  }
}

export function* updateLocation({ payload }) {
  try {
    const response = yield call(auth.updateLocationService, payload);
    yield call(getAllLocationAdmin);
  } catch (err) {
    yield put({
      type: types.GET_LOCATION_ERROR,
    });
  }
}

export function* getAddressAndPhoneNumber() {
  try {
    const response = yield call(auth.getUserAddressAndPhoneNumber);
    yield put({
      type: types.GET_ADDRESS_AND_PHONE_SUCCESS,
      payload: response.result || [],
    });
  } catch (err) {
    yield put({
      type: types.GET_LOCATION_ERROR,
    });
  }
}
