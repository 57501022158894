import React, { useState, useEffect } from "react";
import RightSideModal from "../../../helpers/RightSideModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { Form, Row, Col, Tooltip, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getProductCategoryAction } from "./../../../Action/manageProductCategoryAction";
import { getCustomizeVariationItemListByIdService } from "./../../../Services/managePropductCategoryServices";
import CouponItem from "./couponItem";
// menu item actions
import { getMenuItemsWithVariations } from "./../../../Action/menuItemsAction";
import { addCoupon, updateCoupon } from "../../../Action/manageCoupon";
import TimePicker from "react-time-picker";
import DatePicker from "react-datepicker";
import moment from "moment";
import { limitNumber } from "../../../helpers/limitNumber";

function ManageCouponPopup(props) {
  const dispatch = useDispatch();
  const {
    showModal,
    closeModal,
    editData: { edit, data: editDataObject },
  } = props;
  const [activeCategory, setActiveCategory] = useState("");
  const [itemizedCoupon, setItemizedCoupon] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [coupon, setcoupon] = useState({
    couponName: "",
    couponType: "limited", //limited unlimited
    limitedDateStart: "",
    limitedDateEnd: "",
    couponServiceType: "delivery", //"pickup"
    couponMinimumAmount: "",
    deductionType: "amount", //amount percentage
    deductionAmount: "",
    deduction: "",
    itemizedCoupon: false,
    couponCombo: [
      {
        categoryId: "",
        itemId: "",
        variationId: "",
        variationName: "",
        itemName: "",
        type: 1,
      },
    ],
    // type: 0,
    weekdays: [
      {
        dayName: "All Days",
        validTimeStart: "",
        validTimeEnd: "",
        isValid: true,
        isFullDay: false,
      },
      {
        dayName: "Monday",
        validTimeStart: "",
        validTimeEnd: "",
        isValid: false,
        isFullDay: false,
      },
      {
        dayName: "Tuesday",
        validTimeStart: "",
        validTimeEnd: "",
        isValid: false,
        isFullDay: false,
      },
      {
        dayName: "Wednesday",
        validTimeStart: "",
        validTimeEnd: "",
        isValid: false,
        isFullDay: false,
      },
      {
        dayName: "Thursday",
        validTimeStart: "",
        validTimeEnd: "",
        isValid: false,
        isFullDay: false,
      },
      {
        dayName: "Friday",
        validTimeStart: "",
        validTimeEnd: "",
        isValid: false,
        isFullDay: false,
      },
      {
        dayName: "Saturday",
        validTimeStart: "",
        validTimeEnd: "",
        isValid: false,
        isFullDay: false,
      },
      {
        dayName: "Sunday",
        validTimeStart: "",
        validTimeEnd: "",
        isValid: false,
        isFullDay: false,
      },
    ],
  });

  // const { productCategoryList, productCateItemList } = useSelector(
  //   (state) => state.productCat
  // );

  // get all variations
  const { allMenuData } = useSelector((state) => state.manageMenu);
  const [itemVars, setVars] = useState([]);
  const [itemList, setItemList] = useState([[]]);
  const [errors, setErrors] = useState([]);
  //close  the model and clear the state
  const closeModaleWithClearAll = () => {
    closeModal();
    setcoupon({
      couponName: "",
      couponType: "limited", //limited unlimited
      deductionType: "amount", //amount percentage
      deduction: "",
      weekdays: [],
      // couponServiceType: "delivery"
    });
    setErrors([]);
  };

  //handle change in delivery charges
  const handleCoupon = (e, index) => {
    // if(e.target.value?.length, 'length')
    if (["deductionAmount", "couponMinimumAmount"].includes(e.target.name)) {
      setcoupon({
        ...coupon,
        [e.target.name]: Number(e.target.value),
      });
    } else {
      setcoupon({
        ...coupon,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleCouponWeekDays = (name, value, dayName) => {
    let tmpCoupen = { ...coupon };
    let tmpIndex = tmpCoupen.weekdays.findIndex((e) => e.dayName === dayName);
    if (tmpIndex != -1) {
      tmpCoupen.weekdays[tmpIndex][name] = value;
    }
    // console.log(tmpCoupen, 'updated coupon')
    setcoupon(tmpCoupen);
  };
  //submit the modifier
  const onSubmitMasterFormModifier = (e, action) => {
    e.preventDefault();
    console.log(coupon, "-------- coupon");
    const arrayIsValid = handleCouponCodeValidation({ ...coupon });
    // const areValidDates = coupon.weekdays.some(day => day.isValid)

    // if(!coupon.itemizedCoupon && !areValidDates) return
    if (Object.keys(arrayIsValid).length === 0) {
      //sort out all days in weeksdays
      switch (action) {
        case "create":
          dispatch(
            addCoupon({
              ...coupon,
              couponName: handleCouponCodeName(coupon.couponName),
              ...(coupon.itemizedCoupon && { couponServiceType: "" }),
            })
          );
          break;
        case "update":
          // Check for valid dates first

          dispatch(
            updateCoupon({
              data: {
                ...coupon,
                couponName: handleCouponCodeName(coupon.couponName),
                // ...(!coupon.itemizedCoupon && {couponServiceType: coupon.couponServiceType})
              },
              id: coupon._id,
            })
          );

          break;
        default:
          break;
      }
      closeModaleWithClearAll();
    }
  };
  const handleCouponCodeName = (str) =>
    str.replace(/[^a-zA-Z0-9]/g, "_").toUpperCase();
  const handleCouponCodeValidation = (data) => {
    let tmpError = {};
    if (itemizedCoupon) {
      // ------------------------------- Validation for itemizedCoupon -------------------------
      Object.keys(data).map((item, index) => {
        if (!data["deductionAmount"]) {
          tmpError["deductionAmount"] = "This field is required";
        }
        if (data["deductionType"] === "amount") {
          if (data["deductionAmount"] < 1) {
            tmpError["deductionAmount"] = "This field is required";
          }
        }
        if (data["deductionType"] === "percentage") {
          tmpError["deductionAmount"] =
            "Deduction amount can not be in percentage";
        } else if (item === "couponName") {
          if (!data[item]) {
            tmpError["couponName"] = "This field is required";
          }
        } else if (item === "couponMinimumAmount") {
          if (!data[item]) {
            tmpError["couponMinimumAmount"] = "This field is required";
          }
        } else if (data["couponMinimumAmount"] < data["deductionAmount"]) {
          tmpError["couponMinimumAmount"] =
            "Minimum amount should be greater than Coupon Amount";
        } else if (item === "deductionAmount") {
          if (!data[item]) {
            tmpError[item] = "This field is required";
          }
        } else if (item === "couponCombo") {
          if (
            data[item].some(
              (cop) => !cop.categoryId || !cop.itemId || !cop.variationId
            )
          ) {
            tmpError["couponCombo"] = "All fields are required";
          }
        }
      });
      // /----------------------------------------------------------------------------------------------------
    } else {
      Object.keys(data).map((item, index) => {
        if (item === "couponType") {
          if (data[item] === "limited") {
            //item is limited so let check start date and date
            if (!data["limitedDateStart"]) {
              tmpError["limitedDateStart"] = "This field is required";
            }
            if (!data["limitedDateEnd"]) {
              tmpError["limitedDateEnd"] = "This field is required";
            }
            if (
              moment(data["limitedDateStart"]).isAfter(data["limitedDateEnd"])
            ) {
              tmpError["limitedDateStart"] =
                "Start date should be lower than end date";
              tmpError["limitedDateEnd"] =
                "Start date should be lower than end date";
            }
          }
        } else if (item === "deductionAmount") {
          if (data["deductionType"] === "amount") {
            if (data["deductionAmount"] < 1) {
              tmpError["deductionAmount"] =
                "Deduction amount should be greater than 0";
            }
          }
          if (data["deductionType"] === "percentage") {
            if (Number(data["deductionAmount"]) > 100) {
              tmpError["deductionAmount"] =
                "Deduction amount should not be exceed than 100%";
            }
            if (data["deductionAmount"] < 1) {
              tmpError["deductionAmount"] =
                "Deduction amount should be greater than 0";
            }
          }
        } else if (item === "weekdays") {
          if (data[item].every((day) => !day.isValid)) {
            tmpError["weekdays"] = "Please select atleast one day";
          }
          for (let i = 0; i < data.weekdays.length; i++) {
            let cItem = data.weekdays[i];
            if (cItem.isValid) {
              if (!cItem.isFullDay) {
                if (!cItem.validTimeStart) {
                  tmpError[cItem.dayName] = {
                    validTimeStart: "Please enter valid start time",
                  };
                } else if (!cItem.validTimeEnd) {
                  tmpError[cItem.dayName] = {
                    validTimeEnd: "Please enter valid end time",
                  };
                } else {
                  var startTimeObject = cItem.validTimeStart?.split(":");
                  var startTime =
                    Number(startTimeObject[0]) * 60 +
                    Number(startTimeObject[1]);
                  var endTimeObject = cItem.validTimeEnd?.split(":");
                  var endTime =
                    Number(endTimeObject[0]) * 60 + Number(endTimeObject[1]);
                  if (startTime >= endTime) {
                    tmpError[cItem.dayName] = {
                      validTimeStart:
                        "start time should be lower than end time",
                      validTimeEnd: "start time should be lower than end time",
                    };
                  }
                }
              }
              if (cItem.dayName === "All Days") {
                break;
              }
            }
          }
        } else if (item === "couponName") {
          if (!data[item]) {
            tmpError["couponName"] = "This field is required";
          }
        } else if (item === "couponMinimumAmount") {
          if (!data[item]) {
            tmpError["couponMinimumAmount"] = "This field is required";
          }
        } else if (
          coupon.deductionType === "amount" &&
          data["couponMinimumAmount"] < data["deductionAmount"]
        ) {
          tmpError["couponMinimumAmount"] =
            "Minimum amount should be greater than Coupon Amount";
        }
      });
    }
    setErrors(tmpError);
    return tmpError;
  };
  function addNewItemizedField() {
    let tempCoupon = { ...coupon };
    let tempData = { categoryId: "", itemId: "", variationId: "", type: 1 };
    tempCoupon.couponCombo?.push(tempData);
    setcoupon(tempCoupon);
  }
  function handleCouponCategory(e) {
    if (e.target.name === "itemizedCoupon" && itemizedCoupon) return;
    if (e.target.name === "normalCoupon" && !itemizedCoupon) return;
    setcoupon({
      couponName: "",
      couponMinimumAmount: "",
      couponType: "limited", //limited unlimited
      deductionType: "amount", //amount percentage
      deduction: "",
      weekdays: [],
      couponServiceType: "delivery",
      couponCombo: [
        {
          categoryId: "",
          itemId: "",
          variationId: "",
          variationName: "",
          itemName: "",
          type: 1,
        },
      ],
      weekdays: [
        {
          dayName: "All Days",
          validTimeStart: "",
          validTimeEnd: "",
          isValid: true,
          isFullDay: false,
        },
        {
          dayName: "Monday",
          validTimeStart: "",
          validTimeEnd: "",
          isValid: false,
          isFullDay: false,
        },
        {
          dayName: "Tuesday",
          validTimeStart: "",
          validTimeEnd: "",
          isValid: false,
          isFullDay: false,
        },
        {
          dayName: "Wednesday",
          validTimeStart: "",
          validTimeEnd: "",
          isValid: false,
          isFullDay: false,
        },
        {
          dayName: "Thursday",
          validTimeStart: "",
          validTimeEnd: "",
          isValid: false,
          isFullDay: false,
        },
        {
          dayName: "Friday",
          validTimeStart: "",
          validTimeEnd: "",
          isValid: false,
          isFullDay: false,
        },
        {
          dayName: "Saturday",
          validTimeStart: "",
          validTimeEnd: "",
          isValid: false,
          isFullDay: false,
        },
        {
          dayName: "Sunday",
          validTimeStart: "",
          validTimeEnd: "",
          isValid: false,
          isFullDay: false,
        },
      ],
    });
    if (e.target.name === "itemizedCoupon") setItemizedCoupon(true);
    if (e.target.name === "normalCoupon") setItemizedCoupon(false);
  }

  useEffect(() => {
    let tmpCoupon = coupon;
    if (!edit) {
      if (itemizedCoupon) {
        // tmpCoupon.couponServiceType = ""
        tmpCoupon.couponType = "unlimited";
        tmpCoupon.deductionType = "amount";
        tmpCoupon.itemizedCoupon = true;
        // tmpCoupon.couponServiceType = ""
      } else {
        tmpCoupon.couponCombo = [
          {
            categoryId: null,
            itemId: null,
            variationId: null,
            itemName: null,
            type: 1,
          },
        ];
        tmpCoupon.itemizedCoupon = false;
        tmpCoupon.couponServiceType = "delivery";
      }
      setcoupon(tmpCoupon);
    }
  }, [itemizedCoupon]);

  const [event, setEvent] = useState(null);

  // function handleCategoryChange(e) {
  //   setActiveCategory(e.target.value)
  //   dispatch(getProductCategoryItemListById({ itemId: e.target.value }));
  //   let tempCategory = { ...coupon };
  //   tempCategory.couponCombo[e.target.id].categoryId = e.target.value;
  //   setcoupon(tempCategory);
  // }

  // Function to fetch product variations
  const fetchVariations = async (itemId) => {
    const res = await getCustomizeVariationItemListByIdService(itemId);
    // console.log(res)
    // const { VariationDetails } = res?.result?.at(0);
    const varDetails = res?.result?.at(0)?.VariationDetails;
    setVars(varDetails);
  };

  function handleItemChange(e) {
    const VariationDetails = allMenuData.find(
      (vl) => vl.itemId === e.target.value
    )?.VariationDetails;
    // fetchVariations(e.target.value)

    if (VariationDetails) {
      setSelectedItem(VariationDetails);
    }

    let tempCategory = { ...coupon };

    tempCategory.couponCombo[e.target.id].itemId = e.target.value;
    tempCategory.couponCombo[e.target.id].itemName =
      e.target.options[e.target.selectedIndex].text;
    setcoupon(tempCategory);
  }

  function handleVariationChange(e) {
    let tempCategory = { ...coupon };
    tempCategory.couponCombo[e.target.id].variationId = e.target.value;
    tempCategory.couponCombo[e.target.id].variationName =
      e.target.options[e.target.selectedIndex].text;
    setcoupon(tempCategory);
  }

  // -------- Modified code ------------
  function handleTypeChange(e) {
    let tempCategory = { ...coupon };
    tempCategory.couponCombo = tempCategory.couponCombo.map((coupon) => ({
      ...coupon,
      type_text: null,
    }));
    if (Number(e.target.value) === 1) {
      // console.log('LOG: Updated as AND and index is', e.target.id)
      // console.log(tempCategory.couponCombo[Number(e.target.id) - 1], 'LOG: previous single coupon item')
      // console.log(tempCategory.couponCombo[Number(e.target.id) + 1], 'LOG: next single coupon item')
      // --- For AND ----
      if (e.target.id == 0) {
        // console.log(e.target.options[e.target.selectedIndex].text, 'Current text')
        // console.log('LOG: Updating First row')
        // If we're updating for first row, then make current row as AND
        tempCategory.couponCombo[Number(e.target.id)].type = 1;
        if (
          e.target.options[e.target.selectedIndex]?.text === "AND" &&
          tempCategory.couponCombo[Number(e.target.id) + 1]?.type === 0 &&
          tempCategory.couponCombo.length === 4
        ) {
          // console.log('LOG: AND Text')
          // if(tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 1) {
          // console.log('LOG: AND 33333')
          // If the previous of the previous row is OR, then update the previous row as OR and next row as AND
          tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
          tempCategory.couponCombo[Number(e.target.id)].type = 1;
          // tempCategory.couponCombo[Number(e.target.id) + 1].type = 1

          // }
        } else if (
          tempCategory.couponCombo[Number(e.target.id) + 1]?.type === 1
        ) {
          // console.log('LOG: AND 3334')
          // If the previous of the previous row is OR, then update the previous row as OR and next row as AND
          tempCategory.couponCombo[Number(e.target.id)].type = 1;
          // tempCategory.couponCombo[Number(e.target.id) - 1].type = 1
          // tempCategory.couponCombo[Number(e.target.id) + 1].type = 1Q
        } else if (tempCategory.couponCombo[2]?.type === 0) {
          // console.log('LOG: AND 0')
          // If the next of the next row is OR, then keep the next row as OR only
          tempCategory.couponCombo[Number(e.target.id) + 1].type = 0;
        } else {
          // console.log('LOG: AND 1')
          tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
        }
      } else if (e.target.id == tempCategory.couponCombo.length - 2) {
        // console.log('LOG: Updating Last row')
        if (
          e.target.options[e.target.selectedIndex]?.text === "AND" &&
          tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 0 &&
          tempCategory.couponCombo.length === 4
        ) {
          // console.log('LOG: AND Text')
          // if(tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 1) {
          // console.log('LOG: AND 3333')
          // If the previous of the previous row is OR, then update the previous row as OR and next row as AND
          tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
          tempCategory.couponCombo[Number(e.target.id)].type = 1;
          // tempCategory.couponCombo[Number(e.target.id) + 1].type = 1
          // }
        } else if (
          tempCategory.couponCombo[Number(e.target.id) - 2]?.type === 1
        ) {
          // console.log('LOG: AND 3333')
          // If the previous of the previous row is OR, then update the previous row as OR and next row as AND
          tempCategory.couponCombo[Number(e.target.id)].type = 1;
          // tempCategory.couponCombo[Number(e.target.id) - 1].type = 1
          tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
        }
        // If this is the last row
        else if (
          tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 1
        ) {
          // else if (tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 1) {
          // console.log('LOG: AND 2')
          // If the previous of the previous row is AND, then update the previous row as well as next row as AND
          // tempCategory.couponCombo[Number(e.target.id)].type = 1
          tempCategory.couponCombo[Number(e.target.id)].type = 1;
          tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
        } else if (
          tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 0
        ) {
          // console.log('LOG: AND 3')
          // If the previous of the previous row is OR, then update the previous row as OR and next row as AND
          tempCategory.couponCombo[Number(e.target.id)].type = 0;
          tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
        }
      } else {
        // console.log('LOG: default AND case')
        if (
          tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 1 &&
          tempCategory.couponCombo[Number(e.target.id) + 2]?.type === 1
        ) {
          // console.log('LOG: default AND case 2222')
          // If the previous row is AND, update the current row as AND
          tempCategory.couponCombo[Number(e.target.id)].type = 1;
          tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
        } else if (
          tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 0 &&
          tempCategory.couponCombo[Number(e.target.id) + 2]?.type === 1
        ) {
          // console.log('LOG: AND 4')
          tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
          // Always update the next row as AND if this is not a first or last row
          // tempCategory.couponCombo[Number(e.target.id) - 1].type = 1
        } else if (
          tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 0 &&
          tempCategory.couponCombo[Number(e.target.id) + 1]?.type === 0
        ) {
          // console.log('LOG: vishall')
          tempCategory.couponCombo[Number(e.target.id)].type = 0;
          tempCategory.couponCombo[Number(e.target.id) + 1].type_text =
            e.target.options[e.target.selectedIndex]?.text;
          // tempCategory.couponCombo = tempCategory.couponCombo.map(vl => ({...vl, type_text: e.target.options[e.target.selectedIndex]?.text}))
          // Always update the next row as AND if this is not a first or last row
          // tempCategory.couponCombo[Number(e.target.id) - 1].type = 1
        } else if (
          tempCategory.couponCombo[Number(e.target.id) - 1]?.type === 1 &&
          tempCategory.couponCombo[Number(e.target.id) + 2]?.type === 0
        ) {
          // console.log('LOG: vishal')
          tempCategory.couponCombo[Number(e.target.id)].type = 1;
          // Always update the next row as AND if this is not a first or last row
          // tempCategory.couponCombo[Number(e.target.id) - 1].type = 1
        }
        // else if(tempCategory.couponCombo[Number(e.target.id) - 1].type === 0 && tempCategory.couponCombo[Number(e.target.id) + 1].type === 0){
        //   console.log('LOG: vishal00')
        //   tempCategory.couponCombo[Number(e.target.id) ].type = 0
        //   // Always update the next row as AND if this is not a first or last row
        //   // tempCategory.couponCombo[Number(e.target.id) - 1].type = 1
        // }
        // else if(tempCategory.couponCombo[Number(e.target.id) - 1].type === 1 && tempCategory.couponCombo[Number(e.target.id) + 2].type === 0){
        //   console.log('LOG: vishal')
        //   tempCategory.couponCombo[Number(e.target.id) + 1].type = 1
        //   // Always update the next row as AND if this is not a first or last row
        //   // tempCategory.couponCombo[Number(e.target.id) - 1].type = 1
        // }
      }

      // if(e.target.id == 0){
      //   if(tempCategory.couponCombo[2].type === 1){
      //     tempCategory.couponCombo[Number(e.target.id)].type = 1;
      //     tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
      //   }else if(tempCategory.couponCombo[2].type === 0){
      //       tempCategory.couponCombo[e.target.id].type = 1;
      //       tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
      //   }
      // }else if(e.target.id == 1){
      //   if(tempCategory.couponCombo[0]?.type === 1){
      //      tempCategory.couponCombo[e.target.id].type = 1;
      //      tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
      //     }else if(tempCategory.couponCombo[0]?.type === 0){
      //      tempCategory.couponCombo[e.target.id].type = 1;
      //      tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
      //   }
      // }else{
      //   tempCategory.couponCombo[e.target.id].type = Number(e.target.value);
      //   tempCategory.couponCombo[Number(e.target.id) + 1].type = Number(e.target.value);
      // }
    } else if (Number(e.target.value) === 0) {
      // if(e.target.id === ) {
      //   // If this is the first row

      // }
      // --- For OR ----
      tempCategory.couponCombo[Number(e.target.id)].type = 0;

      tempCategory.couponCombo[Number(e.target.id) + 1].type = 0;
      // if(e.target.id == 0){
      //   console.log(tempCategory.couponCombo[2], 'next coupon')
      //   if(tempCategory.couponCombo[2]?.type === 1){
      //     tempCategory.couponCombo[Number(e.target.id)].type = 0;
      //     tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
      //   }else if(tempCategory.couponCombo[2]?.type === 0){
      //       tempCategory.couponCombo[e.target.id].type = 0;
      //       tempCategory.couponCombo[Number(e.target.id) + 1].type = 0;
      //   }
      // }else if(e.target.id == 1){
      //   if(tempCategory.couponCombo[0]?.type === 1){
      //     tempCategory.couponCombo[Number(e.target.id)].type = 1;
      //     tempCategory.couponCombo[Number(e.target.id) + 1].type = 0;
      //  }else if(tempCategory.couponCombo[0]?.type === 0){
      //     tempCategory.couponCombo[e.target.id].type = 0;
      //     tempCategory.couponCombo[Number(e.target.id) + 1].type = 0;
      //   }
      // }else{
      //   tempCategory.couponCombo[e.target.id].type = Number(e.target.value);
      //   tempCategory.couponCombo[Number(e.target.id) + 1].type = Number(e.target.value);
      // }
    }
    setcoupon(tempCategory);
  }

  // --------------- Old Code --------------------
  // function handleTypeChange(e) {
  //  let tempCategory = { ...coupon };
  // if(Number(e.target.value) === 1){
  //   if(e.target.id == 0){
  //     if(tempCategory.couponCombo[2].type === 1){
  //       tempCategory.couponCombo[Number(e.target.id)].type = 1;
  //       tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
  //     }else if(tempCategory.couponCombo[2].type === 0){
  //         tempCategory.couponCombo[e.target.id].type = 1;
  //         tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
  //     }
  //   }else if(e.target.id == 1){
  //     if(tempCategory.couponCombo[0]?.type === 1){
  //        tempCategory.couponCombo[e.target.id].type = 1;
  //        tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
  //       }else if(tempCategory.couponCombo[0]?.type === 0){
  //        tempCategory.couponCombo[e.target.id].type = 1;
  //        tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
  //     }
  //   }else{
  //     tempCategory.couponCombo[e.target.id].type = Number(e.target.value);
  //     tempCategory.couponCombo[Number(e.target.id) + 1].type = Number(e.target.value);
  //   }
  // }else if (Number(e.target.value) === 0){
  //   if(e.target.id == 0){
  //     console.log(tempCategory.couponCombo[2], 'next coupon')
  //     if(tempCategory.couponCombo[2]?.type === 1){
  //       tempCategory.couponCombo[Number(e.target.id)].type = 0;
  //       tempCategory.couponCombo[Number(e.target.id) + 1].type = 1;
  //     }else if(tempCategory.couponCombo[2]?.type === 0){
  //         tempCategory.couponCombo[e.target.id].type = 0;
  //         tempCategory.couponCombo[Number(e.target.id) + 1].type = 0;
  //     }
  //   }else if(e.target.id == 1){
  //     if(tempCategory.couponCombo[0]?.type === 1){
  //       tempCategory.couponCombo[Number(e.target.id)].type = 1;
  //       tempCategory.couponCombo[Number(e.target.id) + 1].type = 0;
  //    }else if(tempCategory.couponCombo[0]?.type === 0){
  //       tempCategory.couponCombo[e.target.id].type = 0;
  //       tempCategory.couponCombo[Number(e.target.id) + 1].type = 0;
  //     }
  //   }else{
  //     tempCategory.couponCombo[e.target.id].type = Number(e.target.value);
  //     tempCategory.couponCombo[Number(e.target.id) + 1].type = Number(e.target.value);
  //   }
  // }
  //   setcoupon(tempCategory);
  // }

  // Function to update item
  const onUpdateItem = (newItem) => {
    const updatedItem = coupon.couponCombo.map((item) =>
      newItem._id === item._id ? newItem : item
    );
    setcoupon({
      ...coupon,
      couponCombo: updatedItem,
    });
  };

  //mount the delivery charges
  useEffect(() => {
    if (edit) {
      setItemizedCoupon(editDataObject.itemizedCoupon);
      setcoupon(editDataObject);
    }
  }, [editDataObject]);

  useEffect(() => {
    dispatch(getProductCategoryAction());
    dispatch(getMenuItemsWithVariations());
  }, []);

  return (
    <>
      {showModal === "yes" && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          addNewitemRow={addCoupon}
          title={`${edit ? "Update" : "Add"} coupon`}
          //addNewButtonName={"Add New"}
          edit={edit}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <button
                  name="normalCoupon"
                  disabled={!props.isNormalCoupon && edit}
                  className={
                    !itemizedCoupon ? "tabBTN tabBTN_Active" : "tabBTN"
                  }
                  onClick={handleCouponCategory}
                >
                  Normal Coupon
                </button>
              </div>
              <div className="col-6">
                <button
                  name="itemizedCoupon"
                  disabled={props.isNormalCoupon && edit}
                  className={itemizedCoupon ? "tabBTN tabBTN_Active" : "tabBTN"}
                  onClick={handleCouponCategory}
                >
                  Itemized Coupon
                </button>
              </div>

              <div className="col-12">
                {itemizedCoupon && (
                  <div className="regBtn btn_right_pad_1 addBTN_Mod">
                    {coupon.couponCombo.length >= 3 ? (
                      <Tooltip title="You can Add Only Max three Items">
                        <Button
                          className="btn"
                          disabled
                          onClick={addNewItemizedField}
                        >
                          Add
                        </Button>
                      </Tooltip>
                    ) : (
                      <button className="btn" onClick={addNewItemizedField}>
                        Add
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {itemizedCoupon ? (
            <Form
              className={"grid_add_master_modifier"}
              onSubmit={(e) =>
                onSubmitMasterFormModifier(e, edit ? "update" : "create")
              }
            >
              <div className={"container"}>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor={"couponName"}>Coupon Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Coupon Name"
                        maxLength={30}
                        name={"couponName"}
                        value={handleCouponCodeName(coupon.couponName)}
                        onChange={handleCoupon}
                      />
                      {errors?.couponName && (
                        <p className="error-validation">{errors.couponName}</p>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor={"couponName"}>Minimum Amount</label>
                      <input
                        type="number"
                        step=".01"
                        className="form-control"
                        placeholder="Enter Minimum amount"
                        name="couponMinimumAmount"
                        value={coupon.couponMinimumAmount}
                        onChange={(e) => {
                          limitNumber(e);
                          handleCoupon(e);
                        }}
                      />
                      {errors?.couponMinimumAmount && (
                        <p className="error-validation">
                          {errors.couponMinimumAmount}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label htmlFor={"couponName"}>Amount</label>
                      {/* <div className="input-group mb-3">
                        <div className="input-group-prepend"> */}

                      {/* </div>  */}
                      <div style={{ display: "flex" }}>
                        <div className="input-group-text">
                          <input
                            type="radio"
                            className="radiobtn"
                            name={"deductionType"}
                            value={"amount"}
                            id={"amount"}
                            onChange={handleCoupon}
                            checked={coupon.deductionType === "amount"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"amount"}
                          >
                            $
                          </label>
                        </div>
                        <input
                          // min={0}
                          step=".01"
                          type="number"
                          className="form-control"
                          placeholder="Enter Coupon Amount"
                          name={"deductionAmount"}
                          value={coupon.deductionAmount}
                          onChange={(e) => {
                            limitNumber(e);
                            handleCoupon(e);
                          }}
                        />
                      </div>
                      {errors?.deductionAmount && (
                        <p className="error-validation">
                          {errors.deductionAmount}
                        </p>
                      )}
                      {/* </div> */}
                      {errors?.deductionType && (
                        <p className="error-validation">
                          {errors.deductionType}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <hr />

                <div>
                  <label>Combo Details</label>

                  {/* {errors?.couponCombo && (
                    <p className="error-validation">
                      {errors.couponCombo}
                    </p>
                  )} */}
                  {coupon.couponCombo?.map((item, index) => {
                    return (
                      <CouponItem
                        key={index}
                        coupon={coupon}
                        index={index}
                        item={item}
                        selectedItem={selectedItem}
                        handleITMChange={handleItemChange}
                        handleVarChange={(e) => {
                          handleVariationChange(e);
                        }}
                        productVars={itemVars}
                        handleTypeChange={handleTypeChange}
                        onUpdateItem={onUpdateItem}
                      />
                      // <React.Fragment key={index}>
                      //   <div className="selectForm_Box">
                      //     <label>Category</label>

                      //     <select
                      //       name="category"
                      //       onChange={handleCategoryChange}
                      //       id={index}
                      //     >
                      //       <option name="category" value="select" id={null}>
                      //         Select
                      //       </option>

                      //       {productCategoryList.map((value, i) => {
                      //         return (
                      //           item.categoryId == value.UniqueID ?
                      //             <option
                      //               name="category"
                      //               value={value.UniqueID}
                      //               selected
                      //               id={index}
                      //             >
                      //               {value.CategoryName}
                      //             </option>
                      //             : <option
                      //               name="category"
                      //               value={value.UniqueID}
                      //               id={index}
                      //             >
                      //               {value.CategoryName}
                      //             </option>
                      //         );
                      //       })}
                      //     </select>
                      //     <label>Menu Item</label>
                      //     <select onChange={handleItemChange} id={index} name='item'>
                      //       {item.itemId ? (
                      //         <option value={item.itemName} selected style={{ display: 'none' }}>{item.itemName}</option>
                      //       ) : (
                      //         <option value="select">Select</option>
                      //       )}

                      //       {productCateItemList.length && productCateItemList?.filter(item => item.categoryId === activeCategory)?.map((v, i) => {
                      //         return (
                      //           <option name={v.itemName} value={v.itemId}>
                      //             {v.itemName}
                      //           </option>
                      //         );
                      //       })}
                      //     </select>
                      //     <label>Variation</label>
                      //     <select
                      //       name="variation"
                      //       onChange={handleVariationChange}
                      //       id={index}
                      //     >
                      //       {item.variationId ? (
                      //         <option value={item.variationName} selected style={{ display: 'none' }}>{item.variationName}</option>
                      //       ) : (
                      //         <>
                      //           <option value="select" selected>Select</option>
                      //         </>
                      //       )}
                      //       {selectedItem && selectedItem?.map((g, i) => {
                      //         return (
                      //           <option name={g.variationName} value={g.variationId} >
                      //             {g.variationName}
                      //           </option>
                      //         );
                      //       })}

                      //     </select>
                      //   </div>
                      //   {console.log(coupon.couponCombo, "coupon.couponCombo",)}
                      //   {coupon.couponCombo.length > 1 && index !== coupon.couponCombo.length - 1 &&
                      //     <div className="selectForm_Box_optional">
                      //       <select
                      //         name={"type"}
                      //         id={index}
                      //         onChange={handleTypeChange}
                      //       >
                      //         {console.log(coupon.couponCombo[index + 1].type, "coupon.couponCombo",)}
                      //         {
                      //           coupon.couponCombo[index + 1].type === 1 || coupon.couponCombo[index + 1].type === 0 ?
                      //             <option value={item.type} selected style={{ display: "none" }}>{coupon.couponCombo[index + 1].type == 1 ? "AND" : "OR"}</option>
                      //             :
                      //             <option value={null} selected style={{ display: "none" }}>AND</option>
                      //         }
                      //         <option value="1">AND</option>
                      //         <option value="0">OR</option>
                      //       </select>
                      //     </div>}
                      // </React.Fragment>
                    );
                  })}
                </div>
                {errors?.couponCombo && (
                  <p className="error-validation">{errors.couponCombo}</p>
                )}
              </div>
              <Row className="mt-3 mb-3">
                <Col md={12} className="text-right">
                  <div className="regBtn btn_right_pad_1">
                    <button className="btn">{edit ? "Update" : "Save"}</button>
                  </div>
                </Col>
              </Row>
            </Form>
          ) : (
            // ------------------------------- Form for Normal Coupon -----------------------------------
            <Form
              className={"grid_add_master_modifier"}
              onSubmit={(e) =>
                onSubmitMasterFormModifier(e, edit ? "update" : "create")
              }
            >
              <div className={"container"}>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <br />
                      <br />
                      <label htmlFor={"couponName"}>Coupon Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Coupon Name"
                        name={"couponName"}
                        value={handleCouponCodeName(coupon.couponName)}
                        onChange={handleCoupon}
                        maxLength={30}
                      />
                      {errors?.couponName && (
                        <p className="error-validation">{errors.couponName}</p>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <br />
                      <br />
                      <label htmlFor={"couponName"}>Coupon Type</label>
                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            name={"couponType"}
                            value={"unlimited"}
                            id={"unlimited"}
                            onChange={handleCoupon}
                            checked={coupon.couponType === "unlimited"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"unlimited"}
                          >
                            Unlimited
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            name={"couponType"}
                            value={"limited"}
                            id={"limited"}
                            onChange={handleCoupon}
                            checked={coupon.couponType === "limited"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"unlimited"}
                          >
                            Limited
                          </label>
                        </div>
                      </div>
                      {errors?.couponType && (
                        <p className="error-validation">{errors.couponType}</p>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor={"couponName"}>Minimum Amount</label>
                      <input
                        type="number"
                        step=".01"
                        className="form-control"
                        placeholder="Enter Minimum amount"
                        name="couponMinimumAmount"
                        value={coupon.couponMinimumAmount}
                        onChange={(e) => {
                          limitNumber(e);
                          handleCoupon(e);
                        }}
                      />
                      {errors?.couponMinimumAmount && (
                        <p className="error-validation">
                          {errors.couponMinimumAmount}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label htmlFor={"couponName"}>Amount </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <input
                              type="radio"
                              className=""
                              name={"deductionType"}
                              value={"amount"}
                              id={"amount"}
                              onChange={handleCoupon}
                              checked={coupon.deductionType === "amount"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"amount"}
                            >
                              $
                            </label>
                          </div>
                        </div>
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <input
                              type="radio"
                              className=""
                              name={"deductionType"}
                              value={"percentage"}
                              id={"percentage"}
                              onChange={handleCoupon}
                              checked={coupon.deductionType === "percentage"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={"percentage"}
                            >
                              %
                            </label>
                          </div>
                        </div>
                        <input
                          step=".01"
                          type="number"
                          className="form-control"
                          placeholder="Enter Coupon Amount"
                          name={"deductionAmount"}
                          value={coupon.deductionAmount}
                          onChange={(e) => {
                            limitNumber(e);
                            handleCoupon(e);
                          }}
                        />
                        {errors?.deductionAmount && (
                          <p className="error-validation">
                            {errors.deductionAmount}
                          </p>
                        )}
                      </div>
                      {errors?.deductionType && (
                        <p className="error-validation">
                          {errors.deductionType}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label htmlFor={"couponName"}>Coupon Service Type</label>
                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            name={"couponServiceType"}
                            value={"delivery"}
                            id={"delivery"}
                            defaultChecked
                            onChange={handleCoupon}
                            checked={coupon.couponServiceType === "delivery"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"delivery"}
                          >
                            Delivery
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            name={"couponServiceType"}
                            value={"pickup"}
                            id={"pickup"}
                            onChange={handleCoupon}
                            checked={coupon.couponServiceType === "pickup"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"pickup"}
                          >
                            Pickup
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            name={"couponServiceType"}
                            value={"both"}
                            id={"both"}
                            onChange={handleCoupon}
                            checked={coupon.couponServiceType === "both"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"pickup"}
                          >
                            Both
                          </label>
                        </div>
                      </div>
                      {errors?.couponServiceType && (
                        <p className="error-validation">
                          {errors.couponServiceType}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                {coupon.couponType === "limited" ? (
                  <>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor={"couponName"}>Start Date</label>{" "}
                          <div className="form-group">
                            <DatePicker
                              className="form-control"
                              dateFormat="MM/dd/yyyy"
                              selected={
                                new Date(coupon.limitedDateStart || null)
                              }
                              showYearDropdown
                              onChange={(e) =>
                                handleCoupon({
                                  target: {
                                    name: "limitedDateStart",
                                    value: e,
                                  },
                                })
                              }
                            />
                          </div>
                          {errors?.limitedDateStart && (
                            <p className="error-validation">
                              {errors.limitedDateStart}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor={"couponName"}>End Date </label>{" "}
                          <div className="form-group">
                            <DatePicker
                              className="form-control"
                              dateFormat="MM/dd/yyyy"
                              selected={new Date(coupon.limitedDateEnd || null)}
                              showYearDropdown
                              onChange={(e) =>
                                handleCoupon({
                                  target: { name: "limitedDateEnd", value: e },
                                })
                              }
                              disableClock={true}
                            />
                          </div>
                          {/* {errors?.limitedDateEnd && (
                            <p className="error-validation">
                              {errors.limitedDateEnd}
                            </p>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                ) : (
                  ""
                )}
                <div className="container">
                  <table className="table table-resposive">
                    <tr>
                      <th>Select</th>
                      <th>Day Name</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Full Day</th>
                    </tr>
                    {coupon.weekdays.map((e, index) => {
                      if (coupon.weekdays[0].isValid) {
                        if (e.dayName === "All Days") {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name={"isValid"}
                                    id={index}
                                    defaultChecked={e.isValid}
                                    checked={e.isValid}
                                    onChange={(value) =>
                                      handleCouponWeekDays(
                                        "isValid",
                                        !e.isValid,
                                        e.dayName
                                      )
                                    }
                                  />
                                </div>
                              </td>
                              <td>{e.dayName}</td>
                              <td>
                                <TimePicker
                                  format="HH:mm"
                                  onChange={(value) => {
                                    handleCouponWeekDays(
                                      "validTimeStart",
                                      value,
                                      e.dayName
                                    );
                                  }}
                                  value={e.validTimeStart}
                                  disableClock={true}
                                />
                                {errors[e.dayName]?.["validTimeStart"] && (
                                  <p className="error-validation">
                                    {errors[e.dayName]?.["validTimeStart"]}
                                  </p>
                                )}
                              </td>
                              <td>
                                <TimePicker
                                  format="HH:mm"
                                  onChange={(value) => {
                                    handleCouponWeekDays(
                                      "validTimeEnd",
                                      value,
                                      e.dayName
                                    );
                                  }}
                                  value={e.validTimeEnd}
                                  disableClock={true}
                                />
                                {errors[e.dayName]?.["validTimeEnd"] && (
                                  <p className="error-validation">
                                    {errors[e.dayName]?.["validTimeEnd"]}
                                  </p>
                                )}
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={e.isFullDay}
                                    checked={e.isFullDay}
                                    name={"isFullDay"}
                                    id={index}
                                    onChange={(value) =>
                                      handleCouponWeekDays(
                                        "isFullDay",
                                        !e.isFullDay,
                                        e.dayName
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        }
                        return true;
                      }
                      return (
                        <tr key={index}>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name={"isValid"}
                                id={index}
                                defaultChecked={e.isValid}
                                checked={e.isValid}
                                onChange={(value) =>
                                  handleCouponWeekDays(
                                    "isValid",
                                    !e.isValid,
                                    e.dayName
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>{e.dayName}</td>
                          <td>
                            <TimePicker
                              format="HH:mm"
                              onChange={(value) => {
                                handleCouponWeekDays(
                                  "validTimeStart",
                                  value,
                                  e.dayName
                                );
                              }}
                              value={e.validTimeStart}
                              disableClock={true}
                            />
                            {errors[e.dayName]?.["validTimeStart"] && (
                              <p className="error-validation">
                                {errors[e.dayName]?.["validTimeStart"]}
                              </p>
                            )}
                          </td>
                          <td>
                            <TimePicker
                              format="HH:mm"
                              onChange={(value) => {
                                handleCouponWeekDays(
                                  "validTimeEnd",
                                  value,
                                  e.dayName
                                );
                              }}
                              value={e.validTimeEnd}
                              disableClock={true}
                            />
                            {errors[e.dayName]?.["validTimeEnd"] && (
                              <p className="error-validation">
                                {errors[e.dayName]?.["validTimeEnd"]}
                              </p>
                            )}
                          </td>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                defaultChecked={e.isFullDay}
                                checked={e.isFullDay}
                                name={"isFullDay"}
                                id={index}
                                onChange={(value) =>
                                  handleCouponWeekDays(
                                    "isFullDay",
                                    !e.isFullDay,
                                    e.dayName
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  {errors?.weekdays && (
                    <p className="error-validation">{errors.weekdays}</p>
                  )}
                </div>
              </div>
              <Row className="mt-3 mb-3">
                <Col md={12} className="text-right">
                  <div className="regBtn btn_right_pad_1">
                    <button className="btn">{edit ? "Update" : "Save"}</button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </RightSideModal>
      )}
    </>
  );
}

export default ManageCouponPopup;
