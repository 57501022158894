import React, { memo } from "react";
import PublicContainer from "../Component/costumerSiteComponent/publicContainer/";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkCookie } from "../Utils/cookies";
import Overlay from "../Component/costumerSiteComponent/overlay/overlay";

const PublicRouter = ({ component: Component, ...rest }) => {
  const token = checkCookie();
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const location = useSelector((state) => state.location);
  return (
    <Route
      {...rest}
      render={(props) =>
        token === null && isLoggedIn === false ? (
          (!location.locationId || location.locationId == "null") &&
          props.location.pathname != "/login" &&
          props.location.pathname != "/forgot-password" ? (
            <Overlay />
          ) : (
            <PublicContainer>
              <Component {...props} />
            </PublicContainer>
          )
        ) : token !== null && isLoggedIn === true ? (
          <Redirect
            to={{
              pathname: "/dashboard",
            }}
          />
        ) : (
          ""
        )
      }
    />
  );
};

export default memo(PublicRouter);
