import React, { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import { FaClipboardList, FaShippingFast } from "react-icons/fa";
import { getCustomerOrder } from "../../../../Services/manageOrderServices";
import BootstrapeTableData from "../../../../helpers/BootstrapeTableData";
import ManageOrderPopup from "../../../ManagePayment/orderDetailPopup";
import moment from "moment";
import DatePicker from "react-datepicker";

import formettedDate from "../../../../helpers/moomentDate";
export default function OrderHistory() {
  const [editData, setEditData] = useState({
    edit: false,
    data: {},
    userData: {},
  });
  const [isTodayCheckBox, setisTodayCheckBox] = useState(true);

  const handleToggleCheckBox = () => {
    if (isTodayCheckBox) {
      setfilter({
        ...filter,
        from: moment().subtract("1", "M").format("MM/DD/YYYY"),
        to: moment().subtract("1", "d").format("MM/DD/YYYY"),
      });
    } else {
      setfilter({
        ...filter,
        from: new Date(),
        to: new Date(),
      });
    }
    setisTodayCheckBox(!isTodayCheckBox);
  };
  //three month old date
  var threeMonthOldDate = new Date();
  threeMonthOldDate.setMonth(threeMonthOldDate.getMonth() - 2);
  threeMonthOldDate = `${threeMonthOldDate.getFullYear()}-${
    threeMonthOldDate.getMonth() < 10
      ? "0" + threeMonthOldDate.getMonth()
      : threeMonthOldDate.getMonth()
  }-${
    threeMonthOldDate.getDate() < 10
      ? "0" + threeMonthOldDate.getDate()
      : threeMonthOldDate.getDate()
  }`;
  //today date
  var todayDate = new Date().toISOString().substr(0, 10);
  //one month old date
  var oneMonthOldDate = new Date();
  oneMonthOldDate.setMonth(oneMonthOldDate.getMonth() - 1);
  oneMonthOldDate = `${oneMonthOldDate.getFullYear()}-${
    oneMonthOldDate.getMonth() + 1 < 10
      ? "0" + oneMonthOldDate.getMonth() + 1
      : oneMonthOldDate.getMonth() + 1
  }-${
    oneMonthOldDate.getDate() < 10
      ? "0" + oneMonthOldDate.getDate()
      : oneMonthOldDate.getDate()
  }`;
  const [orders, setorders] = useState([]);
  const [loading, setloading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filter, setfilter] = useState({
    from: moment().subtract("1", "M").format("MM/DD/YYYY"),
    to: moment().format("MM/DD/YYYY"),
    collectionName: "allOrders", // Specifying collection also
  });
  const [filterError, setfilterError] = useState({
    from: false,
    to: false,
  });
  const [showResetButton, setshowResetButton] = useState(false);
  /** this function add or update the value and than open the order details popup */
  const addNewModal = () => {
    setShowModal((prevState) => (prevState === "yes" ? "no" : "yes"));
    if (showModal === "yes") {
      setEditData({
        edit: false,
        data: {},
        userData: {},
      });
    }
  };
  /* this function will return the correct payment type display */
  const displayPaymentType = (type) => {
    switch (type) {
      case "wallet":
        return "Pay By Wallet";
      case "card":
        return "Pay By Card";
      case "partial":
        return "Partially Paid";
    }
  };
  const addNewModalMemorized = useCallback(addNewModal, [showModal]);
  const actionData = (data, action) => {
    switch (action) {
      case "edit":
        setEditData({
          edit: true,
          data: data,
          userData: data.users[0],
        });
        addNewModal();
        break;
      default:
        break;
    }
  };
  const columns = [
    {
      dataField: "randomOrderId",
      text: "OrderId",
      sort: true,
    },
    {
      dataField: "OrderServiceOption",
      text: "Service Option",
      sort: true,
    },
    {
      dataField: "OrderStatusCode",
      text: "Status",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date Of Orders",
      formatter: (cell, row) => {
        return formettedDate(cell);
      },
      filterValue: (cell, row) => {
        return formettedDate(cell);
      },
      sortValue: (cell, row) => {
        return formettedDate(cell);
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Method",
      formatter: (cell, row) => {
        return (
          <span key={Date.now()}>
            {row.payments[0].paymentMethod === "cod"
              ? "Cash On Delivery"
              : displayPaymentType(row?.payments[0]?.paymentType)}
          </span>
        );
      },
      filterValue: (cell, row) => {
        return row.payments[0].paymentMethod === "cod"
          ? "Cash On Delivery"
          : displayPaymentType(row?.payments[0]?.paymentType);
      },
      sortValue: (cell, row) => {
        return row.payments[0].paymentMethod === "cod"
          ? "Cash On Delivery"
          : displayPaymentType(row?.payments[0]?.paymentType);
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "Payment Status",
      formatter: (cell, row) => {
        try {
          return row.payments[0].paymentStatus;
        } catch {
          return null;
        }
      },
      filterValue: (cell, row) => {
        try {
          return row.payments[0].paymentStatus;
        } catch {
          return null;
        }
      },
      sortValue: (cell, row) => {
        try {
          return row.payments[0].paymentStatus;
        } catch {
          return null;
        }
      },
      sort: true,
    },
    {
      dataField: Math.floor(1000 + Math.random() * 9000).toString(),
      text: "View Detail",
      formatter: (cell, row) => {
        return (
          <ul className="actionListTable">
            <li>
              <Button
                onClick={() => {
                  actionData(row, "edit");
                }}
              >
                <FaClipboardList />
              </Button>
            </li>
            {row?.doordashDetails[0]?.trackUrl && (
              <li>
                <a target="_blank" href={row?.doordashDetails[0]?.trackUrl}>
                  <FaShippingFast />
                </a>
              </li>
            )}
          </ul>
        );
      },
    },
  ];
  //handle the change in filter form
  const handleFilterChange = (type, value) => {
    setfilter({ ...filter, [type]: value });
    let tmpError = { ...filterError };
    if (type === "from") {
      let fDate = moment(value).format("YYYY-MM-DD");
      let sDate = moment(filter["to"]).format("YYYY-MM-DD");
      if (moment(fDate).isAfter(sDate, "day")) {
        tmpError[type] = true;
        tmpError["to"] = true;
      } else {
        tmpError[type] = false;
        tmpError["to"] = false;
      }
    } else if (type === "to") {
      let fDate = moment(filter["from"]).format("YYYY-MM-DD");
      let sDate = moment(value).format("YYYY-MM-DD");
      if (moment(fDate).isAfter(sDate, "day")) {
        tmpError[type] = true;
        tmpError["from"] = true;
      } else {
        tmpError[type] = false;
        tmpError["from"] = false;
      }
    }
    setfilterError(tmpError);
  };

  //submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let tmpError = { ...filterError };
    let isValidate = true;
    Object.keys(tmpError).map((key) => {
      if (key === "from") {
        //check if null
        //check  min or max
        let fDate = moment(filter[key]).format("YYYY-MM-DD");
        let sDate = moment(filter["to"]).format("YYYY-MM-DD");
        if (moment(fDate).isAfter(sDate, "day")) {
          tmpError[key] = true;
          tmpError["to"] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
          tmpError["to"] = false;
        }
      } else if (key === "to") {
        //check if min or max
        let fDate = moment(filter["from"]).format("YYYY-MM-DD");
        let sDate = moment(filter["to"]).format("YYYY-MM-DD");
        if (moment(fDate).isAfter(sDate, "day")) {
          tmpError[key] = true;
          tmpError["from"] = true;
          isValidate = false;
        } else {
          tmpError[key] = false;
          tmpError["from"] = false;
        }
      }
    });
    setfilterError(tmpError);
    if (isValidate) {
      setloading(true);
      if (isTodayCheckBox) {
        getCustomerOrder()
          .then((res) => {
            setorders(res.result.reverse().filter((e) => isToday(e.createdAt)));
            setloading(false);
            setshowResetButton(true);
          })
          .catch((err) => {
            setloading(false);
          });
      } else {
        getCustomerOrder({ ...filter })
          .then((res) => {
            setorders(res.result.reverse());
            setloading(false);
            setshowResetButton(true);
          })
          .catch((err) => {
            setloading(false);
          });
      }
    }
  };

  const isToday = (someDate) => {
    const today = new Date();
    someDate = new Date(someDate);
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  //reset the filter
  const resetFilter = (e) => {
    e.preventDefault();
    setloading(true);
    getCustomerOrder({ collectionName: "allOrders" })
      .then((res) => {
        setorders(res.result.reverse());
        setloading(false);
        setshowResetButton(false);
      })
      .catch((err) => {
        setloading(false);
        setshowResetButton(false);
      });
  };

  // this effect will fetch all orders of customer
  useEffect(() => {
    setloading(true);
    if (isTodayCheckBox) {
      // getCustomerOrder()
      getCustomerOrder({ collectionName: "order" })
        .then((res) => {
          setorders(res.result.reverse().filter((e) => isToday(e.createdAt)));
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
        });
    } else {
      getCustomerOrder({ collectionName: "allOrders" })
        .then((res) => {
          setorders(res.result.reverse());
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
        });
    }
  }, [isTodayCheckBox]);

  return (
    <React.Fragment>
      <div className="row align-align-items-center mb-md-5">
        <div className="col-sm-12">
          <form onSubmit={handleSubmit}>
            <div className="form-row align-align-items-center duration-tab">
              <div className="col-lg-3">
                <div className="form-group d-flex align-items-center cstmmobileform mb-0">
                  <label className="mr-2 mb-0">From</label>
                  {isTodayCheckBox ? (
                    <DatePicker
                      className={`form-control ${
                        filterError.from ? "invalid-field" : ""
                      }`}
                      name="from"
                      dateFormat="MM/dd/yyyy"
                      selected={new Date()}
                      readOnly
                    />
                  ) : (
                    <DatePicker
                      className={`form-control ${
                        filterError.from ? "invalid-field" : ""
                      }`}
                      name="from"
                      dateFormat="MM/dd/yyyy"
                      selected={moment(filter.from).toDate()}
                      minDate={moment().subtract("3", "M").toDate()}
                      maxDate={moment().subtract("1", "d").toDate()}
                      onChange={(value) => {
                        handleFilterChange("from", value);
                      }}
                    />
                  )}
                  {filterError.from ? (
                    <div className="invalid-feedback">
                      from date should be lower then to date
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-center flex-wrap">
                <div className="form-group d-flex align-items-center cstmmobileform mb-0 col-md-7 col-12 px-0 to-container">
                  <label className="mr-2 mb-0">To</label>
                  {isTodayCheckBox ? (
                    <DatePicker
                      className={`form-control ${
                        filterError.to ? "invalid-field" : ""
                      }`}
                      name="to"
                      dateFormat="MM/dd/yyyy"
                      selected={new Date()}
                      readOnly
                    />
                  ) : (
                    <DatePicker
                      className={`form-control ${
                        filterError.to ? "invalid-field" : ""
                      }`}
                      name="to"
                      dateFormat="MM/dd/yyyy"
                      selected={moment(filter.to).toDate()}
                      minDate={moment().subtract("3", "M").toDate()}
                      maxDate={moment().subtract("1", "d").toDate()}
                      onChange={(value) => {
                        handleFilterChange("to", value);
                      }}
                    />
                  )}
                  {filterError.to ? (
                    <div className="invalid-feedback">
                      to date should be greater then from date
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-5 d-flex col-12 px-0 searhorder-btn serachhistrycstmbtn text-right px-md-2">
                  <div className="regBtn orderBtn">
                    <button className="btn mr-2" disabled={false}>
                      Search
                    </button>

                    {showResetButton ? (
                      <button className="btn" onClick={resetFilter}>
                        Clear
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-1 searhorder-btn serachhistrycstmbtn">
                <div className="regBtn">
                  <button className="btn mr-2" disabled={false}>
                    Search
                  </button>

                  {showResetButton ? (
                    <button className="btn" onClick={resetFilter}>
                      Clear
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div> */}

              <div className="col-lg-3 text-md-right py-2">
                <input
                  type="checkbox"
                  defaultChecked={isTodayCheckBox}
                  onClick={handleToggleCheckBox}
                />
                <label className="mx-2 mb-0">Today's Orders</label>{" "}
              </div>
            </div>
          </form>
        </div>
        <div>
          {/* <div>
            <input
              type="checkbox"
              defaultChecked={isTodayCheckBox}
              onClick={handleToggleCheckBox}
            />
            <b className="ml-1">Todays Orders</b>{" "}
          </div> */}
        </div>
      </div>
      <div className="histrycstmtable custom-class">
        <BootstrapeTableData
          data={orders}
          columns={columns}
          keyfield={"_id"}
          searchBar={true}
          isLoading={loading}
        />
        {showModal && (
          <ManageOrderPopup
            showModal={showModal}
            closeModal={addNewModalMemorized}
            editData={editData}
          />
        )}
      </div>
    </React.Fragment>
  );
}
