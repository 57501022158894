import * as types from "../Action/types";

const initialState = {
  loading: false,
  tableLoading: false,
  error: {},
  deliverAndChargesList: [],
  msg: "",
};
function manageDeliveryAndCharges(state = initialState, action) {
  const { payload, msg } = action;
  switch (action.type) {
    case types.GET_DELIVERY_CHARGES:
      return { ...state, loading: true };
    case types.GET_DELIVERY_CHARGES_SUCCESS:
      return {
        ...state,
        loading: false,
        tableLoading: false,
        deliverAndChargesList: payload || [],
        msg: msg,
      };
    case types.GET_Crew_OR_DRIVER_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };
    case types.ADD_Crew_OR_DRIVER_ERROR:
      return { ...state, loading: false, tableLoading: false, msg: msg };
    case types.CHANGE_ORDER_STATUS:
      return { ...state, loading: false, tableLoading: true };
    default:
      return state;
  }
}
export default manageDeliveryAndCharges;
