export const moneyFormat = (amount, currency = "USD") => {
  const formatter = new Intl.NumberFormat(undefined, {
    currency: currency,
    style: "currency",
  });
  return formatter.format(amount);
};

export const dateFormat = (date) => {
  const formatter = new Intl.DateTimeFormat("en", {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formatter.format(new Date(date));
};
