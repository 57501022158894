import { take, takeLatest } from "redux-saga/effects";
import * as authSagas from "./authenticationSaga";
import * as manageProfileSaga from "./manageProfileSaga";
import * as manageMenuSaga from "./manageMenuSaga";
import * as manageModifierSaga from "./manageModifierSaga";
import * as type from "../Action/types";
import * as manageModifierCategorySaga from "./manageModifierCategorySaga";
import * as manageProductCategorySaga from "./manageProductCategorySaga";
import * as manageMasterModifierSaga from "./manageMasterModifierSaga";
import * as ManageordersSaga from "./manageOrdersSaga";
import * as manageCrewOrDriver from "./ManageCrewOrDriverSaga";
import * as ManagePaymentSaga from "./managePaymentSaga";
import * as manageDeliveryAndCharges from "./deliveryandCharges";
import * as manageRestaurentTiming from "./manageRestaurentTiming";
import * as manageVariations from "./manageVariationSaga";
import * as manageDiscount from "./manageDiscountSagas";
import * as manageHomeBanner from "./homeBannerSaga";
import * as manageRoleAndPermission from "./manageRolesAndPermission";
import * as manageUser from "./manageUser";
import * as manageCoupon from "./manageCoupon";
import * as deliverySaga from "./deliverySaga";

/***
 * This function used for watch every action
 * ** */
export default function* watchUserAuthentication() {
  yield takeLatest(type.LOGIN_USER, authSagas.loginSaga);
  yield takeLatest(type.LOGOUT_USER, authSagas.logOutUser);

  // profile
  yield takeLatest(
    type.MANAGE_PROFILE_FORM_GET,
    manageProfileSaga.getManageProfileData
  );
  yield takeLatest(
    type.MANAGE_PROFILE_FORM_UPDATE,
    manageProfileSaga.updateManageProfileData
  );
  yield takeLatest(
    type.MANAGE_PROFILE_FORM_UPDATE_TAX,
    manageProfileSaga.updateManageProfileDataTax
  );
  yield takeLatest(
    type.MANAGE_PROFILE_FORM_UPDATE_STRIPEKEY,
    manageProfileSaga.updateManageProfileStripeKey
  );
  yield takeLatest(
    type.MANAGE_PROFILE_FORM_UPDATE_MINIMUMPURCHASE,
    manageProfileSaga.updateManageProfileMinimumPurchase
  );
  yield takeLatest(type.GET_COUNTRY_LIST, manageProfileSaga.getCountryData);
  yield takeLatest(type.GET_STATE_LIST, manageProfileSaga.getStateData);
  yield takeLatest(type.GET_CITY_LIST, manageProfileSaga.getCityData);

  //Menu items
  yield takeLatest(type.GET_MENU_ITEMS, manageMenuSaga.getMenuItemsList);
  yield takeLatest(
    type.GET_MENU_ITEMS_VARIATIONS,
    manageMenuSaga.getMenuItemsVariation
  );
  yield takeLatest(type.POST_MENU_ITEMS, manageMenuSaga.postMenuItems);
  yield takeLatest(type.DELETE_MENU_ITEMS, manageMenuSaga.deleteMenuItems);
  yield takeLatest(type.UPDATE_MENU_ITEMS, manageMenuSaga.updateMenuItems);
  yield takeLatest(
    type.POST_PRICE_VARIATIONS_ITEM,
    manageMenuSaga.postPriceVariationsItmSaga
  );
  yield takeLatest(
    type.UPDATE_PRICE_VARIATIONS_ITEM,
    manageMenuSaga.updatePriceVariationsItmSaga
  );

  // Mnage Modifier
  yield takeLatest(
    type.GET_MANAGE_MODIFIER_LIST,
    manageModifierSaga.getManageModifierList
  );
  yield takeLatest(
    type.DELETE_MANAGE_MODIFIER_ITEM,
    manageModifierSaga.deleteManageModifierList
  );
  yield takeLatest(
    type.UPDATE_MANAGE_MODIFIER_ITEM,
    manageModifierSaga.updateManageModifierList
  );
  yield takeLatest(
    type.CREATE_MANAGE_MODIFIER_ITEM,
    manageModifierSaga.createManageModifierList
  );
  yield takeLatest(
    type.MODIFIER_TYPE_MANAGE_MODIFIER_LIST,
    manageModifierSaga.getModifierManageModifierList
  );
  yield takeLatest(
    type.VARIATIONS_TYPE_MANAGE_MODIFIER_LIST,
    manageModifierSaga.getVariationsManageModifierList
  );

  // Manage Modifier Category
  yield takeLatest(
    type.GET_MODIFIER_CATEGORY_LIST,
    manageModifierCategorySaga.getModifierCategoryList
  );
  yield takeLatest(
    type.POST_MODIFIER_CATEGORY_LIST,
    manageModifierCategorySaga.postModifierCategoryList
  );
  yield takeLatest(
    type.DELETE_MODIFIER_CATEGORY_LIST_ITEM,
    manageModifierCategorySaga.deleteModifierCategoryListItem
  );
  yield takeLatest(
    type.UPDATE_MODIFIER_CATEGORY_LIST_ITEM,
    manageModifierCategorySaga.updateModifierCategoryListItem
  );

  // Manage Product Category
  yield takeLatest(
    type.GET_PRODUCT_CATEGORY,
    manageProductCategorySaga.getProductCategoryList
  );
  yield takeLatest(
    type.GET_PRODUCT_CATEGORY_NO_TOKEN,
    manageProductCategorySaga.getProductCategoryPublicList
  );
  yield takeLatest(
    type.POST_PRODUCT_CATEGORY,
    manageProductCategorySaga.postProductCategoryList
  );
  yield takeLatest(
    type.DELETE_PRODUCT_CATEGORY,
    manageProductCategorySaga.deleteProductCategoryList
  );
  yield takeLatest(
    type.UPDATE_PRODUCT_CATEGORY,
    manageProductCategorySaga.updateProductCategoryList
  );
  yield takeLatest(
    type.GET_INDIVIDUAL_CATEGORY_LIST,
    manageProductCategorySaga.getIndividualProductCategoryList
  );
  yield takeLatest(
    type.GET_PRODUCT_ITEM_LIST_BYID,
    manageProductCategorySaga.getProductCategoryItemListByIdSaga
  );
  yield takeLatest(
    type.GET_CUSTOMIZE_ITEMVARIATION_LIST_BYID,
    manageProductCategorySaga.getCustomizeItemVariationListByIdSaga
  );
  yield takeLatest(
    type.GET_CUSTOMIZE_ITEMMODIFIER_LIST_BYID,
    manageProductCategorySaga.getCustomizeItemModifierListByIdSaga
  );

  // Manage Master Modifier
  yield takeLatest(
    type.GET_MASTER_MODIFIER_LIST,
    manageMasterModifierSaga.getMasterModifierListSaga
  );
  yield takeLatest(
    type.DELETE_MASTER_MODIFIER_LIST_ITEM,
    manageMasterModifierSaga.deleteMasterModifierListItemSaga
  );
  yield takeLatest(
    type.CREATE_MASTER_MODIFIER_LIST_ITEM,
    manageMasterModifierSaga.createMasterModifierListItemSaga
  );
  yield takeLatest(
    type.UPDATE_MASTER_MODIFIER_LIST_ITEM,
    manageMasterModifierSaga.updateMasterModifierListItemSaga
  );

  //manage orders
  yield takeLatest(type.GET_ORDER_LIST, ManageordersSaga.getOrdersList);
  yield takeLatest(
    type.GET_ORDER_LIST_ARCHIVE,
    ManageordersSaga.getOrdersListArchive
  );

  yield takeLatest(
    type.CHANGE_ORDER_STATUS,
    ManageordersSaga.changeOrderStatus
  );
  yield takeLatest(type.READ_ORDER_STATUS, ManageordersSaga.readOrderStatus);
  //mamnang crew or driver
  yield takeLatest(
    type.GET_Crew_OR_DRIVER,
    manageCrewOrDriver.getCrewOrDriverList
  );
  yield takeLatest(
    type.GET_Crew_OR_DRIVER_ORDER,
    manageCrewOrDriver.getCrewOrDriverOrderList
  );
  yield takeLatest(
    type.READ_ORDER_STATUS_CREW_OR_DRIVER,
    manageCrewOrDriver.readCrewOrDriverOrderStatus
  );
  yield takeLatest(type.ADD_Crew_OR_DRIVER, manageCrewOrDriver.addCrewOrDriver);
  yield takeLatest(
    type.UPDATE_Crew_OR_DRIVER,
    manageCrewOrDriver.updateCrewOrDriver
  );
  yield takeLatest(
    type.UPDATE_CREW_OR_DRIVER_OTP,
    manageCrewOrDriver.updateCrewOrDriverOtp
  );
  yield takeLatest(type.DRIVER_CHECK_IN_OUT, manageCrewOrDriver.checkInOut);
  //payment
  yield takeLatest(type.GET_PAYMENT, ManagePaymentSaga.getPayment);
  yield takeLatest(type.REFUND_PAYMENT, ManagePaymentSaga.refundPaymemt);
  //deliveryChagers
  yield takeLatest(
    type.GET_DELIVERY_CHARGES,
    manageDeliveryAndCharges.getDeliveryChargesList
  );
  yield takeLatest(
    type.ADD_DELIVERY_CHARGES,
    manageDeliveryAndCharges.addDeliveryCharges
  );
  yield takeLatest(
    type.UPDATE_DELIVERY_CHARGES,
    manageDeliveryAndCharges.updateDeliveryCharges
  );
  yield takeLatest(
    type.DELETE_DELIVERY_CHARGES,
    manageDeliveryAndCharges.deleteDeliveryCharges
  );
  //manage restaurent timing
  yield takeLatest(
    type.GET_RESTAURENT_TIMING,
    manageRestaurentTiming.getRestaurentTiming
  );
  yield takeLatest(
    type.UPDATE_RESTAURENT_TIMING,
    manageRestaurentTiming.updateRestaurentTiming
  );
  // delivery
  yield takeLatest(type.GET_DELIVERY, deliverySaga.createDelivery);
  yield takeLatest(type.GET_DELIVERY_QUOTE, deliverySaga.createDeliveryQuote);
  yield takeLatest(
    type.ACCEPT_DELIVERY_QUOTE,
    deliverySaga.acceptDeliveryQuote
  );
  //manage variation
  yield takeLatest(type.GET_VARIATION, manageVariations.getVariation);
  yield takeLatest(type.UPDATE_VARIATION, manageVariations.updateVariation);
  yield takeLatest(type.ADD_VARIATION, manageVariations.addVariation);
  yield takeLatest(type.DELETE_VARIATION, manageVariations.deleteVariation);
  //manage customer Login
  yield takeLatest(type.LOGIN_CUSTOMER, authSagas.customerLoginSaga);
  yield takeLatest(
    type.REGISTRATION_CUSTOMER,
    authSagas.customerRegistrationSaga
  );
  yield takeLatest(type.SET_CUSTOMER, authSagas.updateCustomer);
  yield takeLatest(type.CHANGE_CUSTOMER_PASSWORD, authSagas.changePassword);
  //manage discount
  yield takeLatest(type.GET_DISCOUNT, manageDiscount.getDiscount);
  yield takeLatest(type.ADD_DISCOUNT, manageDiscount.addDiscount);
  yield takeLatest(type.UPDATE_DISCOUNT, manageDiscount.updateDiscount);
  yield takeLatest(type.DELETE_DISCOUNT, manageDiscount.deleteDiscount);

  //manage home banner
  yield takeLatest(type.GET_HOME_BANNER, manageHomeBanner.getHomeBannerList);
  yield takeLatest(
    type.UPDATE_HOME_BANNER,
    manageHomeBanner.updateHomeBannerList
  );
  yield takeLatest(
    type.DELETE_HOME_BANNER,
    manageHomeBanner.deleteHomeBannerList
  );
  yield takeLatest(
    type.CHANGE_HOME_BANNER_INDEXING,
    manageHomeBanner.changeHomeBannerIndexing
  );
  yield takeLatest(type.GET_ROLE, manageRoleAndPermission.getRole);
  yield takeLatest(type.GET_PERMISSION, manageRoleAndPermission.getPermission);
  yield takeLatest(type.ADD_ROLE, manageRoleAndPermission.addRole);
  yield takeLatest(type.UPDATE_ROLE, manageRoleAndPermission.updateRole);
  yield takeLatest(type.DELETE_ROLE, manageRoleAndPermission.deleteRole);
  yield takeLatest(type.GET_USER, manageUser.getUser);
  yield takeLatest(type.ADD_USER, manageUser.addUser);
  yield takeLatest(type.UPDATE_USER, manageUser.updateUser);
  yield takeLatest(type.DELETE_USER, manageUser.deleteUser);
  yield takeLatest(type.GET_CUSTOMER, manageUser.getCustomer);

  //manage coupon
  yield takeLatest(type.GET_COUPON, manageCoupon.getCoupon);
  yield takeLatest(type.ADD_COUPON, manageCoupon.addCoupon);
  yield takeLatest(type.UPDATE_COUPON, manageCoupon.updateCoupon);
  yield takeLatest(type.DELETE_COUPON, manageCoupon.deleteCoupon);
  yield takeLatest(type.GET_LOCATION, authSagas.getAllLocation);
  yield takeLatest(type.GET_ADMIN_LOCATION, authSagas.getAllLocationAdmin);

  yield takeLatest(
    type.GET_ADDRESS_AND_PHONE,
    authSagas.getAddressAndPhoneNumber
  );

  yield takeLatest(type.CREATE_LOCATION, authSagas.createLocation);
  yield takeLatest(type.UPDATE_LOCATION, authSagas.updateLocation);

  // get request for all orders form order details table
  yield takeLatest(type.GET_ORDER_REQUEST, ManageordersSaga.getOrdersDetails);

  //yield takeLatest(type.GET_ALL_PERMISSION, manageRoleAndPermission.deleteUser);
}
