import React, { Component, memo } from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import { logOutUser } from "../../Action/authAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import menutoogleIcon from "../../Assets/images/menutoogle.svg";
import belliconIcon from "../../Assets/images/bellicon.svg";
import settingiconIcon from "../../Assets/images/settingicon.svg";
import userimageIcon from "../../Assets/images/user-default.png";

let timerClear = [];
class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleNav: false,
      sessionTimer: "",
    };
  }
  /** this will handle the token expriration **/
  componentDidMount() {
    const { exp } = this.props.login.userDetails;
    const sessionOuttime = (exp * 1000 - Date.now()).toFixed(0);
    this.setState({ sessionTimer: sessionOuttime });
    this.sessionOutTimerUser(sessionOuttime);
  }
  componentDidUpdate() {
    if (this.state.isToggleNav === true) {
      document.body.className = "fullwidthPanel";
    } else {
      document.body.className = "";
    }
  }
  /** this will handle the token expriration **/
  sessionOutTimerUser(timer) {
    if (timer !== "" && timer !== undefined && timer !== null) {
      timerClear = setTimeout(
        () => this.props.logOutUser("Session has been Expired"),
        timer - 100
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(timerClear);
  }
  /** handle the click on navbar */
  sidebarNavToggle = () => {
    this.setState((prevState) => ({
      isToggleNav: !prevState.isToggleNav,
    }));
  };

  render() {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <div
        className="dropdown-toggle"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ));

    const { fname, lname } = this.props.login.userDetails;

    return (
      <header className="top-header" id="TopHeader">
        <div className="topmenu">
          <div className="toggleIcon" onClick={this.sidebarNavToggle}>
            <img src={menutoogleIcon} alt="" className="img-fluid" />
          </div>

          <div className="user-section">
            <div>
              <ul className="list-inline iconsulTop">
                {/* <li className="list-inline-item topicons">
                  <NavLink to="/manage-settings">
                    <img src={settingiconIcon} alt="" />
                  </NavLink>
                </li>
                <li className="list-inline-item topicons">
                  <NavLink to="">
                    <img src={belliconIcon} alt="" />
                    <span className="topNotification">1</span>
                  </NavLink>
                </li> */}
              </ul>
            </div>

            <div className="user_section">
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                >
                  <img src={userimageIcon} alt="" className="img-fluid" />
                  {`${fname} ${lname}`}
                </Dropdown.Toggle>

                <Dropdown.Menu className="userdropdown">
                  <ul>
                    <li>
                      <NavLink to="/manage-profile">Profile</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/manage-settings">Setting</NavLink>
                    </li> */}
                    <li>
                      <Button
                        onClick={() => this.props.logOutUser("User Logout")}
                      >
                        Logout
                      </Button>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      logOutUser,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(HeaderComponent));
