import * as types from "./types";

/***
 * this item will get delivery details
 */
export const createDeliveryRequest = (payload) => ({
  type: types.GET_DELIVERY,
  payload,
});

/***
 * this item will generate delivery quote details
 */
export const createDeliveryQuoteRequest = (payload) => ({
  type: types.GET_DELIVERY_QUOTE,
  payload,
});

/***
 * this item will accept delivery quote details
 */
export const acceptDeliveryQuoteRequest = (payload) => ({
  type: types.ACCEPT_DELIVERY_QUOTE,
  payload,
});

/***
 * this item will remove delivery quote details
 */
export const removeDeliveryQuoteRequest = () => ({
  type: types.REMOVE_DELIVERY_QUOTE,
});
/***
 * this item will remove accepted quote status
 */
export const removeAcceptedQuoteRequest = () => ({
  type: types.REMOVE_ACCCEPTED_QUOTE,
});
