import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../Container/privateRoute";
import PublicRouter from "../Container/publicRouter";
import CustomerProtectedRouter from "./customerProtectedRoute";
import LoginComponent from "../Component/Auth/Login";
import Dashboard from "../Component/Dashboard/Dashboard";
import ManageProfile from "../Component/ManageProfile/manageProfile";
import NotFoundPage from "../Component/notFoundpage/PageNotFound";
import ManageMenu from "../Component/manageMenu/ManageMenu";
import ManageModifier from "../Component/managemodifier/ManageModifier";
import ManageModifierCategory from "../Component/manageModifierCategory/";
import ManageCategory from "../Component/manageProductCategory";
import ManageMasterModifier from "../Component/manageMasterModifier/";
import ManageOrder from "../Component/ManageOrders/manageorder";
// Costumer site pages
import HomePage from "../Component/costumerSiteComponent/home/";
import Aboutus from "../Component/costumerSiteComponent/aboutus/";
import Career from "../Component/costumerSiteComponent/careers/";
import Checkout from "../Component/costumerSiteComponent/home/Checkout";
import OrderSuccessful from "../Component/costumerSiteComponent/home/OrderSuccessful";
import ManageOrderCrew from "../Component/manageCrewAndDriver/manageCrewAndDriver";
import manageCrew from "../Component/manageCrewOrDriverOrders/manageCrew";
import manageDriverDashboard from "../Component/manageCrewOrDriverOrders/driver/Dashboard";
import DriverOrders from "../Component/manageCrewOrDriverOrders/driver/Orders";
import driverCheckInOutHistory from "../Component/manageCrewOrDriverOrders/driver/DriverHistory";
import DriverOrderHistory from "../Component/manageCrewOrDriverOrders/driver/driverOrderHistory";
// import NotFoundPagePublic from '../Component/costumerSiteComponent/helpers/NotFoundPagePublic';
import { Route } from "react-router-dom";
import ManagePayment from "../Component/ManagePayment/managepayment";
import manageSettings from "../Component/manageSettings/manageSettings";
import manageVariation from "../Component/manageVariation";
import ForgotPassword from "../Component/ForgotPassword/ForgotPassword";
import ForgotPasswordLink from "../Component/costumerSiteComponent/customerProtected/customerContainer/forgotPasswordLink";
import Profile from "../Component/costumerSiteComponent/customerProtected/customerContainer/profile";
import OrderHistory from "../Component/costumerSiteComponent/customerProtected/customerContainer/orderHistory";
import ChangePassword from "../Component/costumerSiteComponent/customerProtected/customerContainer/changePassword";
import ManageReports from "../Component/manageReports";
import SalesByServiceType from "../Component/manageReports/salesByServiceType";
import VariationReport from "../Component/manageReports/variationReport";
import GrossSales from "../Component/manageReports/grossSales";
import CreditCardAmountCollected from "../Component/manageReports/creditCardAmount";
import CashAmountCollected from "../Component/manageReports/cashAmount";
import DeliveryFeeCollected from "../Component/manageReports/deliveryFeeAmount";
import ExpirableOrderDetail from "../Component/costumerSiteComponent/ExpiryOrderDetail/expiryOrderDetail";
import orderAssignToDriver from "../Component/costumerSiteComponent/orderAssignToDriver/orderAssignToDriver";
import DriverReport from "../Component/driverReport/driverReport";
import manageRoles from "../Component/manageRoles/manageRoles";
import manageUsers from "../Component/manageUsers/manageUsers";
import SalesByServiceTypeArchive from "../Component/manageArchive/getSalesByServiceTypeArchive";
import ManageOrderArchive from "../Component/manageArchive/ManageOrdersArchive/manageorderArchive";
import ManagePaymentArchive from "../Component/manageArchive/ManagePayment/managepaymentArchive";
import manageCustomers from "../Component/manageCustomers/manageCustomer";
import ManageCrewTwo from "../Component/manageCrewOrDriverOrders/manageCrewTwo";
import Wallet from "../Component/costumerSiteComponent/customerProtected/customerContainer/wallet";
import ManageLocation from "../Component/manageLocation";
import manageCalculation from "../Component/manageCalculation/manageCalculation";
import foodCalculator from "../Component/manageCalculation/foodCalculator";
import DoordashReport from "../Component/manageReports/DoordashReport";
import PrivacyPolicy from "../Component/costumerSiteComponent/policyPages/PrivacyPolicy";
import TermsPolicy from "../Component/costumerSiteComponent/policyPages/TermsConditions";
const MainRouter = () => {
  return (
    <Switch>
      {/* Public Route */}
      <PublicRouter path="/" component={HomePage} exact />
      <PublicRouter path="/aboutus" component={Aboutus} exact />
      <PublicRouter path="/career" component={Career} exact />
      <PublicRouter path="/login" component={LoginComponent} exact />
      <PublicRouter path="/checkout" component={Checkout} exact />
      <PublicRouter path="/privacyPolicy" component={PrivacyPolicy} exact />
      <PublicRouter path="/smsterms" component={TermsPolicy} exact />
      <PublicRouter
        path="/successfull/:orderId"
        component={OrderSuccessful}
        exact
      />
      <PublicRouter path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/crew/:userId/:locationId" component={manageCrew} exact />
      <Route
        path="/crew2/:userId/:locationId"
        component={ManageCrewTwo}
        exact
      />
      <Route path="/driver/:userId" component={manageDriverDashboard} exact />
      <Route path="/driver/orders/:userId" component={DriverOrders} exact />
      <Route path="/assignOrder/:orderId" component={orderAssignToDriver} />
      <Route
        path="/driver/history/:userId"
        component={DriverOrderHistory}
        exact
      />
      <Route
        path="/driver/checkinouthistory/:userId"
        component={driverCheckInOutHistory}
        exact
      />
      <Route
        path="/reset-password/:userId/:token"
        component={ForgotPasswordLink}
        exact
      />
      <Route
        path="/orders/:randomOrderId"
        component={ExpirableOrderDetail}
        exact
      />
      {/* customer protected routes start */}
      <CustomerProtectedRouter path="/profile" component={Profile} />
      <CustomerProtectedRouter path="/order-history" component={OrderHistory} />
      <CustomerProtectedRouter
        path="/change-password"
        component={ChangePassword}
      />
      <CustomerProtectedRouter path="/wallet" component={Wallet} />
      {/* customer protected routes end  */}
      {/* Private Route */}
      <PrivateRoute component={Dashboard} path="/dashboard" exact />
      <PrivateRoute component={ManageProfile} path="/manage-profile" exact />
      <PrivateRoute component={ManageMenu} path="/manage-menu-item" exact />
      <PrivateRoute component={ManageModifier} path="/manage-modifier" exact />
      <PrivateRoute component={ManagePayment} path="/manage-payment" exact />
      <PrivateRoute component={manageSettings} path="/manage-settings" exact />

      <PrivateRoute
        component={manageVariation}
        path="/manage-variation"
        exact
      />
      {/* report routes */}
      <PrivateRoute
        component={SalesByServiceType}
        path="/sales-reports"
        exact
      />
      <PrivateRoute
        component={VariationReport}
        path="/variation-reports"
        exact
      />
      <PrivateRoute component={DoordashReport} path="/doordash-reports" exact />
      <PrivateRoute component={DriverReport} path="/driver-reports" exact />
      <PrivateRoute component={GrossSales} path="/gross-sales-reports" exact />
      <PrivateRoute
        component={CreditCardAmountCollected}
        path="/creditCard-amount-collected-reports"
        exact
      />
      <PrivateRoute
        component={CashAmountCollected}
        path="/cash-amount-collected-reports"
        exact
      />
      <PrivateRoute
        component={DeliveryFeeCollected}
        path="/delivery-fee-collected-reports"
        exact
      />
      <PrivateRoute
        component={ManageModifierCategory}
        path="/manage-modifier-category"
        exact
      />
      <PrivateRoute
        component={ManageCategory}
        path="/manage-product-category"
        exact
      />
      <PrivateRoute
        component={ManageMasterModifier}
        path="/manage-master-modifier"
        exact
      />
      <PrivateRoute component={ManageOrder} path="/manage-orders" exact />
      {/**--page not found routing--**/}
      <PrivateRoute
        component={ManageOrderCrew}
        path="/manage-crew-driver-user"
        exact
      />
      <PrivateRoute component={manageRoles} path="/manage-roles" exact />
      <PrivateRoute component={manageUsers} path="/manage-users" exact />
      <PrivateRoute
        component={SalesByServiceTypeArchive}
        path="/sales-reports-archive"
        exact
      />
      <PrivateRoute
        component={ManageOrderArchive}
        path="/manage-orders-archive"
        exact
      />
      <PrivateRoute
        component={ManagePaymentArchive}
        path="/manage-payments-archive"
        exact
      />
      <PrivateRoute
        component={manageCustomers}
        path="/manage-customers"
        exact
      />
      <PrivateRoute component={ManageLocation} path="/manage-locations" exact />
      <PrivateRoute
        component={manageCalculation}
        path="/manage-food-price"
        exact
      />
      <PrivateRoute component={foodCalculator} path="/food-calculator" exact />
      <PrivateRoute component={NotFoundPage} />
      {/* <PublicRouter path="*" component={NotFoundPagePublic} />  */}
    </Switch>
  );
};

export default MainRouter;
