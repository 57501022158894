import React from 'react'
// import o_original_logo from "../overlay/o_original_logo.jpg";
// import { NavLink } from 'react-bootstrap';

const TermsPolicy = () => {
  return (
  //   <div
  //   id="myNav"
  //   className="overlay"
  //   style={{ backgroundColor: "rgb(48 119 49)" }}
  // >
    <div className=" bg-white h-100 h-mobile-auto overlay-inner">
      <div className="p-5 h-100 custom-overflow-wrapper">
      {/* <NavLink className="logo" href="/">
        <div className="welcome-logo">
          <img className="img-fluid" src={o_original_logo} />
        </div>
        </NavLink> */}
        <ol className="txt-contain">
        <h3 className="display-4">
            OLIVER'S PIZZA SMS TERMS & CONDITIONS
        </h3>
          <li className="menu-item">
                <span className="menu-item-title">
                PROGRAM DESCRIPTION: WHEN OPTED-IN, YOU WILL RECEIVE TEXT MESSAGES (SMS/MMS) TO YOUR MOBILE NUMBER. THESE KINDS OF MESSAGES MAY INCLUDE A RESPONSE TO AN INQUIRY, REAL-TIME TEXTS TO ASK AND ANSWER QUESTIONS ABOUT OUR SERVICES AND PRICING, AND APPOINTMENT CONFIRMATIONS.
                </span>
              </li>
          <li className="menu-item">
                <span className="menu-item-title">
                PROGRAM FREQUENCY: MESSAGE FREQUENCY WILL VARY.
                </span>
              </li>
          <li className="menu-item">
                <span className="menu-item-title">
                OPT OUT: YOU CAN OPT-OUT OF THIS SERVICE AT ANY TIME. JUST TEXT “STOP” TO THE PHONE NUMBER. AFTER YOU TEXT “STOP” TO US, WE WILL SEND YOU AN SMS REPLY TO CONFIRM THAT YOU HAVE BEEN UNSUBSCRIBED. AFTER THIS, YOU WILL NO LONGER RECEIVE SMS MESSAGES FROM US. IF YOU WANT TO JOIN AGAIN, JUST SIGN UP AS YOU DID THE FIRST TIME OR TEXT “START,” AND WE WILL START SENDING SMS MESSAGES TO YOU AGAIN.
                </span>
              </li>
          <li className="menu-item">
                <span className="menu-item-title">
                HELP: IF YOU ARE EXPERIENCING ANY ISSUES, YOU CAN REPLY WITH THE KEYWORD HELP. OR, YOU CAN GET HELP DIRECTLY FROM US AT <a href="mailto:support@oliverspizza.com">SUPPORT@OLIVERSPIZZA.COM</a>
                </span>
              </li>
          <li className="menu-item">
                <span className="menu-item-title">
                INTERRUPTION: CARRIERS ARE NOT LIABLE FOR DELAYED OR UNDELIVERED MESSAGES.
                </span>
              </li>
          <li className="menu-item">
                <span className="menu-item-title">
                COSTS: MESSAGE AND DATA RATES MAY APPLY FOR ANY MESSAGES SENT TO YOU FROM US AND TO US FROM YOU. IF YOU HAVE ANY QUESTIONS ABOUT YOUR TEXT PLAN OR DATA PLAN, PLEASE CONTACT YOUR WIRELESS PROVIDER.
                </span>
              </li>
          <li className="menu-item">
                <span className="menu-item-title">
                PRIVACY: WE WILL NOT SELL OR SHARE YOUR INFO.
                </span>
              </li>
          </ol>
        </div>
      </div>
    // </div>
  )
}

export default TermsPolicy