/***
 * Prefix for all api calls
 * ** */

// const LOCAL_URL = process.env.REACT_APP_API_BASE_URL;
// const PRODUCTION_URL = process.env.REACT_APP_API_PRODUCTION_URL;
// const STAGING_URL = process.env.REACT_APP_API_STAGING_URL;

/* these are for base url */

/* */
var DEPLOY_PLATFORM = "";
var DEPLOY_PLATFORM_BASE = "";
var SOCKET_URL = "";
var REACT_APP_STRIPE_KEY = "";
if (window.location.hostname === "localhost") {
  DEPLOY_PLATFORM = process.env.REACT_APP_API_BASE_URL;
  SOCKET_URL = process.env.REACT_APP_LOCAL_SOCKET_URL;
} else if (window.location.hostname === "staging-olivers.culinaryrobotics.io") {
  DEPLOY_PLATFORM = process.env.REACT_APP_API_STAGING_URL;
  SOCKET_URL = process.env.REACT_APP_STAGING_SOCKET_URL;
  // } else if (window.location.hostname === "pro-test.culinaryrobotics.io") {
} else if (window.location.hostname === "olivers.culinaryrobotics.io") {
  DEPLOY_PLATFORM = process.env.REACT_APP_API_PRODUCTION_URL;
  SOCKET_URL = process.env.REACT_APP_PRODUCTION_SOCKET_URL;
} else if (
  window.location.hostname === "theoriginaloliverspizza-front-qa.chetu.com"
) {
  DEPLOY_PLATFORM = process.env.REACT_APP_API_QA_URL;
  SOCKET_URL = process.env.REACT_APP_QA_SOCKET_URL;
}
module.exports = { DEPLOY_PLATFORM, SOCKET_URL };
