import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ComponentLoader } from "../../../helpers/PageLoader";
import {
  assignOrderToDriver,
  getOrderItemData,
} from "../../../Services/manageOrderServices";
import { Store } from "react-notifications-component";
import "./orderAssignToDriver.css";
import ApplicationDetail from "../helpers/ApplicationDetail";

// replace banner image hostname
import { changeImageUrl } from "../../../helpers/replacehost.js";

export default function orderAssignToDriver() {
  const params = useParams();
  const dispatch = useDispatch();
  const [isloading, setisloading] = useState(false);
  const [data, setorder] = useState({});
  const [otp, setotp] = useState("");
  const [alreadyAssigned, setalreadyAssigned] = useState(false);
  const [orderitemData, setorderitemData] = useState([]);
  /** this function will add the mask for us number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    value = value.toString();
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  /**  this will filterize values */
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  //generate google map url address
  const generateGoogleMapurl = (lat, long) => {
    return `http://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${long}`;
  };
  const [isAssigned, setisAssigned] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    if (otp) {
      setisloading(true);
      assignOrderToDriver({
        orderId: params.orderId,
        driverOtp: otp,
      })
        .then((res) => {
          setisloading(false);
          setorder(res?.data?.result?.[0]);
          setisAssigned(true);
        })
        .catch((error) => {
          let errorReason = error?.response?.data?.err?.name;
          if (errorReason === "driverNotFound") {
            Store.addNotification({
              message: "Wrong Otp",
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 1000,
                onScreen: true,
              },
            });
          } else if (errorReason === "orderNotFound") {
            Store.addNotification({
              message: "Order not found",
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 1000,
                onScreen: true,
              },
            });
          } else if (errorReason === "alreadyAssigned") {
            setalreadyAssigned(true);
          } else if (errorReason === "notCheckedIn") {
            Store.addNotification({
              message: "You are not checked-in ,please check-in first",
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 1000,
                onScreen: true,
              },
            });
          }
          setisloading(false);
          setisAssigned(false);
        });
    }
  };
  useEffect(() => {
    setisloading(true);
    getOrderItemData(data?._id)
      .then((res) => {
        setisloading(false);
        setorderitemData(res.result);
      })
      .catch((err) => {
        setisloading(false);
      });
  }, [data]);
  return (
    <>
      <header className="">
        <div className="top-head">
          <div className="container">
            <div className="con-head">
              <div className="head-contactinfo">
                <ul>
                  <li>
                    <img
                      src="/static/media/awesome-phone-alt.4ba22d39.svg"
                      className="ficon"
                      alt=""
                    />{" "}
                    <ApplicationDetail type="contactNumber" />
                  </li>
                  <li className="addli">
                    <img
                      src="/static/media/material-location-on.4291049a.svg"
                      className="licon"
                      alt=""
                    />{" "}
                    <ApplicationDetail type="address" />
                  </li>
                </ul>
              </div>
              <div className="dropdown">
                <div className="nav-link useraccount-dropdown"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="order-wrapper">
        <div className="row">
          <div className="col-sm-12 text-center">
            {isloading ? (
              <ComponentLoader />
            ) : isAssigned ? (
              <>
                <div className="order-show-wrapper order-details-pop px-3 py-1">
                  <div className="row">
                    <div className="col-md-6">
                      <table className="table1 ordertable ordertable-info">
                        <tbody>
                          <tr>
                            <th>Customer Name</th>
                            <td>
                              <div className="itemsWrapper d-inline-flex align-items-center">
                                {data?.users?.[0].fname +
                                  " " +
                                  data?.users?.[0].lname}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Customer Email</th>
                            <td>
                              <div className="itemsWrapper d-inline-flex align-items-center">
                                {data?.users?.[0].email}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Customer Contact Number</th>
                            <td>
                              <div className="itemsWrapper d-inline-flex align-items-center">
                                {normalizeInput(data?.contactNumber)}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              {data?.OrderServiceOption === "pickup"
                                ? "Pickup Address"
                                : "Customer Address"}
                            </th>
                            <td>
                              <div className="itemsWrapper d-inline-flex align-items-center">
                                {data?.address?.[0].street}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Order Id</th>
                            <td>
                              <div className="itemsWrapper d-inline-flex align-items-center">
                                {data?.randomOrderId}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6">
                      <table className="table1 ordertable ordertable-info">
                        <tbody>
                          <tr>
                            <th>Order Total</th>
                            <td>
                              <div className="itemsWrapper d-inline-flex align-items-center">
                                ${data?.totalGrossAmount}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Order Service Type</th>
                            <td>
                              <div className="itemsWrapper d-inline-flex align-items-center">
                                {data?.OrderServiceOption}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Payment Status Type</th>
                            <td>
                              <div className="itemsWrapper d-inline-flex align-items-center">
                                {data?.payments?.[0].paymentStatus}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th>Order Date</th>
                            <td>
                              <div className="itemsWrapper d-inline-flex align-items-center">
                                {new Date(data?.createdAt)?.toUTCString()}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="table-respnsive">
                    <table className=" table table-bordered checkListWrapper2">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Items</th>
                          <th>Size</th>
                          <th>Quantity</th>
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderitemData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="imgWrapper">
                                  {item.itemImage ? (
                                    <img
                                      src={changeImageUrl(item.itemImage)}
                                      alt="item here"
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={`${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`}
                                      alt="items here"
                                    />
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="itemsWrapper d-inline-flex align-items-center">
                                  <div className="details">
                                    <h6>{item.itemName}</h6>
                                    <p>
                                      {item.orderModifierDetail.map(
                                        (modifier, index) => {
                                          return modifier.isDeleted ? (
                                            <span>
                                              No {modifier.modifierName}{" "}
                                              {modifier.halfToppingOption != " "
                                                ? `( ${modifier.halfToppingOption.toUpperCase()} )`
                                                : ""}
                                            </span>
                                          ) : (
                                            <span>
                                              {modifier.modifierName}{" "}
                                              {modifier.halfToppingOption != " "
                                                ? `( ${modifier.halfToppingOption.toUpperCase()} )`
                                                : ""}
                                            </span>
                                          );
                                        }
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="sizes">
                                  <span className="customController">
                                    {item.varaitionName}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="addmore-row justify-content-center p-0 border-top-0">
                                  <div className="control">{item.quantity}</div>
                                </div>
                              </td>
                              <td>
                                ${priceMature(item.itemPrice * item.quantity)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="bottam">
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="list">
                        <li>
                          <div className="TotalMain">
                            <div>
                              {" "}
                              <h3>Total Tax Amount</h3>
                            </div>
                            <div>
                              <p>${priceMature(data?.taxAmount)}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="TotalMain">
                            <div>
                              {" "}
                              <h3>Total Amount</h3>
                            </div>
                            <div>
                              <p>${priceMature(data?.totalAmount)}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="TotalMain">
                            <div>
                              {" "}
                              <h3>Total Discount Amount </h3>
                            </div>
                            <div>
                              <p>${priceMature(data?.discountAmount)}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="TotalMain">
                            <div>
                              {" "}
                              <h3>Delivery Charges</h3>
                            </div>
                            <div>
                              <p>${priceMature(data?.deliveryCharge)}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="TotalMain">
                            <div>
                              {" "}
                              <h3>Gross Total</h3>
                            </div>
                            <div>
                              <p>${priceMature(data?.totalGrossAmount)}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {alreadyAssigned ? (
                  <h1>This order has been assigned to someone else..</h1>
                ) : (
                  <div className="card" style={{ width: "300px" }}>
                    <div className="card-header">Please enter your OTP</div>
                    <div className="card-body">
                      <form onSubmit={onSubmit}>
                        <div className="form-group">
                          <label>OTP</label>
                          <input
                            type="number"
                            className={`form-control ${
                              otp.length < 1 ? "invalid-field" : ""
                            }`}
                            value={otp}
                            onChange={(e) => {
                              setotp(e.target.value);
                            }}
                          />
                        </div>
                        <button className="btn btn-success">Submit</button>
                      </form>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="container">
          <div className="row ">
            <div className="col-md-8">
              <p>© Copyright WebsiteName 2022. All Right Reserved</p>
            </div>
            <div className="col-md-4 text-right">
              <a aria-current="page" className="active" href="/">
                Terms of Use
              </a>
              <a aria-current="page" className="active" href="/">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
