import React from "react";
import { useParams } from "react-router-dom";
import Banner from "./Banner";

const OrderSuccessful = () => {
  const { orderId } = useParams();
  return (
    <>
      <div className="main">
        <Banner />
        <section className="porduct-section">
          <div className="container text-center">
            <h1>
              We have received your order <br />
              your Order Id is {orderId}
            </h1>
          </div>
        </section>
      </div>
    </>
  );
};

export default OrderSuccessful;
