import React, { useState, useEffect, memo } from "react";
import RightSideModal from "../../helpers/RightSideModal";
import "./manageOrder.css";
import { getOrderItemData } from "../../Services/manageOrderServices";

// replace banner image hostname
import { changeImageUrl } from "../../helpers/replacehost.js";

const ManageOrderPopup = (props) => {
  const {
    showModal,
    closeModal,
    editData: { edit, data, userData },
    refundPopupAction,
  } = props;
  var address = "";
  try {
    address = data.address[0].street;
  } catch (error) {
    //
  }
  const [isloading, setisloading] = useState(false);
  const [orderitemData, setorderitemData] = useState([]);
  const closeModaleWithClearAll = () => {
    closeModal();
  };
  //get order item
  useEffect(() => {
    setisloading(true);
    getOrderItemData(data._id)
      .then((res) => {
        setisloading(false);
        setorderitemData(res.result);
      })
      .catch((err) => {
        setisloading(false);
      });
  }, [data]);
  const priceMature = (v) => {
    if (v == undefined) {
      return parseFloat(0).toFixed(2);
    } else if (v == "") {
      return parseFloat(0).toFixed(2);
    } else if (isNaN(v)) {
      return parseFloat(0).toFixed(2);
    } else if (v.toString().indexOf(".") != -1) {
      return parseFloat(v).toFixed(2);
    } else {
      return parseFloat(v).toFixed(2);
    }
  };
  /** this function will add the mask for us number  */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  return (
    <>
      {showModal && (
        <RightSideModal
          showModal={showModal}
          closeModal={closeModaleWithClearAll}
          sizeModal={"50"}
          title={edit === true ? "Order Details" : "Add Modifier Category"}
          addNewButtonName={"Add New"}
          edit={edit}
          className={"order-detailspopup"}
        >
          <div className="px-3 py-1">
            <div className="row">
              <table className="table1">
                <thead>
                  <tr>
                    <th>Name</th>
                    <td>
                      <div className="itemsWrapper d-inline-flex align-items-center">
                        {userData.fname + " " + userData.lname}
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Email</th>
                    <td>
                      <div className="itemsWrapper d-inline-flex align-items-center">
                        {userData.email}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Contact Number</th>
                    <td>
                      <div className="itemsWrapper d-inline-flex align-items-center">
                        {normalizeInput(data.contactNumber || " ")}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>
                      <div className="itemsWrapper d-inline-flex align-items-center">
                        {address}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className=" table table-bordered checkListWrapper2">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Items</th>
                    <th>Size</th>
                    <th>Quantity</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {orderitemData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="imgWrapper">
                            {item.itemImage ? (
                              <img
                                src={changeImageUrl(item.itemImage)}
                                alt="item here"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = `${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`;
                                }}
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_STAGING_BASE_URL}/images/__default.png`}
                                alt="items here"
                              />
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="itemsWrapper d-inline-flex align-items-center">
                            <div className="details">
                              <h6>{item.itemName}</h6>
                              <p>
                                {item.orderModifierDetail.map(
                                  (modifier, index) => {
                                    return modifier.isDeleted ? (
                                      <span>
                                        No {modifier.modifierName}{" "}
                                        {modifier.halfToppingOption !== null
                                          ? `( ${modifier.halfToppingOption.toUpperCase()} )`
                                          : ""}
                                      </span>
                                    ) : (
                                      <span>
                                        {modifier.modifierName}{" "}
                                        {modifier.halfToppingOption != " "
                                          ? `(${modifier.halfToppingOption.toUpperCase()} ${
                                              modifier.layer == "extra"
                                                ? `x 2`
                                                : ""
                                            })`
                                          : modifier.layer == "extra"
                                          ? `(x 2)`
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="sizes">
                            <span className="customController">
                              {item.varaitionName}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="addmore-row justify-content-center p-0 border-top-0">
                            <div className="control">{item.quantity}</div>
                          </div>
                        </td>
                        <td>${priceMature(item.itemPrice * item.quantity)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="col-md-12 mt-3">
                <div className="">
                  <ul className="list">
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Amount</h3>
                        </div>
                        <div>
                          <p>${priceMature(data.totalAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Discount Amount </h3>
                        </div>
                        <div>
                          <p>${priceMature(data.discountAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Coupon Discount Amount </h3>
                        </div>
                        <div>
                          <p>${priceMature(data.couponAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Total Tax Amount</h3>
                        </div>
                        <div>
                          <p>${priceMature(data.taxAmount)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Delivery Charges</h3>
                        </div>
                        <div>
                          <p>${priceMature(data.deliveryCharge)}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="TotalMain">
                        <div>
                          {" "}
                          <h3>Gross Total</h3>
                        </div>
                        <div>
                          <p>${priceMature(data.totalGrossAmount)}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </RightSideModal>
      )}
    </>
  );
};

export default memo(ManageOrderPopup);
