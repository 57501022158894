import * as api from "./api";

//create delivery
export const createDelivery = async (params) => {
  try {
    return await api.post("deliveryCharges/createDelivery", params);
  } catch (error) {
    return error;
  }
};

//create delivery
export const createDeliveryQuote = async (params) => {
  try {
    return await api.post("deliveryCharges/createDeliveryQuote", params);
  } catch (error) {
    throw error;
  }
};

//accept delivery
export const acceptDeliveryQuote = async (params) => {
  try {
    return await api.post(`deliveryCharges/acceptDeliveryQuote`, params);
  } catch (error) {
    return error;
  }
};
