import React, { useEffect, memo } from "react";
import { Redirect, Route } from "react-router-dom";
import AdminContainer from "../Component/adminDashboard/";
import jwt from "jsonwebtoken";
import { useSelector, useDispatch } from "react-redux";
import { checkCookie } from "../Utils/cookies";
import { logOutUser } from "../Action/authAction";
import NotFoundPage from "../Component/notFoundpage/PageNotFound";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const token = checkCookie();
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const login = useSelector((state) => state.login);
  useEffect(() => {
    jwt.verify(token, "secretkEy-InR5cCI6IkpXV", function (err) {
      if (err && err.message === "jwt expired") {
        dispatch(logOutUser());
      }
    });
  });
  if (token === null && isLoggedIn === false) {
    return <Redirect to={"/login"} />;
  }
  if (login?.userData?.role !== "superAdmin") {
    if (!["/dashboard", "/manage-profile"].includes(rest.path)) {
      if (login?.permission.findIndex((e) => e.uri === rest.path) == -1) {
        return (
          <Route
            {...rest}
            render={(props) => {
              return <NotFoundPage />;
            }}
          />
        );
      }
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        token !== null && isLoggedIn === true ? (
          <AdminContainer>
            <Component {...props} />
          </AdminContainer>
        ) : token === null && isLoggedIn === false ? (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        ) : (
          ""
        )
      }
    />
  );
};

export default memo(PrivateRoute);
