import { call, put } from "redux-saga/effects";
import * as types from "../Action/types";
import * as auth from "../Services/manageOrderServices";
import { Store } from "react-notifications-component";

// get
export function* getOrdersList(collectionName) {
  const response = yield call(auth.getOrderlist, collectionName);
  try {
    yield put({
      type: types.GET_ORDER_LIST_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_ORDER_LIST_ERROR,
      msg: response.message,
    });
  }
}
// get
export function* getOrdersListArchive() {
  const response = yield call(auth.getOrderlistArchive);
  try {
    yield put({
      type: types.GET_ORDER_LIST_SUCCESS,
      payload: response.result,
      msg: response.message,
    });
  } catch (err) {
    yield put({
      type: types.GET_ORDER_LIST_ERROR,
      msg: response.message,
    });
  }
}
// change order status
export function* changeOrderStatus(params) {
  const { payload } = params;
  const response = yield call(auth.changeOrderStatus, payload);
  try {
    if (response.status === 200) {
      Store.addNotification({
        message: response.message,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
      yield call(getOrdersList);
    }
  } catch (err) {
    yield put({
      type: types.CHANGE_ORDER_STATUS_ERROR,
      msg: response.message,
    });
  }
}

// change read order status
export function* readOrderStatus(params) {
  const { payload } = params;
  const response = yield call(auth.readOrderStatus, payload);
  try {
    if (response.status === 200) {
      yield put({
        type: types.READ_ORDER_STATUS_SUCCESS,
        payload,
        msg: response.message,
      });
    }
  } catch (err) {
    yield put({
      type: types.READ_ORDER_STATUS_FAILED,
      msg: response.message,
    });
  }
}

// get all orders form order details table
export function* getOrdersDetails() {
  const response = yield call(auth.getAllOrderDetails);
  try {
    if (response.status === 200) {
      yield put({
        type: types.GET_ORDER_DETAILS,
        payload: response.result,
        msg: response.message,
      });
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log("error in all orders details table", err);
  }
}
