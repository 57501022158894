import * as types from "../Action/types";

const initialState = {
  loading: false,
  trackUrl: "",
  deliveryId: "",
  quote: null,
  msg: "",
  quoteAccepted: false,
};

function delivery(state = initialState, action) {
  const { payload, msg } = action;
  switch (action.type) {
    case types.GET_DELIVERY:
      return { ...state, loading: true };
    case types.GET_DELIVERY_SUCCESS:
      return {
        ...state,
        loading: false,
        trackUrl: payload?.trackUrl,
        deliveryId: payload?.deliveryId,
        msg: msg,
      };
    case types.GET_DELIVERY_ERROR:
      return { ...state, loading: false, msg: msg };
    case types.GET_DELIVERY_QUOTE:
      return { ...state, loading: true };
    case types.GET_DELIVERY_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        quote: payload,
        msg: msg,
      };
    case types.GET_DELIVERY_QUOTE_ERROR:
      return { ...state, loading: false, msg: msg };
    case types.REMOVE_DELIVERY_QUOTE:
      return { ...state, quote: null };
    case types.ACCEPT_DELIVERY_QUOTE:
      return { ...state, loading: true };
    case types.ACCEPT_DELIVERY_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        trackUrl: payload?.trackUrl,
        deliveryId: payload?.deliveryId,
        msg: msg,
        quoteAccepted: true,
      };
    case types.REMOVE_ACCCEPTED_QUOTE:
      return { ...state, quoteAccepted: false };
    case types.ACCEPT_DELIVERY_QUOTE_ERROR:
      return { ...state, loading: false, msg: msg };
    default:
      return state;
  }
}
export default delivery;
